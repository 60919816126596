import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { CodesState } from './reducer';

const selectNode = (state: AppState) => state.codes;

export const codes = createSelector(selectNode, (state: CodesState) => state.codes);
export const code = (codeId: string) =>
  createSelector(selectNode, (state: CodesState) => (state.detail?.id === codeId ? state.detail : null));
export const metadata = createSelector(selectNode, (state: CodesState) => state.metadata);
export const query = createSelector(selectNode, (state: CodesState) => state.query);
export const errorCrudCodes = createSelector(selectNode, (state: CodesState) => state.errorCrudCodes);
export const isCreateCodeLoading = createSelector(selectNode, (state: CodesState) => state.isCreateCodeLoading);
export const isDeleteCodeLoading = createSelector(selectNode, (state: CodesState) => state.isDeleteCodeLoading);
export const isGetCodeDetailLoading = createSelector(selectNode, (state: CodesState) => state.isGetCodeDetailLoading);
export const isGetCodesLoading = createSelector(selectNode, (state: CodesState) => state.isGetCodesLoading);
export const isUpdateCodeLoading = createSelector(selectNode, (state: CodesState) => state.isUpdateCodeLoading);
