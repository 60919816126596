import React, { FC, ChangeEvent, ReactNode, KeyboardEvent, useRef } from 'react';
import classnames from 'classnames';
import InputWrapper, { InputWrapperProps } from '../../input/InputWrapper';
import './customInputField.scss';

function nextSiblingWithClass(element: Element, className: string): Element | undefined {
  let sibling = element?.nextElementSibling;
  while (sibling) {
    if (sibling.matches(className)) return sibling;
    sibling = sibling.nextElementSibling;
  }
}

export type TInputFieldProps = InputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  icon?: ReactNode;
  normalize?: (value: string) => string | number;
  onChange: (value: string | number, name: string) => void;
  onClick?: (name: string) => void;
  placeholder?: string;
  type?: 'email' | 'number' | 'password' | 'text';
  value?: string | number;
};

const CustomInputField: FC<TInputFieldProps> = ({
  autoComplete,
  className,
  autoFocus,
  icon,
  normalize,
  onChange,
  onClick = () => {},
  placeholder,
  type = 'text',
  value,
  ...wrapperProps
}) => {
  const { disabled, name } = wrapperProps;
  const ref = useRef<HTMLInputElement>();
  return (
    <InputWrapper {...wrapperProps}>
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classnames('custom-input', className)}
        disabled={disabled}
        name={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          let normalizedValue = type === 'number' ? parseFloat(value) : value;
          if (normalize) normalizedValue = normalize(value.trim());
          onChange(normalizedValue, name);
        }}
        onClick={() => onClick(name)}
        onKeyDown={(event: KeyboardEvent) => {
          // Blur the field on enter
          if (event.key?.toLowerCase() === 'enter') {
            ref.current.blur();
          }
          // Select the next input field on tab
          // Note: This could could have a better place as it is specific to tables
          if (event.key?.toLowerCase() === 'tab') {
            event.preventDefault();
            const parent = ref.current.closest('.cell-editable');
            const element = nextSiblingWithClass(parent, '.cell-editable')?.firstElementChild as HTMLElement;
            if (element) element.click();
            else ref.current.blur();
          }
        }}
        placeholder={placeholder}
        ref={ref}
        type={type === 'number' ? 'text' : type}
        value={value}
      />
      {icon}
    </InputWrapper>
  );
};

export default CustomInputField;
