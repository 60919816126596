import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomTable, { TTableColumn } from '../../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../../_translations';
import { parseCurrency } from '../../../../_utils/parseHelpers';
import { TDelivery } from '../../../_models';

const columns: TTableColumn[] = [
  {
    label: translations.getLabel('STOCKITEMS.TABLE.DATE'),
    name: 'cashSheetDate',
  },
  {
    className: 'large-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CLIENT_NAME'),
    name: 'clientName',
  },
  {
    className: 'small-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.RECEIPT_CODE'),
    name: 'receiptCode',
  },
  {
    className: 'large-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.DELIVERY_TYPE'),
    name: 'type',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH'), name: 'paidCash' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANCONTACT'), name: 'paidBancontact' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CREDITCARD'), name: 'paidCreditcard' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_BANCONTACT'),
    name: 'paidBancontactPortable',
  },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_CREDITCARD'),
    name: 'paidCreditcardPortable',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.COUPON'), name: 'paidCheck' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANK_TRANSFER'),
    name: 'paidBankTransfer',
  },
];

type TProps = {
  data?: TDelivery[];
  isLoading?: boolean;
  setDate: (date: string) => void;
  storeId: string;
};

const DeliverySearchTable: React.FC<TProps> = ({ data, isLoading, storeId, setDate }) => {
  function renderRow(delivery: TDelivery, index: number): JSX.Element {
    return (
      <CustomTable.Row className={delivery?.highlight?.toLowerCase()} key={index}>
        <CustomTable.Cell className="underline">
          <NavLink onClick={() => setDate(delivery.cashSheetDate)} to={`/cash-sheets/${storeId}`}>
            {delivery.cashSheetDate}
          </NavLink>
        </CustomTable.Cell>
        <CustomTable.Cell>{delivery.clientName}</CustomTable.Cell>
        <CustomTable.Cell>{delivery.receiptCode}</CustomTable.Cell>
        <CustomTable.Cell>{delivery.type}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidCash)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidBancontact)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidCreditCard)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidBancontactPortable)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidCreditCardPortable)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidCheck)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(delivery.paidBankTransfer)}</CustomTable.Cell>
      </CustomTable.Row>
    );
  }

  return <CustomTable columns={columns} data={data} isLoading={isLoading} renderRow={renderRow} />;
};

export default DeliverySearchTable;
