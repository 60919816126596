import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translations } from '../../_translations';
import { pricesSelectors } from '../../_store/selectors';
import { Modal, Button, ErrorMessage } from '../../_shared';
import { ApiError } from '../../_http';
import { StockItem } from '../../stock/_models';
import { PricesPrintRequest, PrintFormat } from '../_models';
import { pricesActions } from '../../_store/actions';
import { calculateGrossPrice } from '../_utils';
import PrintPriceForm from './form/PrintPriceForm';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
  isSinglePrint?: boolean;
  onSuccess?: () => void;
  stockItems?: StockItem[];
};

const PrintPrice: FC<Props> = ({ closeModal, errorAsString, stockItems, isSinglePrint = true, onSuccess }) => {
  const isLoading = useSelector(pricesSelectors.isPrintPriceLoading);
  const error = useSelector(pricesSelectors.errorCrudPrices);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  const initialForm: PricesPrintRequest = {
    printFormat: PrintFormat.A6,
    productsToPrint: stockItems.map(stockItem => ({
      price: {
        brutoPrice: parseFloat(calculateGrossPrice(stockItem.product.price.sellingPrice)),
        description: stockItem.product.price.description || '',
        sellingPrice: parseFloat(stockItem.product.price.sellingPrice),
        title: stockItem.product.price.title || '',
      },
      product: {
        code: stockItem.product.code,
        dimensions: stockItem.product.dimensions || '',
        id: stockItem.product.id,
      },
    })),
  };

  function submitForm(requestBody: PricesPrintRequest): void {
    const ids = {
      store: stockItems[0].store.id,
    };

    dispatch(
      new pricesActions.PrintPrice({
        ids,
        onSuccess: () => {
          closeModal();
          onSuccess?.();
        },
        values: requestBody,
      }),
    );
  }

  return (
    <Modal className="print-prices-modal" onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('PRICES.PRINT.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <PrintPriceForm
          buttons={
            <Button asText onClick={closeModal}>
              {translations.getLabel('SHARED.BUTTONS.CANCEL')}
            </Button>
          }
          error={error}
          initialForm={initialForm}
          isSinglePrint={isSinglePrint}
          isSubmitting={isLoading}
          submitForm={(values: PricesPrintRequest) => submitForm(values)}
        />
      </Modal.Content>
    </Modal>
  );
};
export default PrintPrice;
