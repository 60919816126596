import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { Container } from 'semantic-ui-react';
import { Table } from '../../_shared';
import { reportsActions } from '../../_store/actions';
import { reportsSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { TopTenItem } from '../_models';
import { TableColumn } from '../../_shared/table/Table';

type Props = {
  storeId: string;
};

const columns: TableColumn[] = [
  { label: 'REPORTS.TABLE.RANKING', name: 'ranking', sortable: false },
  { label: 'REPORTS.TABLE.CODE', name: 'code', sortable: false },
  { label: 'REPORTS.TABLE.DESCRIPTION', name: 'description', sortable: false },
  { label: 'REPORTS.TABLE.QUANTITY', name: 'value', sortable: false },
];

const ReportsTopTen = ({ storeId }: Props) => {
  const topTen = useSelector(reportsSelectors.topTen);
  const isLoading = useSelector(reportsSelectors.isGetTopTenLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new reportsActions.GetTopTen({ storeId }));
  }, []);

  const renderRow = (item: TopTenItem, index: number) => {
    const className = classnames({ 'greyed-out': index % 2 });

    return (
      <Table.Row className={className} key={item.id}>
        <Table.Cell className="edit">{index + 1}</Table.Cell>
        <Table.Cell className="code-cell">{item.code}</Table.Cell>
        <Table.Cell className="code-cell">{item.description}</Table.Cell>
        <Table.Cell className="code-cell">{item.value}</Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Container as="main" className="reports-top-ten">
      <Table
        columns={columns}
        data={topTen || []}
        emptyLabel={translations.getLabel('REPORTS.TOP_TEN.EMPTY')}
        fixed={false}
        isLoading={isLoading}
        renderRow={renderRow}
      />
    </Container>
  );
};

export default ReportsTopTen;
