import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { keepRetrievedDataPage, insertUpdatedData } from '../../_utils/objectHelpers';
import { StockItem, StockChange } from '../_models';
import { StockAction, StockActionType } from './actions';

export type StockState = {
  errorCrudStockChange?: ApiError;
  errorCrudStockItems?: ApiError;
  isGetStockChangeLoading?: boolean;
  isGetStockItemsLoading?: boolean;
  stockChange?: StockChange[];
  stockChangeMetadata?: HttpMetadataPagingResponse;
  stockChangeQuery?: HttpMetadataQuery;
  stockItemMetadata?: HttpMetadataPagingResponse;
  stockitems?: StockItem[];
};

export const initialState: StockState = {};

export default function reducer(state = initialState, action: StockAction): StockState {
  switch (action.type) {
    case StockActionType.GetStockChange:
      return {
        ...state,
        errorCrudStockChange: null,
        isGetStockChangeLoading: true,
        stockChangeMetadata: null,
      };
    case StockActionType.GetStockChangeSuccess: {
      return {
        ...state,
        isGetStockChangeLoading: false,
        stockChange: keepRetrievedDataPage(state.stockChange, action.payload.data, action.payload.meta),
        stockChangeMetadata: action.payload.meta,
      };
    }
    case StockActionType.GetStockChangeError:
      return {
        ...state,
        errorCrudStockChange: action.payload.error,
        isGetStockChangeLoading: false,
      };
    case StockActionType.GetStockItems:
      return {
        ...state,
        errorCrudStockItems: null,
        isGetStockItemsLoading: true,
        stockItemMetadata: null,
      };
    case StockActionType.GetStockItemsSuccess: {
      return {
        ...state,
        isGetStockItemsLoading: false,
        stockItemMetadata: action.payload.meta,
        stockitems: keepRetrievedDataPage(state.stockitems, action.payload.data, action.payload.meta),
      };
    }
    case StockActionType.GetStockItemsError:
      return {
        ...state,
        errorCrudStockItems: action.payload.error,
        isGetStockItemsLoading: false,
      };
    case StockActionType.SetStockChangeQuery:
      return {
        ...state,
        stockChangeQuery: action.payload.query,
      };
    case StockActionType.UpdateStockItem:
      return {
        ...state,
        errorCrudStockItems: null,
        isGetStockItemsLoading: true,
      };
    case StockActionType.UpdateStockItemSuccess:
      return {
        ...state,
        isGetStockItemsLoading: false,
        stockitems: insertUpdatedData(state.stockitems, [action.payload.updatedStockItem]),
      };
    case StockActionType.UpdateStockItemError:
      return {
        ...state,
        errorCrudStockItems: action.payload.error,
        isGetStockItemsLoading: false,
      };
    default:
      return state;
  }
}
