import React from 'react';
import classnames from 'classnames';
import { translations } from '../../../_translations';
import { parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TOtherFurniture } from '../../_models/OtherFurniture';
import { otherFurnitureTableColumns } from '../table/OtherFurnitureTable';
import { BottomBar } from '../../../_shared';
import './otherFurnitureBottomBar.scss';

type TProps = {
  otherFurniture?: TOtherFurniture;
};

const OtherFurnitureBottomBar: React.FC<TProps> = ({ otherFurniture }) => {
  return (
    <BottomBar>
      <div className="bottom-bar__table-wrapper" id="otherFurnitureBottomBar">
        <table>
          <colgroup>
            {otherFurnitureTableColumns.map(column => (
              <col className={classnames(column.className)} key={column.name} />
            ))}
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={5}>{translations.getLabel('LEDGER.GENERAL.TOTAL')}</td>
              <td colSpan={1}>{parseCurrencyWithPlaceholder(otherFurniture?.totalPrice)}</td>
              <td colSpan={10} />
              <td colSpan={1}> {parseCurrencyWithPlaceholder(otherFurniture?.totalRevenue)} </td>
              <td className={otherFurniture?.totalOwedAmount >= 0 ? 'positive' : 'negative'} colSpan={1}>
                {parseCurrencyWithPlaceholder(otherFurniture?.totalOwedAmount)}
              </td>
              <td colSpan={1} />
            </tr>
          </tbody>
        </table>
      </div>
    </BottomBar>
  );
};

export default OtherFurnitureBottomBar;
