import React, { FC } from 'react';
import { translations } from '../../_translations';
import { Button, InputField, ErrorMessage, ActiveStateCheckbox } from '../../_shared';
import { useForm } from '../../_hooks';
import { FormValidationErrors, SubmitFormFunction } from '../../_hooks/useForm';
import { formValidator } from '../../_utils/formValidation';
import { ApiError } from '../../_http';
import { ICodeForm } from '../_models';
import { errorAsString } from '../../_utils/errorHelper';
import './codeForm.scss';

type Props = {
  buttons?: JSX.Element | JSX.Element[];
  error?: ApiError;
  initialForm: ICodeForm;
  isSubmitting?: boolean;
  submitForm: SubmitFormFunction<ICodeForm>;
};

function validateForm(values: ICodeForm): FormValidationErrors<ICodeForm> {
  return {
    code: formValidator.required(values.code).error,
    name: formValidator.required(values.name).error,
    type: formValidator.required(values.type).error,
  };
}

const CodeForm: FC<Props> = ({ initialForm, submitForm, isSubmitting, error, buttons }) => {
  const form = useForm<ICodeForm>({ error, initialForm, submitForm, validateForm });
  const errorMessage = errorAsString(error);

  return (
    <form className="roleForm" onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>

      <InputField
        disabled
        errorMessage={form.validationErrors.code}
        label={translations.getLabel('CODES.CODE')}
        name="code"
        onChange={form.setAttribute}
        required
        type="text"
        value={form.values.code}
      />

      <InputField
        errorMessage={form.validationErrors.name}
        label={translations.getLabel('CODES.NAME')}
        name="name"
        onChange={form.setAttribute}
        required
        type="text"
        value={form.values.name}
      />

      <InputField
        errorMessage={form.validationErrors.description}
        label={translations.getLabel('CODES.DESCRIPTION')}
        name="description"
        onChange={form.setAttribute}
        type="text"
        value={form.values.description}
      />

      <div className="group" role="group">
        <InputField
          errorMessage={form.validationErrors.serialNumber}
          label={translations.getLabel('CODES.SERIALNUMBER')}
          name="serialNumber"
          onChange={(value, name) => form.setAttribute(parseInt(value), name)}
          type="number"
          value={form.values.serialNumber.toString()}
        />

        <ActiveStateCheckbox name="state" onChange={form.setAttribute} state={form.values.state} />
      </div>

      <div className="actions">
        {buttons}
        <Button loading={isSubmitting} primary type="submit">
          {translations.getLabel('SHARED.BUTTONS.CREATE')}
        </Button>
      </div>
    </form>
  );
};

export default CodeForm;
