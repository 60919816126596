import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '../../_shared';
import { translations } from '../../_translations';
import './user-delete-modal.scss';
import { usersActions } from '../../_store/actions';

type Props = {
  closeModal?: () => void;
  userId?: string;
};

const UserDeleteModal: FC<Props> = ({ closeModal, userId }) => {
  const dispatch = useDispatch();

  return (
    <Modal className="user-delete-modal" open>
      <header>
        <label>{translations.getLabel('USERS.DELETE.TITLE')}</label>
      </header>
      <Modal.Content>
        <h3>{translations.getLabel('USERS.DELETE.ATTENTION')}</h3>
        <p>{translations.getLabel('USERS.DELETE.INFO')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button asText onClick={closeModal}>
          {translations.getLabel('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button onClick={() => dispatch(new usersActions.DeleteUser({ userId }))} primary>
          {translations.getLabel('SHARED.BUTTONS.DELETE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserDeleteModal;
