import React, { FC } from 'react';
import { translations } from '../../../_translations';
import { Dropdown, InputField } from '../../../_shared';
import { FormValidationErrors, IFormHook } from '../../../_hooks/useForm';
import { formValidator } from '../../../_utils/formValidation';
import { IProductForm } from '../../_models';
import './productform.scss';

type Props = {
  buttons?: JSX.Element | JSX.Element[];
  form?: IFormHook<IProductForm>;
  isEdit?: boolean;
  isWarehouse?: boolean;
  showExtraPrice: boolean;
  showPurchasePrice: boolean;
  showSellingPrice: boolean;
  showWarehouseStockCount: boolean;
};

export function validateForm(values: IProductForm): FormValidationErrors<IProductForm> {
  return {
    code: formValidator.required(values.code).error,
    description: formValidator.required(values.description).error,
  };
}
const isChairOptions = [
  {
    text: translations.getLabel('PRODUCTS.YES'),
    value: 'true',
  },
  {
    text: translations.getLabel('PRODUCTS.NO'),
    value: 'false',
  },
];

const ProductForm: FC<Props> = ({
  form,
  buttons,
  isEdit,
  showExtraPrice,
  showPurchasePrice,
  showSellingPrice,
  isWarehouse,
  showWarehouseStockCount,
}) => {
  return (
    <form className="product-form">
      <div className="content-wrapper">
        <div className="product-wrapper">
          <InputField
            errorMessage={form.validationErrors.code}
            label={translations.getLabel('PRODUCTS.TABLE.CODE')}
            name="code"
            onChange={form.setAttribute}
            required
            type="text"
            value={form.values.code}
          />

          <InputField
            errorMessage={form.validationErrors.description}
            label={translations.getLabel('PRODUCTS.TABLE.DESCRIPTION')}
            name="description"
            onChange={form.setAttribute}
            required
            type="text"
            value={form.values.description}
          />

          <InputField
            errorMessage={form.validationErrors.dimensions}
            label={translations.getLabel('PRODUCTS.TABLE.DIMENSIONS')}
            name="dimensions"
            onChange={form.setAttribute}
            type="text"
            value={form.values.dimensions}
          />

          <InputField
            errorMessage={form.validationErrors.remark}
            label={translations.getLabel('PRODUCTS.TABLE.REMARK')}
            name="remark"
            onChange={form.setAttribute}
            type="text"
            value={form.values.remark}
          />

          <InputField
            errorMessage={form.validationErrors.zone}
            label={translations.getLabel('PRODUCTS.TABLE.ZONE')}
            name="zone"
            onChange={form.setAttribute}
            type="text"
            value={form.values.zone}
          />

          <InputField
            errorMessage={form.validationErrors.expected}
            label={translations.getLabel('PRODUCTS.TABLE.EXPECTED')}
            name="expected"
            onChange={form.setAttribute}
            type="text"
            value={form.values.expected}
          />
          <Dropdown
            errorMessage={form.validationErrors.isChair}
            label={translations.getLabel('PRODUCTS.TABLE.IS_CHAIR')}
            name="isChair"
            onChange={(isChair: string) => form.setAttribute(isChair === 'true', 'isChair')}
            options={isChairOptions}
            value={form.values.isChair.toString()}
          />
        </div>
        <div className="price-wrapper">
          <div>
            {showPurchasePrice && (
              <InputField
                errorMessage={form.validationErrors.purchasePrice}
                label={translations.getLabel('PRODUCTS.TABLE.FULL_PURCHASE_PRICE')}
                name="purchasePrice"
                onChange={form.setAttribute}
                type="text"
                value={form.values.purchasePrice}
              />
            )}

            {showSellingPrice && (
              <InputField
                errorMessage={form.validationErrors.sellingPrice}
                label={translations.getLabel('PRODUCTS.TABLE.FULL_SELLING_PRICE')}
                name="sellingPrice"
                onChange={form.setAttribute}
                type="text"
                value={form.values.sellingPrice}
              />
            )}

            {showExtraPrice && (
              <InputField
                errorMessage={form.validationErrors.extraPrice}
                label={translations.getLabel('PRODUCTS.TABLE.EXTRA_PRICE')}
                name="extraPrice"
                onChange={form.setAttribute}
                type="text"
                value={form.values.extraPrice}
              />
            )}
          </div>
          {!isEdit && (
            <InputField
              errorMessage={form.validationErrors.initalStock}
              label={translations.getLabel('PRODUCTS.TABLE.INITIAL_STOCK')}
              name="initalStock"
              onChange={form.setAttribute}
              type="text"
              value={form.values.initalStock}
            />
          )}
          <InputField
            errorMessage={form.validationErrors.reserved}
            label={translations.getLabel('PRODUCTS.TABLE.RESERVED')}
            name="reserved"
            onChange={form.setAttribute}
            type="text"
            value={form.values.reserved}
          />
          {isWarehouse && showWarehouseStockCount && (
            <InputField
              errorMessage={form.validationErrors.warehouseStockCount}
              label={translations.getLabel('PRODUCTS.TABLE.WAREHOUSE_STOCK_COUNT')}
              maxLength={4}
              name="warehouseStockCount"
              onChange={form.setAttribute}
              type="text"
              value={form.values.warehouseStockCount.toString()}
            />
          )}
        </div>
      </div>
      {buttons}
    </form>
  );
};

export default ProductForm;
