import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAdminStore } from '../_models';
import { translations } from '../../_translations';
import { storesSelectors } from '../../_store/selectors';
import { storesActions } from '../../_store/actions';
import { Modal, Button, ErrorMessage } from '../../_shared';
import StoreForm from '../edit/StoreForm';
import { ApiError } from '../../_http';
import './NewStoreModal.scss';
import { ItemState } from '../../_utils/stateHelper';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
};

const initialForm: IAdminStore = {
  code: '',
  color: '#FFFFFF',
  contactEmail: '',
  contactPerson: '',
  description: '',
  id: '',
  name: '',
  serialNumber: 0,
  state: ItemState.Active,
};

const NewStoreModal: FC<Props> = ({ closeModal, errorAsString }) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(storesSelectors.isCreateStoreLoading);
  const error = useSelector(storesSelectors.errorCrudStores);
  const errorMessage = errorAsString(error);

  return (
    <Modal onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('MANAGEMENT.STORES.NEW_STORE.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <StoreForm
          buttons={
            <Button asText onClick={closeModal}>
              {translations.getLabel('SHARED.BUTTONS.CANCEL')}
            </Button>
          }
          error={error}
          initialForm={initialForm}
          isSubmitting={isSubmitting}
          submitForm={(values: IAdminStore) => dispatch(new storesActions.CreateStore({ onSuccess: closeModal, values }))}
        />
      </Modal.Content>
    </Modal>
  );
};

export default NewStoreModal;
