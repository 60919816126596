import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, tap, catchError, exhaustMap, switchMap } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { translations } from '../../_translations';
import * as storesSelectors from './selectors';
import * as storesActions from './actions';
import * as storesApi from './api';

const getStoreDetailEpic$: Epic = action$ =>
  action$.ofType(storesActions.StoresActionType.GetStoreDetail).pipe(
    exhaustMap(({ payload }: storesActions.GetStoreDetail) => {
      return from(storesApi.getStoreDetail(payload.storeId)).pipe(
        map(data => new storesActions.GetStoreDetailSuccess({ data })),
        catchError(error => of(new storesActions.GetStoreDetailError({ error }))),
      );
    }),
  );

const getAdminStoresEpic$: Epic = (action$, state$) =>
  action$.ofType(storesActions.StoresActionType.GetAdminStores).pipe(
    exhaustMap(() => {
      const query = storesSelectors.query(state$.value);
      return from(storesApi.getAdminStores(query)).pipe(
        map(({ data, meta }) => new storesActions.GetAdminStoresSuccess({ data, meta })),
        catchError(error => of(new storesActions.GetAdminStoresError({ error }))),
      );
    }),
  );

const createStoreEpic$: Epic = action$ =>
  action$.ofType(storesActions.StoresActionType.CreateStore).pipe(
    switchMap(({ payload }: storesActions.CreateStore) =>
      from(storesApi.createStore(payload.values)).pipe(
        tap(() =>
          toast.success(translations.getLabel('MANAGEMENT.STORES.TOASTER.STORE_CREATED'), {
            position: toast.POSITION.TOP_RIGHT,
          }),
        ),
        map(createdStore => {
          payload.onSuccess?.();
          return new storesActions.CreateStoreSuccess({ createdStore });
        }),
        catchError(error => of(new storesActions.CreateStoreError({ error }))),
      ),
    ),
  );

const updateStoreEpic$: Epic = action$ =>
  action$.ofType(storesActions.StoresActionType.UpdateStore).pipe(
    exhaustMap(({ payload }: storesActions.UpdateStore) =>
      from(storesApi.updateStore(payload.storeId, payload.values)).pipe(
        tap(() =>
          toast.success(translations.getLabel('MANAGEMENT.STORES.TOASTER.STORE_UPDATED'), {
            position: toast.POSITION.TOP_RIGHT,
          }),
        ),
        map(updatedStore => {
          payload.onSuccess?.();
          return new storesActions.UpdateStoreSuccess({ updatedStore });
        }),
        catchError(error => of(new storesActions.UpdateStoreError({ error }))),
      ),
    ),
  );

export default [getAdminStoresEpic$, getStoreDetailEpic$, createStoreEpic$, updateStoreEpic$];
