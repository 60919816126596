import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Code } from '../codes/_models';
import { hasViewPermissions } from '../profile/_utils';
import SecondaryNav from '../_routing/layouts/authorized/menu/SecondaryNav';
import { ForbiddenPage } from '../_shared';
import { codesSelectors, profileSelectors, storesSelectors } from '../_store/selectors';
import StockOverview from './overview/StockOverview';

const Stock: React.FC = () => {
  const { url } = useRouteMatch();
  const { storeId } = useParams();
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);

  const hasViewPermission = hasViewPermissions(codes, Code.Stock, currentStore?.id, profile.permissions);

  return (
    <>
      <SecondaryNav />
      <Switch>
        <Route
          path={url}
          render={props => (hasViewPermission ? <StockOverview {...props} storeId={storeId} /> : <ForbiddenPage />)}
        />
      </Switch>
    </>
  );
};

export default Stock;
