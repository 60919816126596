import { Product } from '../../products/_models';

export * from './rules';

type Store = {
  id: string;
};

export type StockItem = {
  createdAt?: string;
  createdBy?: string;
  expected: string;
  id: string;
  isSyncFailed: boolean;
  product: Product;
  store: Store;
  updatedAt?: string;
  updatedBy?: string;
  value: number;
  zone: string;
};

export type IUpdateStockItem = {
  addition?: string | number;
  zone?: string | null;
};

export type StockChange = {
  createdAt: string;
  createdBy: string;
  id: string;
  stockItem: StockItem;
  store: Store;
  type: StockChangeState;
  updatedAt: string;
  updatedBy: string;
  value: number;
};

export enum StockChangeState {
  STOCK_CHANGE = 'STOCK_CHANGE',
}

export type Ids = {
  price?: string;
  product?: string;
  stock?: string;
  stockItem?: string;
  store?: string;
};
