import { HttpMetadataQuery, HttpPagedResponse, HttpClient } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { ItemState } from '../../_utils/stateHelper';
import { StockItem, IUpdateStockItem, StockChange, Ids } from '../_models';

export function getStockChange(storeId: string, query?: HttpMetadataQuery): Promise<HttpPagedResponse<StockChange>> {
  return HttpClient.get<HttpPagedResponse<StockChange>>(`stock-change/${getQueryParams(query)}`, null, { 'store-id': storeId });
}

export function getStockItems(
  storeId: string,
  state: ItemState,
  skip?: number,
  take?: number,
): Promise<HttpPagedResponse<StockItem>> {
  return HttpClient.get<HttpPagedResponse<StockItem>>(`stock-items/${getQueryParams({ skip, state, take })}`, null, {
    'store-id': storeId,
  });
}

export function updateStockItem(ids: Ids, values: IUpdateStockItem): Promise<StockItem> {
  return HttpClient.put<StockItem>(`stock-items/${ids.stock}`, values, { 'store-id': ids.store });
}
