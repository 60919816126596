import { toast } from 'react-toastify';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap, delay, filter, switchMap } from 'rxjs/operators';
import { ApiError } from '../../_http';
import { cashSheetActions } from '../../_store/actions';
import { cashSheetsSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { CashSheetsActionType, IUpdateCashSheetAction } from './actions';
import * as cashSheetsApi from './api';

const getCashSheetDetailEpic$: Epic = (action$, state$) =>
  action$.ofType(CashSheetsActionType.GetCashSheetDetail).pipe(
    exhaustMap(({ payload }: cashSheetActions.GetCashSheetDetail) => {
      const query = cashSheetsSelectors.query(state$.value);
      return from(cashSheetsApi.getCashSheetDetail(payload.storeId, query)).pipe(
        map(data => new cashSheetActions.GetCashSheetDetailSuccess({ data })),
        catchError(error => of(new cashSheetActions.GetCashSheetDetailError({ error }))),
      );
    }),
  );

const getCashSheetDetailSearchEpic$: Epic = (action$, state$) =>
  action$.ofType(CashSheetsActionType.GetCashSheetDetailSearch).pipe(
    exhaustMap(({ payload }: cashSheetActions.GetCashSheetDetailSearch) => {
      const search = cashSheetsSelectors.search(state$.value);
      return from(cashSheetsApi.getCashSheetDetailSearch(payload.storeId, search)).pipe(
        map(data => new cashSheetActions.GetCashSheetDetailSearchSuccess({ data })),
        catchError(error => of(new cashSheetActions.GetCashSheetDetailSearchError({ error }))),
      );
    }),
  );

const setCashSheetQueryEpic$: Epic = action$ =>
  action$
    .ofType(CashSheetsActionType.SetCashSheetQuery)
    .pipe(
      map(
        ({ payload }: cashSheetActions.GetCashSheetDetail) =>
          new cashSheetActions.GetCashSheetDetail({ storeId: payload.storeId }),
      ),
    );

const setCashSheetSearchEpic$: Epic = (action$, state$) =>
  action$.ofType(CashSheetsActionType.SetCashSheetSearch).pipe(
    map(({ payload }: cashSheetActions.GetCashSheetDetailSearch) => {
      const search = cashSheetsSelectors.search(state$.value);
      if (!search?.search?.length) return new cashSheetActions.ClearCashSheetSearch();
      return new cashSheetActions.GetCashSheetDetailSearch({ storeId: payload.storeId });
    }),
  );

//check if the cell we want to set undefined is still active so no more recent selected cell gets set undefined
const clearActiveCellEpic$: Epic = (action$, state$) =>
  action$.ofType(CashSheetsActionType.ClearActiveCell).pipe(
    delay(500),
    filter(({ payload }: cashSheetActions.ClearActiveCell) => {
      const currenctActiveCell = cashSheetsSelectors.activeCell(state$.value);
      if (!currenctActiveCell) return false;
      if (payload.activeCell.array !== currenctActiveCell.array) return false;
      if (payload.activeCell.index !== currenctActiveCell.index) return false;
      if (payload.activeCell.name !== currenctActiveCell.name) return false;
      return true;
    }),
    map(() => new cashSheetActions.SetActiveCell({ activeCell: undefined })),
  );

const updateCashSheetEpic$: Epic = action$ =>
  action$.pipe(
    ofType(CashSheetsActionType.UpdateCashSheet),
    filter(({ payload }: cashSheetActions.UpdateCashSheet) => !!payload.cashSheetId),
    switchMap((action: cashSheetActions.UpdateCashSheet) => {
      const { payload } = action;
      return from(cashSheetsApi.updateCashSheet(payload.cashSheetId, payload.storeId, payload.values)).pipe(
        map(() => new cashSheetActions.UpdateCashSheetSuccess({ storeId: payload.storeId })),
        catchError(error => of(new cashSheetActions.UpdateCashSheetError({ error }))),
      );
    }),
  );

const deleteSaleEpic$: Epic = action$ =>
  action$.pipe(
    ofType(CashSheetsActionType.DeleteSale),
    exhaustMap(({ payload }: cashSheetActions.DeleteSale) => {
      return from(cashSheetsApi.deleteSale(payload.cashSheetId, payload.storeId, payload.saleId)).pipe(
        map(() => new cashSheetActions.DeleteSaleSuccess({ storeId: payload.storeId })),
        catchError(error => of(new cashSheetActions.DeleteSaleError({ error }))),
      );
    }),
  );

const deleteDeliveryEpic$: Epic = action$ =>
  action$.pipe(
    ofType(CashSheetsActionType.DeleteDelivery),
    exhaustMap(({ payload }: cashSheetActions.DeleteDelivery) => {
      return from(cashSheetsApi.deleteDelivery(payload.cashSheetId, payload.storeId, payload.deliveryId)).pipe(
        map(() => new cashSheetActions.DeleteDeliverySuccess({ storeId: payload.storeId })),
        catchError(error => of(new cashSheetActions.DeleteDeliveryError({ error }))),
      );
    }),
  );

// cashSheets do not exist by default, they first need to be created
const checkCashSheetExistenceEpic$: Epic = (action$, state$) =>
  action$
    .ofType(
      CashSheetsActionType.UpdateSale,
      CashSheetsActionType.UpdateDecorationRevenue,
      CashSheetsActionType.UpdateDelivery,
      CashSheetsActionType.UpdateCashSheet,
    )
    .pipe(
      filter(({ payload }: IUpdateCashSheetAction) => !payload.cashSheetId),
      switchMap((action: IUpdateCashSheetAction) => {
        const { payload } = action;
        const query = cashSheetsSelectors.query(state$.value);
        return from(cashSheetsApi.createCashSheet(payload.storeId, { date: query.date })).pipe(
          map(data => new cashSheetActions.CreateCashSheetSuccess({ data })),
          map(success => {
            return { ...action, payload: { ...action.payload, cashSheetId: success.payload.data.id } };
          }),
          catchError(error => of(new cashSheetActions.CreateCashSheetError({ error }))),
        );
      }),
    );

const updateSaleEpic$: Epic = action$ =>
  action$.ofType(CashSheetsActionType.UpdateSale).pipe(
    filter(({ payload }: cashSheetActions.UpdateSale) => !!payload.cashSheetId),
    switchMap((action: cashSheetActions.UpdateSale) => {
      const { payload } = action;
      const call = payload.saleId
        ? cashSheetsApi.updateSale(payload.cashSheetId, payload.storeId, payload.saleId, payload.values)
        : cashSheetsApi.createSale(payload.cashSheetId, payload.storeId, payload.values);
      return from(call).pipe(
        map(() => new cashSheetActions.UpdateSaleSuccess({ storeId: payload.storeId })),
        catchError((error: ApiError) => {
          if (error.error === 'RECEIPT_CODE_ALREADY_EXISTS') {
            toast.error(translations.getLabel('CASH_SHEETS.ERRORS.RECEIPT_CODE_ALREADY_EXISTS'));
          }
          return of(new cashSheetActions.UpdateSaleError({ error }));
        }),
      );
    }),
  );

const updateDeliveryEpic$: Epic = action$ =>
  action$.ofType(CashSheetsActionType.UpdateDelivery).pipe(
    filter(({ payload }: cashSheetActions.UpdateDelivery) => !!payload.cashSheetId),
    switchMap((action: cashSheetActions.UpdateDelivery) => {
      const { payload } = action;
      const call = payload.deliveryId
        ? cashSheetsApi.updateDelivery(payload.cashSheetId, payload.storeId, payload.deliveryId, payload.values)
        : cashSheetsApi.createDelivery(payload.cashSheetId, payload.storeId, payload.values);
      return from(call).pipe(
        map(() => new cashSheetActions.UpdateDeliverySuccess({ storeId: payload.storeId })),
        catchError(error => of(new cashSheetActions.UpdateDeliveryError({ error }))),
      );
    }),
  );

const updateDecorationRevenueEpic$: Epic = action$ =>
  action$.ofType(CashSheetsActionType.UpdateDecorationRevenue).pipe(
    filter(({ payload }: cashSheetActions.UpdateDecorationRevenue) => !!payload.cashSheetId),
    switchMap((action: cashSheetActions.UpdateDecorationRevenue) => {
      const { payload } = action;
      const call = payload.decorationRevenueId
        ? cashSheetsApi.updateDecorationRevenue(payload.cashSheetId, payload.storeId, payload.decorationRevenueId, payload.values)
        : cashSheetsApi.createDecorationRevenue(payload.cashSheetId, payload.storeId, payload.values);
      return from(call).pipe(
        map(() => new cashSheetActions.UpdateDecorationRevenueSuccess({ storeId: payload.storeId })),
        catchError(error => of(new cashSheetActions.UpdateDecorationRevenueError({ error }))),
      );
    }),
  );

const refreshCashSheetSuccessEpic$: Epic = action$ =>
  action$.pipe(
    ofType(
      CashSheetsActionType.UpdateCashSheetSuccess,
      CashSheetsActionType.UpdateDecorationRevenueSuccess,
      CashSheetsActionType.UpdateDeliverySuccess,
      CashSheetsActionType.UpdateSaleSuccess,
      CashSheetsActionType.DeleteDeliverySuccess,
      CashSheetsActionType.DeleteSaleSuccess,
    ),
    exhaustMap(({ payload }: { payload: { storeId: string } }) =>
      of(new cashSheetActions.GetCashSheetDetail({ storeId: payload.storeId })),
    ),
  );

export default [
  getCashSheetDetailEpic$,
  getCashSheetDetailSearchEpic$,
  setCashSheetQueryEpic$,
  setCashSheetSearchEpic$,
  clearActiveCellEpic$,
  updateCashSheetEpic$,
  refreshCashSheetSuccessEpic$,
  updateSaleEpic$,
  updateDeliveryEpic$,
  updateDecorationRevenueEpic$,
  checkCashSheetExistenceEpic$,
  deleteDeliveryEpic$,
  deleteSaleEpic$,
];
