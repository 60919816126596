import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { LedgerState } from './reducer';

const selectNode = (state: AppState) => state.ledger;

export const revenuePerSeller = createSelector(selectNode, (state: LedgerState) => state.revenuePerSeller);
export const bankTransfers = createSelector(selectNode, (state: LedgerState) => state.bankTransfers);
export const month = createSelector(selectNode, (state: LedgerState) => state.month);
export const year = createSelector(selectNode, (state: LedgerState) => state.year);
export const activeCell = createSelector(selectNode, (state: LedgerState) => state.activeCell);
export const errorCrudLedger = createSelector(selectNode, (state: LedgerState) => state.errorCrudLedger);
export const isRevenuePerSellerLoading = createSelector(selectNode, (state: LedgerState) => state.isRevenuePerSellerLoading);
export const isBankTransfersLoading = createSelector(selectNode, (state: LedgerState) => state.isBankTransfersLoading);
export const decorationRevenueQuery = createSelector(selectNode, (state: LedgerState) => state.decorationQuery);
export const otherFurnitureQuery = createSelector(selectNode, (state: LedgerState) => state.otherFurnitureQuery);
export const decorationRevenue = createSelector(selectNode, (state: LedgerState) => state.decorationRevenue);
export const otherFurniture = createSelector(selectNode, (state: LedgerState) => state.otherFurniture);
export const isDecorationRevenuesLoading = createSelector(selectNode, (state: LedgerState) => state.isDecorationRevenuesLoading);
export const isOtherFurnitureLoading = createSelector(selectNode, (state: LedgerState) => state.isOtherFurnitureLoading);
