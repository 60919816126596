import { Action } from 'redux';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { IAdminStore, IStore } from '../_models';

export enum StoresActionType {
  CreateStore = '[Stores] CreateStore',
  CreateStoreError = '[Stores] CreateStoreError',
  CreateStoreSuccess = '[Stores] CreateStoreSuccess',
  GetAdminStores = '[Stores] GetAdminStores',
  GetAdminStoresError = '[Stores] GetAdminStoresError',
  GetAdminStoresSuccess = '[Stores] GetAdminStoresSuccess',
  GetStoreDetail = '[Stores] GetStoreDetail',
  GetStoreDetailError = '[Stores] GetStoreDetailError',
  GetStoreDetailSuccess = '[Stores] GetStoreDetailSuccess',
  SetStoresQuery = '[Stores] SetStoresQuery',
  UpdateCurrentStore = '[Stores] UpdateCurrentStore',
  UpdateStore = '[Stores] UpdateStore',
  UpdateStoreError = '[Stores] UpdateStoreError',
  UpdateStoreSuccess = '[Stores] UpdateStoreSuccess',
}

export class GetStoreDetail implements Action<StoresActionType> {
  readonly type = StoresActionType.GetStoreDetail;
  constructor(public payload: { storeId: string }) {}
}

export class GetStoreDetailSuccess implements Action<StoresActionType> {
  readonly type = StoresActionType.GetStoreDetailSuccess;
  constructor(public payload: { data: IAdminStore }) {}
}

export class GetStoreDetailError implements Action<StoresActionType> {
  readonly type = StoresActionType.GetStoreDetailError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetAdminStores implements Action<StoresActionType> {
  readonly type = StoresActionType.GetAdminStores;
}

export class GetAdminStoresSuccess implements Action<StoresActionType> {
  readonly type = StoresActionType.GetAdminStoresSuccess;
  constructor(public payload: { data: IAdminStore[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetAdminStoresError implements Action<StoresActionType> {
  readonly type = StoresActionType.GetAdminStoresError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetStoresQuery implements Action<StoresActionType> {
  readonly type = StoresActionType.SetStoresQuery;
  constructor(public payload: { query: HttpMetadataQuery }) {}
}

export class CreateStore implements Action<StoresActionType> {
  readonly type = StoresActionType.CreateStore;
  constructor(public payload: { onSuccess?: () => void; values: IAdminStore }) {}
}

export class CreateStoreSuccess implements Action<StoresActionType> {
  readonly type = StoresActionType.CreateStoreSuccess;
  constructor(public payload: { createdStore: IAdminStore }) {}
}

export class CreateStoreError implements Action<StoresActionType> {
  readonly type = StoresActionType.CreateStoreError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateCurrentStore implements Action<StoresActionType> {
  readonly type = StoresActionType.UpdateCurrentStore;
  constructor(public payload: { store: IStore }) {}
}

export class UpdateStore implements Action<StoresActionType> {
  readonly type = StoresActionType.UpdateStore;
  constructor(public payload: { onSuccess?: () => void; storeId: string; values: IAdminStore }) {}
}

export class UpdateStoreSuccess implements Action<StoresActionType> {
  readonly type = StoresActionType.UpdateStoreSuccess;
  constructor(public payload: { updatedStore: IAdminStore }) {}
}

export class UpdateStoreError implements Action<StoresActionType> {
  readonly type = StoresActionType.UpdateStoreError;
  constructor(public payload: { error: ApiError }) {}
}

export type StoresAction =
  | GetStoreDetail
  | GetStoreDetailSuccess
  | GetStoreDetailError
  | GetAdminStores
  | GetAdminStoresSuccess
  | GetAdminStoresError
  | SetStoresQuery
  | CreateStore
  | CreateStoreSuccess
  | CreateStoreError
  | UpdateCurrentStore
  | UpdateStore
  | UpdateStoreSuccess
  | UpdateStoreError;
