import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Icon, InputFieldDate, SearchField } from '../../_shared';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { dateFromString, formatDate } from '../../_utils/timeHelpers';
import LedgerMenu from '../ledgerMenu/LedgerMenu';
import { TOtherFurnitureQuery } from '../_models/OtherFurniture';
import LedgerEmpty from '../ledgerEmpty/LedgerEmpty';
import { initialState } from '../_store/reducer';
import OtherFurnitureBottomBar from './bottomBar/OtherFurnitureBottomBar';
import OtherFurnitureTable from './table/OtherFurnitureTable';
import './ledgerOtherFurniture.scss';

type TProps = {
  storeId?: string;
};

const LedgerOtherFurniture: React.FC<TProps> = ({ storeId }) => {
  const otherFurniture = useSelector(ledgerSelectors.otherFurniture);
  const query = useSelector(ledgerSelectors.otherFurnitureQuery);
  const isLoading = useSelector(ledgerSelectors.isOtherFurnitureLoading);
  const dispatch = useDispatch();
  const dateFormat = 'yyyy';

  const setQuery = (partialQuery: TOtherFurnitureQuery) => {
    dispatch(new ledgerActions.SetOtherFurnitureQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useEffect(() => {
    dispatch(
      new ledgerActions.SetOtherFurnitureQuery({
        query: initialState.otherFurnitureQuery,
        storeId,
      }),
    );
  }, [storeId]);

  function renderContent() {
    if (otherFurniture?.sales.length === 0) {
      return <LedgerEmpty />;
    }
    return (
      <>
        <OtherFurnitureTable isLoading={isLoading} otherFurniture={otherFurniture} />
        <OtherFurnitureBottomBar otherFurniture={otherFurniture} />
      </>
    );
  }

  return (
    <main className="ledger-other-furniture">
      <div className="ledger-other-furniture__header">
        <LedgerMenu />
        <div className="ledger-other-furniture__header__controls">
          <SearchField query={query} setQuery={setQuery} />

          <InputFieldDate
            dateFormat={dateFormat}
            disabled={query.isOwedAmountZeroExcluded}
            name="year"
            onChange={(date: string) => setQuery({ year: formatDate(dateFromString(date, dateFormat), dateFormat) })}
            showYearPicker
            value={query.year}
          />
          <Button onClick={() => window.print()} primary>
            <Icon name="SvgPrint" size={2} />
            {translations.getLabel('CASH_SHEETS.OVERVIEW.PRINT_PAGE')}
          </Button>
        </div>
      </div>
      <Checkbox
        label={translations.getLabel('LEDGER.OPEN_SALES')}
        name="opensales"
        onChange={() => setQuery({ isOwedAmountZeroExcluded: !query.isOwedAmountZeroExcluded })}
        toggle
      />
      {renderContent()}
    </main>
  );
};

export default LedgerOtherFurniture;
