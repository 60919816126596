import { ItemState } from '../_utils/stateHelper';

export type HttpMetadataPagingResponse = {
  count: number;
  skip: number;
  totalCount: number;
};

export enum HttpSortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export type HttpPagedResponse<T> = {
  data: T[];
  meta: HttpMetadataPagingResponse;
};

export type ProductsHttpMetadataPagingResponse = HttpMetadataPagingResponse & {
  totalPrice: number;
};

export type ProductsHttpPagedResponse<T> = HttpPagedResponse<T> & {
  meta: ProductsHttpMetadataPagingResponse;
};

export type HttpMetadataQuery = {
  dateFilter?: string;
  fromDate?: string;
  search?: string;
  skip?: number;
  sortBy?: string;
  sortDirection?: HttpSortDirection;
  state?: ItemState;
  take?: number;
  toDate?: string;
};

export type FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => void;
