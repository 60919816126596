import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Highlight } from '../../../cashSheets/_models';
import { useForm } from '../../../_hooks';
import { CustomTable, InputFieldText } from '../../../_shared';
import { ledgerActions } from '../../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../../_store/selectors';
import { parseCurrency, parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TDecorationForm, TDecorationRevenue } from '../../_models';

type TProps = {
  decoration: TDecorationRevenue;
  highlight?: Highlight;
  index: number;
  initialForm: TDecorationForm;
};

const DecorationRevenueForm: React.FC<TProps> = ({ initialForm, index, decoration, highlight }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const error = useSelector(ledgerSelectors.errorCrudLedger);

  const form = useForm<TDecorationForm>({ initialForm, submitForm: () => ({}), validateForm: () => ({}) });

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [decoration]);

  useEffect(() => {
    if (error) {
      form.setFormValues(initialForm);
    }
  }, [error]);

  const onBlur = (name: string, value: unknown) => {
    dispatch(new ledgerActions.ClearActiveCell({ activeCell: { array: 'decorationRevenue', index, name } }));
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new ledgerActions.UpdateDecorationRevenue({
            decorationRevenueId: decoration?.id,
            storeId: currentStore.id,
            values: { ...values, [name]: value },
          }),
        );
      });
    }
  };

  const onClick = (name: string) => {
    dispatch(
      new ledgerActions.SetActiveCell({
        activeCell: {
          array: 'decorationRevenue',
          highlight: decoration.highlight,
          id: decoration.id,
          index,
          name,
        },
      }),
    );
  };
  return (
    <CustomTable.Row className={highlight?.toLowerCase()} key={index}>
      <CustomTable.Cell className="bold centered-text">{index + 1}</CustomTable.Cell>
      <CustomTable.Cell>{decoration.date}</CustomTable.Cell>
      <CustomTable.Cell>{decoration.receiptCode}</CustomTable.Cell>
      <CustomTable.Cell className="bold">{parseCurrency(decoration.totalPrice)}</CustomTable.Cell>
      <CustomTable.Cell className="border-left">{parseCurrency(decoration.paidCash)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(decoration.paidBancontact)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(decoration.paidCreditCard)}</CustomTable.Cell>
      <CustomTable.Cell className="bold border-right">{parseCurrency(decoration.paidBankTransfer)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(decoration.totalRevenue)}</CustomTable.Cell>
      <CustomTable.Cell className={decoration.totalDifference >= 0 ? 'positive' : 'negative'}>
        {parseCurrencyWithPlaceholder(decoration.totalDifference)}
      </CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="note" onBlur={onBlur} onClick={onClick} value={form.values.note} />
      </CustomTable.CellEditable>
    </CustomTable.Row>
  );
};

export default DecorationRevenueForm;
