import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { pricesSelectors } from '../../_store/selectors';
import { pricesActions } from '../../_store/actions';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import { useModal, useSelectArray, useToggle } from '../../_hooks';
import { SearchField, TabMenu, Icon, Button } from '../../_shared';
import { errorAsString } from '../../_utils/errorHelper';
import { ItemState } from '../../_utils/stateHelper';
import { translations } from '../../_translations';
import PrintPrice from '../print/PrintPrice';
import { StockItem } from '../../stock/_models';
import PricesTable from './table/PricesTable';
import './pricesoverview.scss';

type Props = {
  storeId: string;
};

const PricesOverview: FC<Props> = ({ storeId }) => {
  const [isSaved, setIsSaved] = useToggle(false);
  const [isActive, setIsActive] = useToggle(true);
  const [printMultiple, togglePrintMultiple] = useToggle(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const { selectArray: selectedPrices, select: selectPrice, clear: clearSelectedPrices } = useSelectArray([]);

  const prices = useSelector(pricesSelectors.prices);
  const query = useSelector(pricesSelectors.query);
  const isLoading = useSelector(pricesSelectors.isGetPricesLoading);
  const dispatch = useDispatch();

  const [renderPrintPrice, showPrintPrice] = useModal<{
    isSinglePrint?: boolean;
    onSuccess?: () => void;
    stockItems: StockItem[];
  }>(modalProps => <PrintPrice {...modalProps} errorAsString={errorAsString} />);

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new pricesActions.SetPricesQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useEffect(() => {
    setQuery({ state: isActive ? ItemState.Active : ItemState.Inactive, take: isActive ? 1000 : 200 });
  }, [storeId]);

  useEffect(() => {
    if (!printMultiple) clearSelectedPrices();
  }, [printMultiple]);

  const showActivePrices = (active: boolean) => {
    setQuery({ state: active ? ItemState.Active : ItemState.Inactive, take: active ? 1000 : 200 });
    setIsActive(active);
  };

  function saveChanges(): void {
    clearTimeout(timer);
    setIsSaved(true);
    setTimer(setTimeout(() => setIsSaved(false), 3000));
  }

  const menuItems = [
    {
      isChecked: isActive,
      onClick: () => showActivePrices(true),
      text: translations.getLabel('PRICES.OVERVIEW.SHOW_ACTIVE'),
    },
    {
      isChecked: !isActive,
      onClick: () => showActivePrices(false),
      text: translations.getLabel('PRICES.OVERVIEW.SHOW_INACTIVE'),
    },
  ];

  const onPrint = () => {
    if (printMultiple) {
      showPrintPrice({
        isSinglePrint: false,
        onSuccess: () => togglePrintMultiple(),
        stockItems: prices.filter(stockItem => selectedPrices.includes(stockItem.id)),
      });
    } else {
      togglePrintMultiple();
    }
  };

  return (
    <Container as="main" className="prices">
      <div className="header">
        <div className="info-bar">
          <h4 className="overview-title">{translations.getLabel('PRICES.OVERVIEW.TITLE')}</h4>
          {isActive && (
            <span className={classnames('changes-saved-wrapper', { active: isSaved })}>
              <Icon name="SvgCheck" size={1.8} />
              <p>{translations.getLabel('STOCKITEMS.OVERVIEW.CHANGES_SAVED')}</p>
            </span>
          )}
        </div>
      </div>

      <div className="filter-wrapper">
        <SearchField query={query} setQuery={setQuery} />
        <TabMenu menuItems={menuItems} />
        <Button className="print-button" onClick={onPrint} primary>
          <Icon name="SvgPrint" />
          {translations.getLabel(printMultiple ? 'PRICES.PRINT.PRINT_SELECTION' : 'PRICES.PRINT.PRINT_MULTIPLE', {
            amount: selectedPrices.length,
          })}
        </Button>
        {printMultiple && (
          <Button asText onClick={() => togglePrintMultiple()}>
            {translations.getLabel('SHARED.BUTTONS.CANCEL')}
          </Button>
        )}
      </div>

      {renderPrintPrice()}
      <PricesTable
        data={prices}
        isChecked={isActive}
        isLoading={isLoading}
        onSelectPrice={selectPrice}
        printMultiple={printMultiple}
        saveChanges={saveChanges}
        selectedPrices={selectedPrices}
        setQuery={setQuery}
        showPrintPrice={showPrintPrice}
      />
    </Container>
  );
};

export default PricesOverview;
