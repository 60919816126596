import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translations } from '../../_translations';
import { codesSelectors, productsSelectors, profileSelectors, storesSelectors } from '../../_store/selectors';
import { productsActions } from '../../_store/actions';
import { Modal, Button, ErrorMessage, Icon } from '../../_shared';
import { ApiError } from '../../_http';
import { createProductForm, IProductForm, mapProductItem } from '../_models';
import ProductForm, { validateForm } from '../create/form/ProductForm';
import { canActivateStockItemProduct, canDeleteStockItemProduct, StockItem } from '../../stock/_models';
import { useForm, useModal } from '../../_hooks';
import ProductDeleteModal from '../delete/ProductDeleteModal';
import { hasEditPermissions } from '../../profile/_utils';
import { Code } from '../../codes/_models';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
  stockItem?: StockItem;
};

const ProductEditModal: FC<Props> = ({ closeModal, errorAsString, stockItem }) => {
  const isLoading = useSelector(productsSelectors.isUpdateProductsLoading);
  const error = useSelector(productsSelectors.errorCrudProducts);
  const currentStore = useSelector(storesSelectors.currentStore);
  const codes = useSelector(codesSelectors.codes);
  const permissions = useSelector(profileSelectors.permissions);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  const hasPurchasePricePermission = hasEditPermissions(codes, Code.PurchasePrice, currentStore?.id, permissions);
  const hasSellingPricePermission = hasEditPermissions(codes, Code.SellPrice, currentStore?.id, permissions);
  const hasExtraPricePermission = hasEditPermissions(codes, Code.ExtraPrice, currentStore?.id, permissions);
  const hasTotalPricePermission = hasEditPermissions(codes, Code.TotalPrice, currentStore?.id, permissions);

  const [renderProductDeleteModal, showProductDeleteModal] = useModal<{ stockItem: StockItem }>(modalProps => (
    <ProductDeleteModal {...modalProps} closeEditModal={closeModal} errorAsString={errorAsString} />
  ));

  const initialForm = createProductForm(stockItem);

  const submitForm = (values: IProductForm) =>
    dispatch(
      new productsActions.UpdateProduct({
        ids: { product: stockItem.product.id, store: stockItem.store.id },
        onSuccess: closeModal,
        values: mapProductItem(values, stockItem.product.price.id),
      }),
    );
  const form = useForm<IProductForm>({ error, initialForm, submitForm, validateForm });

  return (
    <>
      {renderProductDeleteModal()}
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      <Modal className="product-modal" onClose={closeModal} open>
        <header>
          <label>{translations.getLabel('PRODUCTS.EDIT.TITLE')}</label>
          {canDeleteStockItemProduct(stockItem) && (
            <Icon name="SvgTrash" onClick={() => showProductDeleteModal({ stockItem })} size={2.4} />
          )}
          {canActivateStockItemProduct(stockItem) && (
            <Icon
              name="SvgActivate"
              onClick={() =>
                dispatch(
                  new productsActions.ActivateProduct({
                    ids: { product: stockItem.product.id, store: currentStore.id },
                    onSuccess: closeModal,
                  }),
                )
              }
              size={2.4}
            />
          )}
        </header>
        <Modal.Content>
          <ProductForm
            buttons={
              <div className="actions">
                <Button asText onClick={closeModal}>
                  {translations.getLabel('SHARED.BUTTONS.CANCEL')}
                </Button>
                <Button loading={isLoading} onClick={() => form.submit(null)} primary type="button">
                  {translations.getLabel('SHARED.BUTTONS.CREATE')}
                </Button>
              </div>
            }
            form={form}
            isEdit
            isWarehouse={currentStore?.isWarehouse}
            showExtraPrice={hasExtraPricePermission}
            showPurchasePrice={hasPurchasePricePermission}
            showSellingPrice={hasSellingPricePermission}
            showWarehouseStockCount={hasTotalPricePermission}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ProductEditModal;
