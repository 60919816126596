import { Action } from 'redux';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { BestsellerItem, TopTenItem } from '../_models';

export enum ReportsActionType {
  ExportBestsellers = '[Reports] ExportBestsellers',
  ExportBestsellersError = '[Reports] ExportBestsellersError',
  ExportBestsellersSuccess = '[Reports] ExportBestsellersSuccess',
  GetBestSellers = '[Reports] GetBestSellers',
  GetBestSellersError = '[Reports] GetBestSellersError',
  GetBestSellersSuccess = '[Reports] GetBestSellersSuccess',
  GetTopTen = '[Reports] GetTopTen',
  GetTopTenError = '[Reports] GetTopTenError',
  GetTopTenSuccess = '[Reports] GetTopTenSuccess',
  SetReportsQuery = '[Reports] SetReportsQuery',
}

export class ExportBestsellers implements Action<ReportsActionType> {
  readonly type = ReportsActionType.ExportBestsellers;
  constructor(public payload: { query: HttpMetadataQuery; storeId: string }) {}
}

export class ExportBestsellersError implements Action<ReportsActionType> {
  readonly type = ReportsActionType.ExportBestsellersError;
  constructor(public payload: { error: ApiError }) {}
}

export class ExportBestsellersSuccess implements Action<ReportsActionType> {
  readonly type = ReportsActionType.ExportBestsellersSuccess;
  constructor(public payload: { file: Blob; name: string }) {}
}

export class GetTopTen implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetTopTen;
  constructor(public payload: { storeId: string }) {}
}

export class GetTopTenSuccess implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetTopTenSuccess;
  constructor(public payload: { data: TopTenItem[] }) {}
}

export class GetTopTenError implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetTopTenError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetBestSellers implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetBestSellers;
  constructor(public payload: { storeId: string }) {}
}

export class GetBestSellersSuccess implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetBestSellersSuccess;
  constructor(public payload: { data: BestsellerItem[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetBestSellersError implements Action<ReportsActionType> {
  readonly type = ReportsActionType.GetBestSellersError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetReportsQuery implements Action<ReportsActionType> {
  readonly type = ReportsActionType.SetReportsQuery;
  constructor(public payload: { query: HttpMetadataQuery; storeId: string }) {}
}

export type ReportsAction =
  | GetTopTen
  | GetTopTenSuccess
  | GetTopTenError
  | GetBestSellers
  | GetBestSellersSuccess
  | GetBestSellersError
  | SetReportsQuery;
