export type TSale = {
  cashSheetDate?: string;
  clientName?: string;
  highlight?: Highlight;
  id: string;
  orderNumber: number;
  paidBancontact?: number;
  paidBancontactPortable?: number;
  paidBankTransfer?: number;
  paidCash?: number;
  paidCheck?: number;
  paidCreditCard?: number;
  paidCreditCardPortable?: number;
  receiptCode?: string;
  seller?: string;
  totalPrice?: number;
};

export enum Highlight {
  Negative = 'NEGATIVE',
  None = 'NONE',
  Positive = 'POSITIVE',
}
