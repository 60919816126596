import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap, tap } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { pricesActions } from '../../_store/actions';
import { pricesSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { showPricePdf } from '../_utils';
import { PricesActionType } from './actions';
import * as pricesApi from './api';

const getPricesEpic$: Epic = (action$, state$) =>
  action$.ofType(PricesActionType.GetPrices).pipe(
    exhaustMap(({ payload }: pricesActions.GetPrices) => {
      const query = pricesSelectors.query(state$.value);
      return from(pricesApi.getPrices(payload.storeId, query)).pipe(
        map(({ data, meta }) => new pricesActions.GetPricesSuccess({ data, meta })),
        catchError(error => of(new pricesActions.GetPricesError({ error }))),
      );
    }),
  );

const setPricesQueryEpic$: Epic = action$ =>
  action$
    .ofType(PricesActionType.SetPricesQuery)
    .pipe(map(({ payload }): pricesActions.GetPrices => new pricesActions.GetPrices({ storeId: payload.storeId })));

const updatePriceEpic$: Epic = action$ =>
  action$.ofType(PricesActionType.UpdatePrice).pipe(
    exhaustMap(({ payload }: pricesActions.UpdatePrice) =>
      from(pricesApi.updatePrice(payload.ids, payload.values)).pipe(
        tap(() => {
          if (payload.showToaster) return toast.success(translations.getLabel('PRICES.TOASTER.SUCCESS'));
          else return null;
        }),
        map(() => {
          payload.onSuccess?.();
          return new pricesActions.UpdatePriceSuccess({ ids: payload.ids, values: payload.values });
        }),
        catchError(error => of(new pricesActions.UpdatePriceError({ error }))),
      ),
    ),
  );

const printPriceEpic$: Epic = action$ =>
  action$.ofType(PricesActionType.PrintPrice).pipe(
    exhaustMap(({ payload }: pricesActions.PrintPrice) =>
      from(pricesApi.printPrice(payload.ids, payload.values)).pipe(
        map((printPrice: string) => {
          payload.onSuccess?.();
          return new pricesActions.PrintPriceSuccess({ printPrice });
        }),
        catchError(error => of(new pricesActions.PrintPriceError({ error }))),
      ),
    ),
  );

export const printPriceSuccessEpic$: Epic = action$ =>
  action$.ofType(PricesActionType.PrintPriceSuccess).pipe(
    exhaustMap(({ payload }: pricesActions.PrintPriceSuccess) => {
      showPricePdf(payload.printPrice);
      return of();
    }),
  );

export default [getPricesEpic$, setPricesQueryEpic$, updatePriceEpic$, printPriceEpic$, printPriceSuccessEpic$];
