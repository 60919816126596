import React, { FC } from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import './tabmenu.scss';

export type MenuItem = {
  isChecked?: boolean;
  onClick?: () => void;
  path?: string;
  text: string;
};

type Props = {
  menuItems: MenuItem[];
};

const TabMenu: FC<Props> = ({ menuItems }) => {
  return (
    <Menu>
      {menuItems.map(item => {
        if (item.onClick) return <Menu.Item active={item.isChecked} key={item.text} name={item.text} onClick={item.onClick} />;

        return (
          <NavLink activeClassName={'active'} key={item.text} to={item.path}>
            <Menu.Item name={item.text} />
          </NavLink>
        );
      })}
    </Menu>
  );
};

export default TabMenu;
