import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { productsActions } from '../../_store/actions';
import { productsSelectors, storesSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { downloadFile } from '../../_utils/downloadHelper';
import { ProductsActionType } from './actions';
import * as productsApi from './api';

const createProductEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.CreateProduct).pipe(
    switchMap(({ payload }: productsActions.CreateProduct) =>
      from(productsApi.createProduct(payload.ids, payload.values)).pipe(
        tap(() => toast.success(translations.getLabel('PRODUCTS.CREATE.SUCCESS', { productId: payload.values.product.code }))),
        map(() => {
          payload.onSuccess?.();
          return new productsActions.CreateProductSuccess();
        }),
        catchError(error => {
          tap(() => toast.error(translations.getLabel('ERRORS.CREATE.ERROR', { productId: payload.values.product.code })));
          return of(new productsActions.CreateProductError({ error }));
        }),
      ),
    ),
  );

const createProductSuccessEpic$: Epic = (action$, state$) =>
  action$.ofType(ProductsActionType.CreateProductSuccess).pipe(
    exhaustMap(() => {
      const products = productsSelectors.products(state$.value);
      return of(new productsActions.GetProducts({ storeId: products[0].store.id }));
    }),
  );

const getProductsEpic$: Epic = (action$, state$) =>
  action$.ofType(ProductsActionType.GetProducts).pipe(
    switchMap(({ payload }: productsActions.GetProducts) => {
      const query = productsSelectors.query(state$.value);
      return from(productsApi.getProducts(payload.storeId, query)).pipe(
        map(({ data, meta }) => new productsActions.GetProductsSuccess({ data, meta })),
        catchError(error => of(new productsActions.GetProductsError({ error }))),
      );
    }),
  );

const silentGetProductsEpic$: Epic = (action$, state$) =>
  action$.ofType(ProductsActionType.SilentGetProducts).pipe(
    switchMap(() => {
      const query = productsSelectors.query(state$.value);
      const cuurentStore = storesSelectors.currentStore(state$.value);
      return from(productsApi.getProducts(cuurentStore.id, query)).pipe(
        map(({ data, meta }) => new productsActions.SilentGetProductsSuccess({ data, meta })),
        catchError(error => of(new productsActions.SilentGetProductsError({ error }))),
      );
    }),
  );

const deactivateProductEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.DeactivateProduct).pipe(
    exhaustMap(({ payload }: productsActions.DeactivateProduct) =>
      from(productsApi.deactivateProduct(payload.ids)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new productsActions.DeactivateProductSuccess();
        }),
        catchError(error => of(new productsActions.DeactivateProductError({ error }))),
      ),
    ),
  );

const activateProductEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.ActivateProduct).pipe(
    exhaustMap(({ payload }: productsActions.ActivateProduct) =>
      from(productsApi.activateProduct(payload.ids)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new productsActions.ActivateProductSuccess();
        }),
        catchError(error => of(new productsActions.ActivateProductError({ error }))),
      ),
    ),
  );

export const exportProductsEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.ExportProducts).pipe(
    exhaustMap(({ payload }: productsActions.ExportProducts) =>
      from(productsApi.exportProductsTable(payload.ids, payload.state)).pipe(
        map((file: Blob) => {
          const date = new Date();

          return new productsActions.ExportProductsSuccess({
            file,
            name: `${date.getTime()}_products.csv`,
          });
        }),
        catchError(error => of(new productsActions.ExportProductsError({ error }))),
      ),
    ),
  );

export const exportProductsSuccessEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.ExportProductsSuccess).pipe(
    exhaustMap(({ payload }: productsActions.ExportProductsSuccess) => {
      downloadFile(payload.file, payload.name);
      return of();
    }),
  );

const deactivateProductSuccessEpic$: Epic = (action$, state$) =>
  action$.ofType(ProductsActionType.DeactivateProductSuccess, ProductsActionType.ActivateProductSuccess).pipe(
    exhaustMap(() => {
      const products = productsSelectors.products(state$.value);
      return of(new productsActions.GetProducts({ storeId: products[0].store.id }));
    }),
  );

const setProductsQueryEpic$: Epic = action$ =>
  action$
    .ofType(ProductsActionType.SetProductsQuery)
    .pipe(map(({ payload }): productsActions.GetProducts => new productsActions.GetProducts({ storeId: payload.storeId })));

const updateProductEpic$: Epic = action$ =>
  action$.ofType(ProductsActionType.UpdateProduct).pipe(
    exhaustMap(({ payload }: productsActions.UpdateProduct) =>
      from(productsApi.updateProduct(payload.ids, payload.values)).pipe(
        map(({ data }) => {
          payload?.onSuccess?.(data);
          new productsActions.UpdateProductSuccess({ data });
          return new productsActions.GetProducts({ storeId: payload.ids.store });
        }),
        catchError(error => of(new productsActions.UpdateProductError({ error }))),
      ),
    ),
  );

export default [
  createProductEpic$,
  createProductSuccessEpic$,
  activateProductEpic$,
  deactivateProductEpic$,
  deactivateProductSuccessEpic$,
  exportProductsEpic$,
  exportProductsSuccessEpic$,
  getProductsEpic$,
  setProductsQueryEpic$,
  silentGetProductsEpic$,
  updateProductEpic$,
];
