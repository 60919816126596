import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../_shared';
import { translations } from '../../_translations';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../_store/selectors';
import './ledgerToolbar.scss';
import { Highlight } from '../../cashSheets/_models';

const LedgerToolbar: React.FC = () => {
  const activeCell = useSelector(ledgerSelectors.activeCell);
  const currentStore = useSelector(storesSelectors.currentStore);
  const dispatch = useDispatch();

  const updateHighlight = (highlight: Highlight) => {
    const payload = {
      storeId: currentStore.id,
      values: { highlight },
    };
    switch (activeCell.array) {
      case 'decorationRevenue':
        return dispatch(new ledgerActions.UpdateDecorationRevenue({ ...payload, decorationRevenueId: activeCell.id }));
      case 'otherFurniture':
        return dispatch(new ledgerActions.UpdateOtherFurniture({ ...payload, saleId: activeCell.id }));
      default:
        break;
    }
  };

  const getHighlightSvg = (highlight: Highlight, color: Highlight) => {
    return (
      <Icon
        className={`toolbar__tools__highlight__${color.toLowerCase()}`}
        clickable
        key={color}
        name={highlight === color ? 'SvgCircleCheck' : 'SvgCircle'}
        onClick={() => updateHighlight(color)}
      />
    );
  };

  if (!activeCell) return null;
  return (
    <div className="toolbar ">
      <div className="toolbar__tools">
        <div className="toolbar__tools__highlight">
          {[Highlight.None, Highlight.Negative, Highlight.Positive].map(color => getHighlightSvg(activeCell.highlight, color))}
        </div>
      </div>
      <div className="toolbar__cancel">
        <Button
          className="toolbar__tools__edit"
          negative
          onClick={() => {
            dispatch(new ledgerActions.SetActiveCell({ activeCell: undefined }));
          }}
        >
          <span>{translations.getLabel('SHARED.BUTTONS.CANCEL')}</span>
        </Button>
      </div>
    </div>
  );
};

export default LedgerToolbar;
