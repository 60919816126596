import React, { FC, ReactNode } from 'react';
import { ApiError } from '../../_http';
import { IAdminStore } from '../_models';
import useForm, { SubmitFormFunction, FormValidationErrors } from '../../_hooks/useForm';
import { translations } from '../../_translations';
import { formValidator } from '../../_utils/formValidation';
import { ErrorMessage, InputField, Button, ActiveStateCheckbox } from '../../_shared';
import { ItemState } from '../../_utils/stateHelper';
import { errorAsString } from '../../_utils/errorHelper';
import './StoreForm.scss';

type Props = {
  buttons?: ReactNode;
  error?: ApiError;
  initialForm: IAdminStore;
  isSubmitting?: boolean;
  storeId?: string;
  submitForm: SubmitFormFunction<IAdminStore>;
};

const StoreForm: FC<Props> = ({ storeId, initialForm, submitForm, isSubmitting, error, buttons }) => {
  function validateForm(values: IAdminStore): FormValidationErrors<IAdminStore> {
    return {
      code: formValidator.maxLength(values.code, 3).error,
      contactEmail: formValidator.maxLength(values.contactEmail, 50).error,
      contactPerson: formValidator.minLength(values.contactPerson, 1).error,
      description: formValidator.required(values.description).error,
      name: formValidator.required(values.name).error,
      serialNumber: formValidator.number(values.serialNumber.toString()).error,
      state: formValidator.enumValue(values.state, ItemState).error,
    };
  }

  const form = useForm<IAdminStore>({
    error,
    initialForm,
    submitForm,
    validateForm,
  });
  const errorMessage = errorAsString(error);

  return (
    <form onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      <InputField
        errorMessage={form.validationErrors.code}
        label={translations.getLabel('MANAGEMENT.STORES.CODE')}
        name="code"
        onChange={form.setAttribute}
        type="text"
        value={form.values.code}
      />

      <InputField
        errorMessage={form.validationErrors.name}
        label={translations.getLabel('MANAGEMENT.STORES.NAME')}
        name="name"
        onChange={form.setAttribute}
        type="text"
        value={form.values.name}
      />

      <InputField
        errorMessage={form.validationErrors.description}
        label={translations.getLabel('MANAGEMENT.STORES.DESCRIPTION')}
        name="description"
        onChange={form.setAttribute}
        type="text"
        value={form.values.description}
      />

      <InputField
        errorMessage={form.validationErrors.contactPerson}
        label={translations.getLabel('MANAGEMENT.STORES.CONTACT_PERSON')}
        name="contactPerson"
        onChange={form.setAttribute}
        type="text"
        value={form.values.contactPerson}
      />

      <InputField
        errorMessage={form.validationErrors.contactEmail}
        label={translations.getLabel('MANAGEMENT.STORES.CONTACT_EMAIL')}
        name="contactEmail"
        onChange={form.setAttribute}
        type="text"
        value={form.values.contactEmail}
      />

      <div className="group" role="group">
        <InputField
          errorMessage={form.validationErrors.serialNumber}
          label={translations.getLabel('MANAGEMENT.STORES.SERIAL_NUMBER')}
          name="serialNumber"
          onChange={(value, name) => form.setAttribute(parseInt(value), name)}
          type="number"
          value={form.values.serialNumber.toString()}
        />

        <ActiveStateCheckbox name="state" onChange={form.setAttribute} state={form.values.state} />
      </div>

      <InputField
        label={translations.getLabel('MANAGEMENT.STORES.NEW_STORE.COLOR')}
        name="color"
        onChange={form.setAttribute}
        type="color"
        value={form.values.color}
      />

      <div className="actions">
        {buttons}
        <Button className="newStoreButton" loading={isSubmitting} primary type="submit">
          {translations.getLabel(storeId ? 'SHARED.BUTTONS.SAVE' : 'SHARED.BUTTONS.CREATE')}
        </Button>
      </div>
    </form>
  );
};

export default StoreForm;
