import { TCashSheetQuery, TCashSheet, TSearchCashSheetQuery, TCashSheetSearchResults } from '../_models';
import { HttpClient } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { TCashSheetForm } from '../_forms/CashSheetForm';
import { TSaleForm } from '../_forms/SaleForm';
import { TDeliveryForm } from '../_forms/DeliveryForm';
import { TDecorationForm } from '../_forms/DecorationForm';

export function getCashSheetDetail(storeId: string, query?: TCashSheetQuery): Promise<TCashSheet> {
  return HttpClient.get<TCashSheet>(`cash-sheets${getQueryParams(query)}`, null, { 'store-id': storeId });
}

function parseSearch(search: string): string {
  if (isNaN((search as unknown) as number)) {
    return search;
  } else if (parseFloat(search)) {
    return `${parseFloat(search.replace(',', '.')) * 100}`;
  } else {
    return '';
  }
}

export function getCashSheetDetailSearch(storeId: string, query: TSearchCashSheetQuery) {
  const newQuery: TSearchCashSheetQuery = {
    ...query,
    search: parseSearch(query.search),
  };

  return HttpClient.get<TCashSheetSearchResults>(`cash-sheets/search${getQueryParams(newQuery)}`, null, { 'store-id': storeId });
}

export function deleteSale(cashSheetId: string, storeId: string, saleId: string): Promise<void> {
  return HttpClient.delete(`cash-sheets/${cashSheetId}/sales/${saleId}`, { 'store-id': storeId });
}

export function deleteDelivery(cashSheetId: string, storeId: string, deliveryId: string): Promise<void> {
  return HttpClient.delete(`cash-sheets/${cashSheetId}/deliveries/${deliveryId}`, { 'store-id': storeId });
}

export function updateCashSheet(cashSheetId: string, storeId: string, body: TCashSheetForm): Promise<void> {
  return HttpClient.put(`cash-sheets/${cashSheetId}`, body, { 'store-id': storeId });
}

export function updateSale(cashSheetId: string, storeId: string, saleId: string, body: TSaleForm): Promise<void> {
  return HttpClient.put(`cash-sheets/${cashSheetId}/sales/${saleId}`, body, { 'store-id': storeId });
}

export function updateDelivery(cashSheetId: string, storeId: string, deliveryId: string, body: TDeliveryForm): Promise<void> {
  return HttpClient.put(`cash-sheets/${cashSheetId}/deliveries/${deliveryId}`, body, { 'store-id': storeId });
}

export function updateDecorationRevenue(
  cashSheetId: string,
  storeId: string,
  decorationRevenueId: string,
  body: TDecorationForm,
): Promise<void> {
  return HttpClient.put(`cash-sheets/${cashSheetId}/decoration-revenue/${decorationRevenueId}`, body, {
    'store-id': storeId,
  });
}

export function createCashSheet(storeId: string, body: TCashSheetForm): Promise<TCashSheet> {
  return HttpClient.post<TCashSheet>(`cash-sheets`, body, { 'store-id': storeId });
}

export function createSale(cashSheetId: string, storeId: string, body: TSaleForm): Promise<void> {
  return HttpClient.post(`cash-sheets/${cashSheetId}/sales`, body, { 'store-id': storeId });
}
export function createDelivery(cashSheetId: string, storeId: string, body: TDeliveryForm): Promise<void> {
  return HttpClient.post(`cash-sheets/${cashSheetId}/deliveries`, body, { 'store-id': storeId });
}

export function createDecorationRevenue(cashSheetId: string, storeId: string, body: TDecorationForm): Promise<void> {
  return HttpClient.post(`cash-sheets/${cashSheetId}/decoration-revenue`, body, { 'store-id': storeId });
}
