import { HttpMetadataQuery, HttpPagedResponse, HttpClient } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { StockItem, Ids } from '../../stock/_models';
import { PricesRequest, PricesPrintRequest } from '../_models';

export function getPrices(storeId: string, query?: HttpMetadataQuery): Promise<HttpPagedResponse<StockItem>> {
  return HttpClient.get<HttpPagedResponse<StockItem>>(`stock-prices${getQueryParams(query)}`, null, { 'store-id': storeId });
}

export function updatePrice(ids: Ids, body: PricesRequest): Promise<void> {
  return HttpClient.put<void>(
    `stock-prices/${ids.price}`,
    { description: body.description || '', title: body.title || '' },
    { 'store-id': ids.store },
  );
}

export function printPrice(ids: Ids, values: PricesPrintRequest): Promise<string> {
  return HttpClient.post<string>(`stock-prices/print`, values, { 'store-id': ids.store }, 'blob');
}
