import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import Table, { TableColumn } from '../../_shared/table/Table';
import { FillMetadataQueryFunction, HttpSortDirection, ApiError } from '../../_http';
import { translations } from '../../_translations';
import { useTableSort, useInfiniteScroll, useModal } from '../../_hooks';
import { codesSelectors, productsSelectors, profileSelectors, storesSelectors } from '../../_store/selectors';
import { productsActions } from '../../_store/actions';
import { StockItem } from '../../stock/_models';
import ProductEditModal from '../edit/ProductEditModal';
import { hasEditPermissions, hasViewPermissions } from '../../profile/_utils';
import { Code } from '../../codes/_models';

import ProductRow from './ProductRow';
import './productstable.scss';

const RELOAD_INTERVAL = 50000;

type Props = {
  data?: StockItem[];
  errorAsString: (error?: ApiError) => string;
  isLoading: boolean;
  onSaveSuccess: () => void;
  readOnly: boolean;
  setQuery: FillMetadataQueryFunction;
};

const columns: TableColumn[] = [
  { className: 'icon', hideWhenFiltering: true, label: '', name: 'edit', sortable: false },
  { collapsing: false, label: 'PRODUCTS.TABLE.CODE', name: 'product.code', sortable: true },
  { label: 'PRODUCTS.TABLE.DESCRIPTION', name: 'product.description', sortable: true },
  { label: 'PRODUCTS.TABLE.DIMENSIONS', name: 'product.dimensions', sortable: true },
  { label: 'PRODUCTS.TABLE.REMARK', name: 'product.remark', sortable: true },
  { label: 'PRODUCTS.TABLE.ZONE', name: 'stock-item.zone', sortable: true },
  { collapsing: true, label: 'PRODUCTS.TABLE.RESERVED', name: 'product.reserved', sortable: true },
  { collapsing: true, label: 'PRODUCTS.TABLE.EXPECTED', name: 'stock-item.expected', sortable: true },
  { hideWhenFiltering: true, label: 'PRODUCTS.TABLE.STOCK', name: 'stock-item.value', sortable: true, textAlign: 'center' },
  { label: 'PRODUCTS.TABLE.PURCHASE_PRICE', name: 'price.purchasePrice', sortable: true, textAlign: 'center' },
  { label: 'PRODUCTS.TABLE.SELLING_PRICE', name: 'price.sellingPrice', sortable: true, textAlign: 'center' },
  { hideWhenFiltering: true, label: 'PRODUCTS.TABLE.PURCHASE_TOTAL', name: 'price.total', sortable: true, textAlign: 'center' },
  { label: 'PRODUCTS.TABLE.EXTRA_PRICE', name: 'price.extraPrice', sortable: true, textAlign: 'center' },
  { label: 'PRODUCTS.TABLE.WAREHOUSE_STOCK_COUNT', name: 'product.warehouseStockCount' },
  { label: 'PRODUCTS.TABLE.IS_CHAIR', name: 'product.isChair' },
];

const ProductsTable: FC<Props> = ({ data, errorAsString, isLoading, setQuery, readOnly, onSaveSuccess }) => {
  const metadata = useSelector(productsSelectors.metadata);
  const profile = useSelector(profileSelectors.profile);
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const codes = useSelector(codesSelectors.codes);
  const timer = useRef<NodeJS.Timeout>(null);

  const hasEditPermission = hasEditPermissions(codes, Code.Product, currentStore?.id, profile.permissions);
  const hasPurchasePricePermission = hasViewPermissions(codes, Code.PurchasePrice, currentStore?.id, profile.permissions);
  const hasSellingPricePermission = hasViewPermissions(codes, Code.SellPrice, currentStore?.id, profile.permissions);
  const hasExtraPricePermission = hasViewPermissions(codes, Code.ExtraPrice, currentStore?.id, profile.permissions);
  const hasTotalPricePermission = hasViewPermissions(codes, Code.TotalPrice, currentStore?.id, profile.permissions);
  const hasLedgerPermission = hasViewPermissions(codes, Code.Ledger, currentStore?.id, profile.permissions);

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );

  const { fetchMoreData } = useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  // Manually perform the fetchMoreData trigger on switch to inpufields
  useEffect(() => {
    if (!readOnly) {
      fetchMoreData();
    } else {
      // if readonly, we will trigger a refresh of the data every X minutes
      timer.current = setInterval(() => {
        dispatch(new productsActions.SilentGetProducts());
      }, RELOAD_INTERVAL);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [readOnly]);

  const closeError = () => dispatch(new productsActions.UpdateProductError({ error: null }));
  const [renderProductEditModal, showProductEditModal] = useModal<{ stockItem: StockItem }>(
    modalProps => <ProductEditModal {...modalProps} errorAsString={errorAsString} />,
    null,
    closeError,
  );

  const renderRow = (stockItem: StockItem, index: number) => {
    const className = classnames({ 'greyed-out': index % 2 });

    return (
      <ProductRow
        className={className}
        hasEditPermission={hasEditPermission}
        hasExtraPricePermission={hasExtraPricePermission}
        hasLedgerPermission={hasLedgerPermission}
        hasPurchasePricePermission={hasPurchasePricePermission}
        hasSellingPricePermission={hasSellingPricePermission}
        hasTotalPricePermission={hasTotalPricePermission}
        id={stockItem.id}
        isWarehouse={currentStore.isWarehouse}
        key={stockItem.id}
        onEdit={showProductEditModal}
        onSaveSuccess={onSaveSuccess}
        readOnly={readOnly}
      />
    );
  };

  const filteredColumns = columns.filter(col => {
    if ((!hasEditPermission || !currentStore.isWarehouse) && col.name === 'edit') return false;
    if (!currentStore.isWarehouse && col.name === 'stock-item.zone') return false;
    if (!currentStore.isWarehouse && col.name === 'stock-item.expected') return false;
    if (!hasPurchasePricePermission && col.name === 'price.purchasePrice') return false;
    if (!hasSellingPricePermission && col.name === 'price.sellingPrice') return false;
    if (!hasTotalPricePermission && col.name === 'price.total') return false;
    if (!hasExtraPricePermission && col.name === 'price.extraPrice') return false;
    if ((!currentStore.isWarehouse || !hasTotalPricePermission) && col.name === 'product.warehouseStockCount') return false;
    // if it's not readonly (not in multiple edit state) we only show the cols that can be edited, indicated by the hideWhenFiltering flag
    return readOnly || !col.hideWhenFiltering;
  });

  return (
    <>
      <Table
        columns={filteredColumns}
        data={data}
        emptyLabel={translations.getLabel('PRODUCTS.OVERVIEW.EMPTY')}
        fixed={false}
        isLoading={isLoading}
        renderRow={renderRow}
        sortFunctions={sortFunctions}
      />
      {hasEditPermission && renderProductEditModal()}
    </>
  );
};

export default ProductsTable;
