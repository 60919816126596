import React, { FC, useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { stockActions } from '../../_store/actions';
import { stockSelectors } from '../../_store/selectors';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import { translations } from '../../_translations';
import { TabMenu, Icon, SearchField, InputFieldDate, Button } from '../../_shared';
import { ItemState } from '../../_utils/stateHelper';
import { useToggle } from '../../_hooks';
import StockItemsTableEdit from '../edit/StockItemsTableEdit';
import StockChangeTable from './table/StockChangeTable';
import './stockoverview.scss';

type Props = {
  storeId: string;
};

const StockOverview: FC<Props> = ({ storeId }) => {
  const [isSaved, setIsSaved] = useToggle(false);
  const [selectedDate, setDate] = useState<string>();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const isLoading = useSelector(stockSelectors.isGetStockItemsLoading);
  const query = useSelector(stockSelectors.stockChangeQuery);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isEditable = pathname.includes('stock-item');
  const dateFormat = 'dd/MM/yyyy';

  const menuItems = [
    {
      path: `${url}/stock-item`,
      text: translations.getLabel('STOCKITEMS.OVERVIEW.CHANGE_STOCK'),
    },
    {
      path: `${url}/stock-change`,
      text: translations.getLabel('STOCKITEMS.OVERVIEW.SHOW_STOCK'),
    },
  ];

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new stockActions.SetStockChangeQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useEffect(() => {
    dispatch(new stockActions.GetStockItems({ state: ItemState.Active, storeId, take: 1000 }));
    setQuery({});
  }, [storeId]);

  function saveChanges(): void {
    clearTimeout(timer);
    setIsSaved(true);
    setTimer(setTimeout(() => setIsSaved(false), 3000));
  }

  function queryOnDate(date: string): void {
    setDate(date);
    setQuery({ dateFilter: date });
  }

  function removeFilters(): void {
    setDate('');
    setQuery({ dateFilter: '', search: '' });
  }

  return (
    <Container as="main" className="stock">
      <div className="header">
        <div className="title-wrapper">
          <h4 className="overview-title">{translations.getLabel('STOCKITEMS.TABLE.STOCK')}</h4>
          {isEditable && (
            <span className={classnames('changes-saved-wrapper', { active: isSaved })}>
              <Icon name="SvgCheck" size={1.8} />
              <p>{translations.getLabel('STOCKITEMS.OVERVIEW.CHANGES_SAVED')}</p>
            </span>
          )}
        </div>
        <TabMenu menuItems={menuItems} />
      </div>
      {!isEditable && (
        <div className="stockoverview-filter-wrapper">
          <SearchField placeholder={translations.getLabel('STOCKITEMS.OVERVIEW.SEARCH')} query={query} setQuery={setQuery} />
          <InputFieldDate
            dateFormat={dateFormat}
            name="date"
            onChange={(date: string) => queryOnDate(date)}
            value={selectedDate}
          />
          <Button disabled={!query || (query.search === '' && query.dateFilter === '')} negative onClick={removeFilters}>
            {translations.getLabel('SHARED.BUTTONS.REMOVE_FILTER')}
          </Button>
        </div>
      )}
      <Switch>
        <Route
          exact
          path={`${url}/stock-item`}
          render={() => <StockItemsTableEdit isLoading={isLoading} saveChanges={saveChanges} storeId={storeId} />}
        />
        <Route
          path={`${url}/stock-change`}
          render={() => <StockChangeTable isLoading={isLoading} setQuery={setQuery} storeId={storeId} />}
        />
      </Switch>
    </Container>
  );
};

export default StockOverview;
