import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Table, { TableColumn } from '../../../_shared/table/Table';
import { FillMetadataQueryFunction, HttpSortDirection } from '../../../_http';
import { translations } from '../../../_translations';
import { useTableSort, useInfiniteScroll, useModal } from '../../../_hooks';
import { codesSelectors, pricesSelectors, profileSelectors, storesSelectors } from '../../../_store/selectors';
import { StockItem } from '../../../stock/_models';
import PricesEditModal from '../../edit/PricesEditModal';
import { errorAsString } from '../../../_utils/errorHelper';
import PriceRow from '../row/PriceRow';
import './pricestable.scss';
import { hasEditPermissions } from '../../../profile/_utils';
import { Code } from '../../../codes/_models';

type Props = {
  data?: StockItem[];
  isChecked: boolean;
  isLoading: boolean;
  onSelectPrice: (id: string) => void;
  printMultiple: boolean;
  saveChanges: () => void;
  selectedPrices: string[];
  setQuery: FillMetadataQueryFunction;
  showPrintPrice: () => void;
};

const columns: TableColumn[] = [
  { className: 'icon print', label: '', name: 'print', sortable: false },
  { className: 'icon', label: '', name: 'edit', sortable: false },
  { label: 'PRICES.OVERVIEW.TABLE.CODE', name: 'product.code', sortable: true },
  { label: 'PRICES.OVERVIEW.TABLE.TITLE', name: 'price.title', sortable: true },
  { label: 'PRICES.OVERVIEW.TABLE.DESCRIPTION', name: 'price.description', sortable: true },
  { label: 'PRICES.OVERVIEW.TABLE.GROSS_PRICE', name: 'price.grossPrice', sortable: true, textAlign: 'center' },
  { label: 'PRICES.OVERVIEW.TABLE.NETTO_PRICE', name: ' price-sellingPrice', sortable: true, textAlign: 'center' },
];

const PricesTable: FC<Props> = ({
  data,
  isChecked,
  isLoading,
  printMultiple,
  saveChanges,
  setQuery,
  selectedPrices,
  onSelectPrice,
  showPrintPrice,
}) => {
  const metadata = useSelector(pricesSelectors.metadata);
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);
  const hasEditPermission = hasEditPermissions(codes, Code.Price, currentStore?.id, profile.permissions);

  const [renderPricesEditModal, showPricesEditModal] = useModal<{ stockItem: StockItem }>(modalProps => (
    <PricesEditModal {...modalProps} errorAsString={errorAsString} />
  ));

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );
  useInfiniteScroll((skip: number) => setQuery({ skip, take: isChecked ? 1000 : 200 }), metadata, isLoading);

  function renderRow(stockItem: StockItem, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });

    return (
      <PriceRow
        className={className}
        isChecked={isChecked}
        isSelected={selectedPrices.includes(stockItem.id)}
        isWarehouse={currentStore.isWarehouse}
        key={stockItem.id}
        onSelect={printMultiple ? onSelectPrice : null}
        readOnly={!hasEditPermission}
        saveChanges={saveChanges}
        showPricesEditModal={showPricesEditModal}
        showPrintPrice={showPrintPrice}
        stockItem={stockItem}
      />
    );
  }

  return (
    <>
      {hasEditPermission && renderPricesEditModal()}
      <Table
        columns={hasEditPermission && currentStore.isWarehouse ? columns : columns.slice(1, columns.length)}
        data={data}
        emptyLabel={translations.getLabel('PRICES.OVERVIEW.EMPTY')}
        fixed={false}
        isLoading={isLoading}
        renderRow={renderRow}
        sortFunctions={sortFunctions}
      />
    </>
  );
};

export default PricesTable;
