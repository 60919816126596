import { ICode, ICodeForm } from '../_models';
import { HttpClient, HttpPagedResponse, HttpMetadataQuery } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { removeEmptyKeys } from '../../_utils/objectHelpers';

export function getCodeDetail(codeId: string): Promise<ICode> {
  return HttpClient.get<ICode>(`roles/${codeId}`);
}

export function getCodes(query?: HttpMetadataQuery): Promise<HttpPagedResponse<ICode>> {
  return HttpClient.get<HttpPagedResponse<ICode>>(`roles${getQueryParams(query)}`);
}

export function updateCode(codeId: string, body: ICodeForm): Promise<ICode> {
  return HttpClient.put<ICode>(`roles/${codeId}`, removeEmptyKeys(body));
}

export function deleteCode(codeId: string): Promise<void> {
  return HttpClient.delete(`roles/${codeId}`);
}
