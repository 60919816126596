import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap, switchMap, delay, filter } from 'rxjs/operators';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors } from '../../_store/selectors';
import { LedgerActionType } from './actions';
import * as ledgerApi from './api';

const getLedgerRevenuePerSeller$: Epic = (action$, state$) =>
  action$.ofType(LedgerActionType.GetLedgerRevenuePerSeller).pipe(
    exhaustMap(({ payload }: ledgerActions.GetLedgerRevenuePerSeller) => {
      const month = ledgerSelectors.month(state$.value);
      return from(ledgerApi.getRevenuePerSeller(payload.storeId, month)).pipe(
        map(data => new ledgerActions.GetLedgerRevenuePerSellerSuccess({ data })),
        catchError(error => of(new ledgerActions.GetLedgerRevenuePerSellerError({ error }))),
      );
    }),
  );

//check if the cell we want to set undefined is still active so no more recent selected cell gets set undefined
const clearActiveCellEpic$: Epic = (action$, state$) =>
  action$.ofType(LedgerActionType.ClearActiveCell).pipe(
    delay(500),
    filter(({ payload }: ledgerActions.ClearActiveCell) => {
      const currenctActiveCell = ledgerSelectors.activeCell(state$.value);
      if (!currenctActiveCell) return false;
      if (payload.activeCell.array !== currenctActiveCell.array) return false;
      if (payload.activeCell.index !== currenctActiveCell.index) return false;
      if (payload.activeCell.name !== currenctActiveCell.name) return false;
      return true;
    }),
    map(() => new ledgerActions.SetActiveCell({ activeCell: undefined })),
  );

const getLedgerBankTransfers$: Epic = (action$, state$) =>
  action$.ofType(LedgerActionType.GetLedgerBankTransfers).pipe(
    exhaustMap(({ payload }: ledgerActions.GetLedgerBankTransfers) => {
      const year = ledgerSelectors.year(state$.value);
      return from(ledgerApi.getBankTransfers(payload.storeId, year)).pipe(
        map(data => new ledgerActions.GetLedgerBankTransfersSuccess({ data })),
        catchError(error => of(new ledgerActions.GetLedgerBankTransfersError({ error }))),
      );
    }),
  );

const updateDecorationRevenueEpic$: Epic = action$ =>
  action$.pipe(
    ofType(LedgerActionType.UpdateDecorationRevenue),
    switchMap(({ payload }: ledgerActions.UpdateDecorationRevenue) => {
      return from(ledgerApi.updateDecorationRevenue(payload.decorationRevenueId, payload.storeId, payload.values)).pipe(
        map(() => new ledgerActions.UpdateDecorationRevenueSuccess({ storeId: payload.storeId })),
        catchError(error => of(new ledgerActions.UpdateDecorationRevenueError({ error }))),
      );
    }),
  );

const refreshDecorationRevenueEpic$: Epic = action$ =>
  action$.pipe(
    ofType(LedgerActionType.UpdateDecorationRevenueSuccess),
    map(
      ({ payload }: { payload: { storeId: string } }) =>
        new ledgerActions.GetLedgerDecorationRevenue({ storeId: payload.storeId }),
    ),
  );

const updateOtherFurnitureEpic$: Epic = action$ =>
  action$.pipe(
    ofType(LedgerActionType.UpdateOtherFurniture),
    switchMap(({ payload }: ledgerActions.UpdateOtherFurniture) => {
      return from(ledgerApi.updateOtherFurniture(payload.saleId, payload.storeId, payload.values)).pipe(
        map(() => new ledgerActions.UpdateOtherFurnitureSuccess({ storeId: payload.storeId })),
        catchError(error => of(new ledgerActions.UpdateOtherFurnitureError({ error }))),
      );
    }),
  );

const refreshOtherFurnitureEpic$: Epic = action$ =>
  action$.pipe(
    ofType(LedgerActionType.UpdateOtherFurnitureSuccess),
    map(
      ({ payload }: { payload: { storeId: string } }) => new ledgerActions.GetLedgerOtherFurniture({ storeId: payload.storeId }),
    ),
  );

const getLedgerDecorationRevenue$: Epic = (action$, state$) =>
  action$.ofType(LedgerActionType.GetLedgerDecorationRevenue).pipe(
    exhaustMap(({ payload }: ledgerActions.GetLedgerDecorationRevenue) => {
      const query = ledgerSelectors.decorationRevenueQuery(state$.value);
      return from(ledgerApi.getDecorationRevenue(payload.storeId, query)).pipe(
        map(data => new ledgerActions.GetLedgerDecorationRevenueSuccess({ data })),
        catchError(error => of(new ledgerActions.GetLedgerDecorationRevenueError({ error }))),
      );
    }),
  );

const getLedgerOtherFurniture$: Epic = (action$, state$) =>
  action$.ofType(LedgerActionType.GetLedgerOtherFurniture).pipe(
    exhaustMap(({ payload }: ledgerActions.GetLedgerOtherFurniture) => {
      const query = ledgerSelectors.otherFurnitureQuery(state$.value);
      return from(ledgerApi.getOtherFurniture(payload.storeId, query)).pipe(
        map(data => new ledgerActions.GetLedgerOtherFurnitureSuccess({ data })),
        catchError(error => of(new ledgerActions.GetLedgerOtherFurnitureError({ error }))),
      );
    }),
  );

const setLedgerMonthEpic$: Epic = action$ =>
  action$
    .ofType(LedgerActionType.SetLedgerMonth)
    .pipe(
      map(
        ({ payload }: ledgerActions.GetLedgerRevenuePerSeller) =>
          new ledgerActions.GetLedgerRevenuePerSeller({ storeId: payload.storeId }),
      ),
    );

const setLedgerYearEpic$: Epic = action$ =>
  action$
    .ofType(LedgerActionType.SetLedgerYear)
    .pipe(
      map(
        ({ payload }: ledgerActions.GetLedgerRevenuePerSeller) =>
          new ledgerActions.GetLedgerBankTransfers({ storeId: payload.storeId }),
      ),
    );

const setDecorationRevenueQueryEpic$: Epic = action$ =>
  action$
    .ofType(LedgerActionType.SetDecorationRevenueQuery)
    .pipe(
      map(
        ({ payload }: ledgerActions.GetLedgerDecorationRevenue) =>
          new ledgerActions.GetLedgerDecorationRevenue({ storeId: payload.storeId }),
      ),
    );

const setOtherFurnitureQueryEpic$: Epic = action$ =>
  action$
    .ofType(LedgerActionType.SetOtherFurnitureQuery)
    .pipe(
      map(
        ({ payload }: ledgerActions.GetLedgerOtherFurniture) =>
          new ledgerActions.GetLedgerOtherFurniture({ storeId: payload.storeId }),
      ),
    );

export default [
  getLedgerRevenuePerSeller$,
  setLedgerMonthEpic$,
  setLedgerYearEpic$,
  getLedgerBankTransfers$,
  setDecorationRevenueQueryEpic$,
  getLedgerDecorationRevenue$,
  updateDecorationRevenueEpic$,
  clearActiveCellEpic$,
  getLedgerOtherFurniture$,
  setOtherFurnitureQueryEpic$,
  updateOtherFurnitureEpic$,
  refreshDecorationRevenueEpic$,
  refreshOtherFurnitureEpic$,
];
