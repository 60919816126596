import { Highlight } from '.';

export type TDelivery = {
  cashSheetDate?: string;
  clientName?: string;
  highlight?: Highlight;
  id: string;
  orderNumber: number;
  paidBancontact?: number;
  paidBancontactPortable?: number;
  paidBankTransfer?: number;
  paidCash?: number;
  paidCheck?: number;
  paidCreditCard?: number;
  paidCreditCardPortable?: number;
  receiptCode?: string;
  type?: DeliveryType;
};

export enum DeliveryType {
  PartialPickup = 'PARTIAL_PICKUP',
  PartialTransport = 'PARTIAL_TRANSPORT',
  Pickup = 'PICKUP',
  Transport = 'TRANSPORT',
}
