import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, InputFieldDate } from '../../_shared';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { dateFromString, formatDate } from '../../_utils/timeHelpers';
import LedgerEmpty from '../ledgerEmpty/LedgerEmpty';
import LedgerMenu from '../ledgerMenu/LedgerMenu';
import RevenuePerSellerBottomBar from './bottomBar/RevenuePerSellerBottomBar';
import './ledgerRevenue.scss';
import RevenuePerSeller from './revenuePerSeller/RevenuePerSeller';

type TProps = {
  storeId: string;
};

const LedgerRevenue: React.FC<TProps> = ({ storeId }) => {
  const ledgerRevenue = useSelector(ledgerSelectors.revenuePerSeller);
  const month = useSelector(ledgerSelectors.month);
  const isLoading = useSelector(ledgerSelectors.isRevenuePerSellerLoading);
  const dispatch = useDispatch();
  const dateFormat = 'yyyy-MM';

  const setMonth = (month: string) => {
    dispatch(new ledgerActions.SetLedgerMonth({ month, storeId }));
  };

  useEffect(() => {
    dispatch(new ledgerActions.GetLedgerRevenuePerSeller({ storeId }));
  }, []);

  function renderContent() {
    if (ledgerRevenue?.revenues.length === 0) {
      return <LedgerEmpty />;
    }
    return (
      <>
        {ledgerRevenue?.revenues.map((revenue, key) => {
          return <RevenuePerSeller isLoading={isLoading} key={key} revenue={revenue} />;
        })}
        <RevenuePerSellerBottomBar revenue={ledgerRevenue} />
      </>
    );
  }

  return (
    <main className="ledger-revenue">
      <div className="ledger-revenue__header">
        <LedgerMenu />
        <div className="ledger-revenue__header__controls">
          <InputFieldDate
            dateFormat={dateFormat}
            name="month"
            onChange={(date: string) => setMonth(formatDate(dateFromString(date, dateFormat), dateFormat))}
            showMonthYearPicker
            value={month ? dateFromString(month, 'yyyy-MM').toLocaleString('default', { month: 'long', year: 'numeric' }) : month}
          />
          <Button
            onClick={() => {
              window.print();
            }}
            primary
          >
            <Icon name="SvgPrint" size={2} />
            {translations.getLabel('CASH_SHEETS.OVERVIEW.PRINT_PAGE')}
          </Button>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default LedgerRevenue;
