import { TBankTransfers, TDecorationForm, TRevenuePerSeller } from '../_models';
import { HttpClient } from '../../_http';
import { TDecorationRevenueQuery, TDecorationRevenues } from '../_models/Decoration';
import { getQueryParams } from '../../_utils/queryHelpers';
import { TOtherFurniture, TOtherFurnitureQuery } from '../_models/OtherFurniture';
import { TSaleForm } from '../../cashSheets/_forms/SaleForm';

export function getRevenuePerSeller(storeId: string, month?: string): Promise<TRevenuePerSeller> {
  return HttpClient.get<TRevenuePerSeller>(`ledger/revenue-by-sellers?month=${month}`, null, {
    'store-id': storeId,
  });
}

export function getBankTransfers(storeId: string, year?: string): Promise<TBankTransfers> {
  return HttpClient.get<TBankTransfers>(`ledger/bank-transfers?year=${year}`, null, {
    'store-id': storeId,
  });
}

export function getDecorationRevenue(storeId: string, query?: TDecorationRevenueQuery): Promise<TDecorationRevenues> {
  return HttpClient.get<TDecorationRevenues>(`ledger/decoration-revenue${getQueryParams(query)}`, null, {
    'store-id': storeId,
  });
}

export function getOtherFurniture(storeId: string, query?: TOtherFurnitureQuery): Promise<TOtherFurniture> {
  return HttpClient.get<TOtherFurniture>(
    `ledger/sales${getQueryParams({ ...query, year: query.isOwedAmountZeroExcluded ? null : query.year })}`,
    null,
    {
      'store-id': storeId,
    },
  );
}

export function updateDecorationRevenue(decorationRevenueId: string, storeId: string, body: TDecorationForm): Promise<void> {
  return HttpClient.put(`ledger/decoration-revenue/${decorationRevenueId}`, body, { 'store-id': storeId });
}

export function updateOtherFurniture(saleId: string, storeId: string, body: TSaleForm): Promise<void> {
  return HttpClient.put(`ledger/sale/${saleId}`, body, { 'store-id': storeId });
}
