import React, { useState } from 'react';
import classnames from 'classnames';
import { Icon } from '../../../_shared';
import CustomTable, { TTableColumn } from '../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../_translations';
import { parseCurrency } from '../../../_utils/parseHelpers';
import { TBankTransfer, TMonthlyBankTransfer } from '../../_models';
import { dateFromString } from '../../../_utils/timeHelpers';
import { TBankTransferForm } from '../../_models/BankTransferForm';
import './bankTransfersPerMonth.scss';
import { Code } from '../../../codes/_models';
import { usePermission } from '../../../_hooks';
import BankTransferForm from './BankTransferForm';

export const bankTransferColumns: TTableColumn[] = [
  {
    className: 'smallest-column centered-text',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.ID'),
    name: 'id',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.DATE'),
    name: 'date',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.AMOUNT'),
    name: 'totalAmountToBank',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.NAME'),
    name: 'closedBy',
  },
  {
    className: 'small-column',
    label: translations.getLabel('LEDGER.TABLE.BANK_STATEMENT'),
    name: 'bankStatement',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.BANK_CHECK'),
    name: 'bankCheck',
  },
  {
    className: 'large-column',
    label: translations.getLabel('LEDGER.TABLE.COMMENT'),
    name: 'bankNote',
  },
];

type TProps = {
  isLoading?: boolean;
  monthlyBankTransfers?: TMonthlyBankTransfer;
};

const BankTransfersPerMonth: React.FC<TProps> = ({ monthlyBankTransfers, isLoading }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const permission = usePermission(Code.Ledger);

  function renderRow(bankTransfer: TBankTransfer, index: number): JSX.Element {
    const initialForm: TBankTransferForm = {
      bankCheck: bankTransfer.bankCheck || 0,
      bankNote: bankTransfer.bankNote || '',
      bankStatement: bankTransfer.bankStatement || '',
    };

    return <BankTransferForm bankTransfer={bankTransfer} index={index} initialForm={initialForm} key={bankTransfer.date} />;
  }
  function showRightChevron() {
    return <Icon clickable name={isExpanded ? 'SvgChevronDown' : 'SvgChevronUp'} onClick={() => setIsExpanded(!isExpanded)} />;
  }

  return (
    <main className="bank-transfers print-page">
      <div className="bank-transfers__summary">
        <div className="bank-transfers__summary__month">
          <h4 className="bank-transfers__summary__month__name">
            {dateFromString(monthlyBankTransfers.month, 'yyyy-MM').toLocaleString('default', { month: 'long' })}
          </h4>
          {showRightChevron()}
        </div>
        <div className="bank-transfers__summary__total-amount">
          <h4 className="bank-transfers__summary__total-amount__text">{translations.getLabel('LEDGER.GENERAL.TOTAL')}:</h4>
          <h4 className="bank-transfers__summary__total-amount__number">
            {parseCurrency(monthlyBankTransfers.totalAmountToBank)}
          </h4>
        </div>
      </div>
      <div className={classnames('bank-transfers__table', { 'is-expanded': isExpanded })}>
        <CustomTable
          columns={bankTransferColumns}
          data={monthlyBankTransfers.transfers}
          isLoading={isLoading}
          readonly={!permission.edit}
          renderRow={renderRow}
        />
      </div>
    </main>
  );
};

export default BankTransfersPerMonth;
