import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../_hooks';
import InputFieldCurrency from '../../../../_shared/custom/inputFieldCurrency/InputFieldCurrency';
import InputFieldText from '../../../../_shared/custom/inputFieldText/InputFieldText';
import Spinner from '../../../../_shared/spinner/Spinner';
import { translations } from '../../../../_translations';
import { parseCurrencyWithPlaceholder } from '../../../../_utils/parseHelpers';
import { TCashSheet } from '../../../_models';
import { TCashSheetForm } from '../../../_forms/CashSheetForm';
import { storesSelectors } from '../../../../_store/selectors';
import { cashSheetActions } from '../../../../_store/actions';
import BankSheet from './BankForm';
import './cashSheetTotals.scss';

type TProps = {
  cashSheet?: TCashSheet;
  isLoading?: boolean;
  readOnly?: boolean;
};

const CashSheetTotals: React.FC<TProps> = ({ cashSheet, isLoading, readOnly }) => {
  const initialForm: TCashSheetForm = {
    amountToBank: cashSheet?.amountToBank || 0,
    amountToBankBKF: cashSheet?.amountToBankBKF || 0,
    amountToBankExtra: cashSheet?.amountToBankExtra || [],
    closedBy: cashSheet?.closedBy || '',
    countedCashTodayEnd: cashSheet?.countedCashTodayEnd || 0,
    countedCashTodayStart: cashSheet?.countedCashTodayStart || 0,
    note: cashSheet?.note || '',
  };
  const form = useForm<TCashSheetForm>({ initialForm, submitForm: () => {}, validateForm: () => ({}) });
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [cashSheet]);

  const onBlur = (name: string, value: unknown) => {
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new cashSheetActions.UpdateCashSheet({
            cashSheetId: cashSheet?.id,
            storeId: currentStore.id,
            values: { ...form.values, [name]: value },
          }),
        );
      });
    }
  };

  if (isLoading && !cashSheet) return <Spinner />;
  return (
    <div className={classnames('overview-wrapper', { readonly: readOnly })}>
      <div className="overview-wrapper__cash-sheet">
        <table className="overview-wrapper__cash-sheet__table">
          <tbody>
            <tr className="bold">
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.TOTAL_REVENUE')}</td>
            </tr>
            <tr>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalRevenue)}</td>
            </tr>
            <tr className="bold">
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANCONTACT')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CREDITCARD')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_BANCONTACT')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_CREDITCARD')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.COUPON')}</td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANK_TRANSFER')}</td>
            </tr>
            <tr>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidCash)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidBancontact)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidCreditCard)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidBancontactPortable)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidCreditCardPortable)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidCheck)}</td>
              <td>{parseCurrencyWithPlaceholder(cashSheet?.totalPaidBankTransfer)}</td>
            </tr>
            <tr className="bold">
              <td colSpan={2}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.REGISTER_YESTERDAY')}</td>
              <td colSpan={2}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH_COUNTED')}</td>
              <td colSpan={2}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.REGISTER_AND_CASH_RECEIVED')}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <InputFieldCurrency
                  autoFocus
                  name="countedCashTodayStart"
                  onBlur={onBlur}
                  value={form.values.countedCashTodayStart}
                />
              </td>
              <td colSpan={2}>
                <InputFieldCurrency
                  autoFocus
                  name="countedCashTodayEnd"
                  onBlur={onBlur}
                  value={form.values.countedCashTodayEnd}
                />
              </td>
              <td colSpan={2}>{parseCurrencyWithPlaceholder(cashSheet?.intendedCashTodayEnd)}</td>
            </tr>
            <tr className="bold">
              <td colSpan={2}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.NEW_BALANCE')}</td>
              <td>
                <div className="overview-wrapper__cash-sheet__table__arrow-left"></div>
              </td>
              <td>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.DIFFERENCE')}</td>
              <td>
                <div className="overview-wrapper__cash-sheet__table__arrow-right"></div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>{parseCurrencyWithPlaceholder(cashSheet?.newCashBalance)}</td>
              <td></td>
              <td className={cashSheet?.countedIntendedCashDifference < 0 ? 'negative' : 'positive'}>
                {parseCurrencyWithPlaceholder(cashSheet?.countedIntendedCashDifference)}
              </td>
              <td></td>
            </tr>
            <tr className="bold">
              <td colSpan={2}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.FILLED_IN_BY')}</td>
              <td colSpan={5}>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.REMARKS')}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <InputFieldText autoFocus name="closedBy" onBlur={onBlur} value={form.values.closedBy} />
              </td>
              <td colSpan={5}>
                <InputFieldText autoFocus name="note" onBlur={onBlur} value={form.values.note} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BankSheet cashSheet={cashSheet} form={form} isLoading={isLoading} onBlur={onBlur} />
    </div>
  );
};

export default CashSheetTotals;
