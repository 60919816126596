import { useSelector } from 'react-redux';
import { Code } from '../codes/_models';
import { hasEditPermissions, hasViewPermissions } from '../profile/_utils';
import { storesSelectors, profileSelectors, codesSelectors } from '../_store/selectors';

export type TPermission = {
  edit: boolean;
  view: boolean;
};

function usePermission(code: Code): TPermission {
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);

  const hasViewPermission = hasViewPermissions(codes, code, currentStore?.id, profile.permissions);
  const hasEditPermission = hasEditPermissions(codes, code, currentStore?.id, profile.permissions);

  return { edit: hasEditPermission, view: hasViewPermission };
}

export default usePermission;
