import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePermission, useTableSort } from '../../../_hooks';
import CustomTable, { TTableColumn } from '../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../_translations';
import { TDecorationForm } from '../../_models';
import { TDecorationRevenue, TDecorationRevenueQuery, TDecorationRevenues } from '../../_models/Decoration';
import { ledgerActions } from '../../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../../_store/selectors';
import { HttpSortDirection } from '../../../_http';

import { Code } from '../../../codes/_models';
import DecorationRevenueForm from './DecorationRevenueForm';

type TProps = {
  isLoading: boolean;
  totalDecorationRevenues: TDecorationRevenues;
};

export const decorationRevenueColumns: TTableColumn[] = [
  { className: 'smallest-column centered-text', label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.ID'), name: 'id' },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.DATE'),
    name: 'date',
    sortable: true,
  },
  { className: 'small-column', label: translations.getLabel('LEDGER.TABLE.RECEIPT_CODE'), name: 'receiptCode', sortable: true },
  { className: 'medium-column', label: translations.getLabel('LEDGER.TABLE.TOTAL_PRICE'), name: 'totalPrice', sortable: true },
  { className: 'medium-column border-left', label: translations.getLabel('LEDGER.TABLE.CASH'), name: 'paidCash' },
  { className: 'medium-column', label: translations.getLabel('LEDGER.TABLE.BANCONTACT'), name: 'paidBancontact' },
  { className: 'medium-column', label: translations.getLabel('LEDGER.TABLE.CREDITCARD'), name: 'paidCreditcard' },
  {
    className: 'medium-column border-right',
    label: translations.getLabel('LEDGER.TABLE.BANK_TRANSFER'),
    name: 'paidBankTransfer',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.TOTAL_REVENUE'),
    name: 'totalRevenue',
    sortable: true,
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.DIFFERENCE'),
    name: 'totalDifference',
    sortable: true,
  },
  { className: 'large-column', label: translations.getLabel('LEDGER.TABLE.NOTE'), name: 'note' },
];

const DecorationRevenueTable: React.FC<TProps> = ({ totalDecorationRevenues, isLoading }) => {
  const currentStore = useSelector(storesSelectors.currentStore);
  const query = useSelector(ledgerSelectors.decorationRevenueQuery);
  const dispatch = useDispatch();
  const permission = usePermission(Code.Ledger);

  const setQuery = (partialQuery: TDecorationRevenueQuery) => {
    dispatch(new ledgerActions.SetDecorationRevenueQuery({ query: { ...query, ...partialQuery }, storeId: currentStore.id }));
  };

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ sortBy: column, sortDirection: direction }),
  );

  function renderRow(decoration: TDecorationRevenue, index: number): JSX.Element {
    const initialForm: TDecorationForm = {
      note: decoration.note || '',
    };

    return (
      <DecorationRevenueForm
        decoration={decoration}
        highlight={decoration.highlight}
        index={index}
        initialForm={initialForm}
        key={decoration.id}
      />
    );
  }

  return (
    <CustomTable
      columns={decorationRevenueColumns}
      data={totalDecorationRevenues?.decorationRevenues ? totalDecorationRevenues.decorationRevenues : []}
      isLoading={isLoading}
      readonly={!permission.edit}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default DecorationRevenueTable;
