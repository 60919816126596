import {
  TCashSheetQuery,
  TActiveCellArrayType,
  TActiveCellInformation,
  TCashSheet,
  TSearchCashSheetQuery,
  TCashSheetSearchResults,
} from '../_models';
import { ApiError } from '../../_http';
import { formatDate } from '../../_utils/timeHelpers';
import { AuthAction } from '../../auth/_store/actions';
import { CashSheetsAction, CashSheetsActionType } from './actions';

export type CashSheetsState = {
  activeCell?: TActiveCellInformation;
  detail?: TCashSheet;
  errorCrudCashSheets?: ApiError;
  isGetCashSheetDetailLoading?: boolean;
  isSearchLoading?: boolean;
  isUpdateCasheetLoading?: boolean;
  query: TCashSheetQuery;
  search?: TSearchCashSheetQuery;
  searchResults?: TCashSheetSearchResults;
};

export const initialState: CashSheetsState = { query: { date: formatDate(new Date(), 'yyyy-MM-dd') } };

function addNewRow(detail: TCashSheet, arrayType: TActiveCellArrayType, index: number): TCashSheet {
  if (arrayType === 'decorationRevenue') return detail;
  const amountOfNonExistingRows = (detail[arrayType] as { id: string }[]).slice(0, index - 1).filter(item => !item.id).length;
  const orderNumber = index - amountOfNonExistingRows + 1;
  return {
    ...detail,
    [arrayType]: [...detail[arrayType].slice(0, index + 1), { orderNumber }, ...detail[arrayType].slice(index + 1)],
  };
}

function deleteRow(detail: TCashSheet, arrayType: TActiveCellArrayType, index: number): TCashSheet {
  if (arrayType === 'decorationRevenue') return detail;
  //check if id exists, then we need to execute a call to the backend and can't just delete object from array
  if (detail[arrayType][index].id) return detail;

  return { ...detail, [arrayType]: [...detail[arrayType].slice(0, index), ...detail[arrayType].slice(index + 1)] };
}

export default function reducer(state = initialState, action: CashSheetsAction | AuthAction): CashSheetsState {
  switch (action.type) {
    case CashSheetsActionType.GetCashSheetDetail:
      return {
        ...state,
        errorCrudCashSheets: null,
        isGetCashSheetDetailLoading: true,
      };
    case CashSheetsActionType.GetCashSheetDetailSuccess: {
      return {
        ...state,
        detail: action.payload.data,
        isGetCashSheetDetailLoading: false,
      };
    }
    case CashSheetsActionType.GetCashSheetDetailError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isGetCashSheetDetailLoading: false,
      };
    case CashSheetsActionType.GetCashSheetDetailSearch:
      return {
        ...state,
        errorCrudCashSheets: null,
        isSearchLoading: true,
      };
    case CashSheetsActionType.GetCashSheetDetailSearchSuccess: {
      return {
        ...state,
        isSearchLoading: false,
        searchResults: action.payload.data,
      };
    }
    case CashSheetsActionType.GetCashSheetDetailSearchError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isSearchLoading: false,
      };
    case CashSheetsActionType.UpdateDelivery:
      return {
        ...state,
        errorCrudCashSheets: null,
        isUpdateCasheetLoading: true,
      };
    case CashSheetsActionType.UpdateDeliverySuccess: {
      return {
        ...state,
        isUpdateCasheetLoading: false,
      };
    }
    case CashSheetsActionType.UpdateDeliveryError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isUpdateCasheetLoading: false,
      };
    case CashSheetsActionType.UpdateSale:
      return {
        ...state,
        errorCrudCashSheets: null,
        isUpdateCasheetLoading: true,
      };
    case CashSheetsActionType.UpdateSaleSuccess: {
      return {
        ...state,
        isUpdateCasheetLoading: false,
      };
    }
    case CashSheetsActionType.UpdateSaleError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isUpdateCasheetLoading: false,
      };
    case CashSheetsActionType.UpdateDecorationRevenue:
      return {
        ...state,
        errorCrudCashSheets: null,
        isUpdateCasheetLoading: true,
      };
    case CashSheetsActionType.UpdateDecorationRevenueSuccess: {
      return {
        ...state,
        isUpdateCasheetLoading: false,
      };
    }
    case CashSheetsActionType.UpdateDecorationRevenueError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isUpdateCasheetLoading: false,
      };
    case CashSheetsActionType.UpdateCashSheet:
      return {
        ...state,
        errorCrudCashSheets: null,
        isUpdateCasheetLoading: true,
      };
    case CashSheetsActionType.UpdateCashSheetSuccess: {
      return {
        ...state,
        isUpdateCasheetLoading: false,
      };
    }
    case CashSheetsActionType.UpdateCashSheetError:
      return {
        ...state,
        errorCrudCashSheets: action.payload.error,
        isUpdateCasheetLoading: false,
      };
    case CashSheetsActionType.SetCashSheetQuery:
      return {
        ...state,
        query: action.payload.query,
      };
    case CashSheetsActionType.SetCashSheetSearch:
      return {
        ...state,
        search: action.payload.search,
      };
    case CashSheetsActionType.ClearCashSheetSearch:
      return {
        ...state,
        searchResults: undefined,
      };
    case CashSheetsActionType.SetActiveCell:
      return {
        ...state,
        activeCell: action.payload.activeCell,
      };
    case CashSheetsActionType.AddNewRow:
      return {
        ...state,
        detail: addNewRow(state.detail, state.activeCell.array, state.activeCell.index),
      };
    case CashSheetsActionType.DeleteRow:
      return {
        ...state,
        detail: deleteRow(state.detail, state.activeCell.array, state.activeCell.index),
      };
    case CashSheetsActionType.CreateCashSheetSuccess: {
      return {
        ...state,
        detail: action.payload.data,
      };
    }
    default:
      return state;
  }
}
