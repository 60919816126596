import { Action } from 'redux';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { ItemState } from '../../_utils/stateHelper';
import { StockItem, IUpdateStockItem, StockChange, Ids } from '../_models';

export enum StockActionType {
  GetStockChange = '[Stock] GetStockChange',
  GetStockChangeError = '[Stock] GetStockChangeError',
  GetStockChangeSuccess = '[Stock] GetStockChangeSuccess',
  GetStockItems = '[Stock] GetStockItems',
  GetStockItemsError = '[Stock] GetStockItemsError',
  GetStockItemsSuccess = '[Stock] GetStockItemsSuccess',
  SetStockChangeQuery = '[Stock] SetStockChangeQuery',
  UpdateStockItem = '[Stock] UpdateStockItem',
  UpdateStockItemError = '[Stock] UpdateStockItemError',
  UpdateStockItemSuccess = '[Stock] UpdateStockItemSuccess',
}

export class GetStockChange implements Action<StockActionType> {
  readonly type = StockActionType.GetStockChange;
  constructor(public payload: { storeId: string }) {}
}

export class GetStockChangeSuccess implements Action<StockActionType> {
  readonly type = StockActionType.GetStockChangeSuccess;
  constructor(public payload: { data: StockChange[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetStockChangeError implements Action<StockActionType> {
  readonly type = StockActionType.GetStockChangeError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetStockItems implements Action<StockActionType> {
  readonly type = StockActionType.GetStockItems;
  constructor(public payload: { skip?: number; state: ItemState; storeId: string; take?: number }) {}
}

export class GetStockItemsSuccess implements Action<StockActionType> {
  readonly type = StockActionType.GetStockItemsSuccess;
  constructor(public payload: { data: StockItem[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetStockItemsError implements Action<StockActionType> {
  readonly type = StockActionType.GetStockItemsError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetStockChangeQuery implements Action<StockActionType> {
  readonly type = StockActionType.SetStockChangeQuery;
  constructor(public payload: { query: HttpMetadataQuery; storeId: string }) {}
}

export class UpdateStockItem implements Action<StockActionType> {
  readonly type = StockActionType.UpdateStockItem;
  constructor(public payload: { ids: Ids; onSuccess?: () => void; values: IUpdateStockItem }) {}
}

export class UpdateStockItemSuccess implements Action<StockActionType> {
  readonly type = StockActionType.UpdateStockItemSuccess;
  constructor(public payload: { updatedStockItem: StockItem }) {}
}

export class UpdateStockItemError implements Action<StockActionType> {
  readonly type = StockActionType.UpdateStockItemError;
  constructor(public payload: { error: ApiError }) {}
}

export type StockAction =
  | GetStockChange
  | GetStockChangeSuccess
  | GetStockChangeError
  | GetStockItems
  | GetStockItemsSuccess
  | GetStockItemsError
  | SetStockChangeQuery
  | UpdateStockItem
  | UpdateStockItemSuccess
  | UpdateStockItemError;
