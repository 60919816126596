import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Icon, SearchField } from '../../_shared';
import { cashSheetActions } from '../../_store/actions';
import { cashSheetsSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { TSearchCashSheetQuery } from '../_models';
import { dateFromString, formatDate } from '../../_utils/timeHelpers';
import SalesSearchTable from './tables/sale/SalesSearchTable';
import DeliverySearchTable from './tables/delivery/DeliverySearchTable';
import DecorationSearchTable from './tables/decoration/DecorationSearchTable';
import './cashSheetsShearch.scss';

type TProps = {
  storeId: string;
};

const CashSheetsSearch: React.FC<TProps> = ({ storeId }) => {
  const search = useSelector(cashSheetsSelectors.search);
  const results = useSelector(cashSheetsSelectors.searchResults);
  const isLoading = useSelector(cashSheetsSelectors.isSearchLoading);
  const dispatch = useDispatch();

  function setSearch(search: TSearchCashSheetQuery) {
    dispatch(new cashSheetActions.SetCashSheetSearch({ search, storeId }));
  }

  function setDate(date: string) {
    dispatch(
      new cashSheetActions.SetCashSheetQuery({ query: { date: formatDate(dateFromString(date), 'yyyy-MM-dd') }, storeId }),
    );
  }

  return (
    <main className="cash-sheets-search">
      <NavLink className="cash-sheets-search__back" to={`/cash-sheets/${storeId}`}>
        <Icon name="SvgChevronLeft" size={3} />
        {translations.getLabel('CASH_SHEETS.SEARCH.NAVIGATE_BACK')}
      </NavLink>
      <h2 className="cash-sheets-search__title"> {translations.getLabel('CASH_SHEETS.SEARCH.TITLE')}</h2>
      <SearchField query={search} setQuery={search => setSearch(search)} />

      <h4>{translations.getLabel('CASH_SHEETS.OVERVIEW.SALES_TITLE')}</h4>
      <SalesSearchTable data={results?.sales} isLoading={isLoading} setDate={setDate} storeId={storeId} />
      <h4>{translations.getLabel('CASH_SHEETS.OVERVIEW.DELIVERIES_TITLE')}</h4>
      <DeliverySearchTable data={results?.deliveries} isLoading={isLoading} setDate={setDate} storeId={storeId} />
      <h4>{translations.getLabel('CASH_SHEETS.OVERVIEW.DECORATION_TITLE')}</h4>
      <DecorationSearchTable data={results?.decorationRevenues} isLoading={isLoading} setDate={setDate} storeId={storeId} />
    </main>
  );
};
export default CashSheetsSearch;
