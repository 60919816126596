import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../../../_hooks/useForm';
import CustomDropdown from '../../../../_shared/custom/customDropdown/CustomDropdown';
import CustomTable from '../../../../_shared/custom/customTable/CustomTable';
import InputFieldCurrency from '../../../../_shared/custom/inputFieldCurrency/InputFieldCurrency';
import InputFieldText from '../../../../_shared/custom/inputFieldText/InputFieldText';
import { cashSheetActions } from '../../../../_store/actions';
import { translations } from '../../../../_translations';
import { DeliveryType, Highlight, TCashSheet } from '../../../_models';
import { TDeliveryForm } from '../../../_forms/DeliveryForm';
import { cashSheetsSelectors, storesSelectors } from '../../../../_store/selectors';

type TProps = {
  cashSheet?: TCashSheet;
  deliveryId?: string;
  highlight: Highlight;
  index: number;
  initialForm: TDeliveryForm;
  isSubmitting?: boolean;
};

const DeliveryForm: React.FC<TProps> = ({ deliveryId, initialForm, highlight, index, cashSheet }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const activeCell = useSelector(cashSheetsSelectors.activeCell);
  const error = useSelector(cashSheetsSelectors.errorCrudCashSheets);

  const form = useForm<TDeliveryForm>({ error, initialForm, submitForm: () => {}, validateForm: () => ({}) });

  useEffect(() => {
    if (!deliveryId) return form.setFormValues(initialForm);
    if (activeCell?.index === index && activeCell?.array === 'deliveries') return;
    form.setFormValues(initialForm);
  }, [cashSheet]);

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [deliveryId]);

  useEffect(() => {
    if (error) {
      form.setFormValues(initialForm);
    }
  }, [error]);

  const onClick = (name: string) => {
    dispatch(
      new cashSheetActions.SetActiveCell({
        activeCell: { array: 'deliveries', highlight, index, name, orderNumber: form.values.orderNumber },
      }),
    );
  };
  const onBlur = (name: string, value: unknown) => {
    dispatch(new cashSheetActions.ClearActiveCell({ activeCell: { array: 'deliveries', index, name } }));
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new cashSheetActions.UpdateDelivery({
            cashSheetId: cashSheet?.id,
            deliveryId,
            storeId: currentStore.id,
            values: { ...values, [name]: value },
          }),
        );
      });
    }
  };
  return (
    <CustomTable.Row className={highlight?.toLowerCase()} key={index}>
      <CustomTable.Cell className="bold centered-text">{deliveryId ? form.values.orderNumber + 1 : null}</CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="clientName" onBlur={onBlur} onClick={onClick} value={form.values.clientName} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldText
          autoFocus
          name="receiptCode"
          normalize={value => value.toUpperCase()}
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.receiptCode}
        />
      </CustomTable.CellEditable>
      <CustomTable.Cell className="dropdown">
        <CustomDropdown
          className="table-dropdown"
          name="type"
          onChange={(value, name) => onBlur(name, value)}
          options={Object.values(DeliveryType)
            .map(value => ({
              label: translations.getLabel(`SHARED.DROPDOWN.${value}`),
              value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          placeholder={translations.getLabel('SHARED.DROPDOWN.PLACEHOLDER')}
          value={form.values.type}
        />
      </CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldCurrency autoFocus name="paidCash" onBlur={onBlur} onClick={onClick} value={form.values.paidCash} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBancontact"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBancontact}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidCreditCard"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidCreditCard}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBancontactPortable"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBancontactPortable}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidCreditCardPortable"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidCreditCardPortable}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency autoFocus name="paidCheck" onBlur={onBlur} onClick={onClick} value={form.values.paidCheck} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBankTransfer"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBankTransfer}
        />
      </CustomTable.CellEditable>
    </CustomTable.Row>
  );
};

export default DeliveryForm;
