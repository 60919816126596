import React from 'react';
import { Switch, Route, useRouteMatch, Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SecondaryNav from '../_routing/layouts/authorized/menu/SecondaryNav';
import { codesSelectors, profileSelectors, storesSelectors } from '../_store/selectors';
import { ForbiddenPage, TabMenu } from '../_shared';
import { translations } from '../_translations';
import { hasViewPermissions } from '../profile/_utils';
import { Code } from '../codes/_models';
import ReportsBestSelling from './reportsBestsellers/ReportsBestsellers';
import ReportsTopTen from './reportsTopTen/ReportsTopTen';

import './reports.scss';

const Reports: React.FC = () => {
  const { url } = useRouteMatch();
  const { storeId } = useParams<{ storeId: string }>();
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);

  const hasViewPermission = hasViewPermissions(codes, Code.TotalPrice, currentStore?.id, profile.permissions);

  const menuItems = [
    {
      path: `${url}/top-ten`,
      text: translations.getLabel('REPORTS.MENU.TOP_TEN'),
    },
    {
      path: `${url}/best-selling-count`,
      text: translations.getLabel('REPORTS.MENU.BEST_SELLING_COUNT'),
    },
    {
      path: `${url}/best-selling-revenue`,
      text: translations.getLabel('REPORTS.MENU.BEST_SELLING_REVENUE'),
    },
  ];

  return (
    <>
      <SecondaryNav />
      {hasViewPermission ? (
        <section className="reports-page">
          <TabMenu menuItems={menuItems} />
          <Switch>
            <Route exact path={`${url}/top-ten`} render={props => <ReportsTopTen {...props} storeId={storeId} />} />
            <Route
              exact
              path={`${url}/best-selling-count`}
              render={props => <ReportsBestSelling {...props} sortBy="quantity" storeId={storeId} />}
            />
            <Route
              exact
              path={`${url}/best-selling-revenue`}
              render={props => <ReportsBestSelling {...props} sortBy="sellValue" storeId={storeId} />}
            />
            <Redirect to={`${url}/top-ten`} />
          </Switch>
        </section>
      ) : (
        <ForbiddenPage />
      )}
    </>
  );
};

export default Reports;
