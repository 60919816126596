import { ApiError } from '../../_http';
import { formatDate } from '../../_utils/timeHelpers';
import {
  TBankTransfers,
  TDecorationRevenueQuery,
  TDecorationRevenues,
  TLedgerActiveCellInformation,
  TOtherFurniture,
  TOtherFurnitureQuery,
  TRevenuePerSeller,
} from '../_models';
import { LedgerAction, LedgerActionType } from './actions';

export type LedgerState = {
  activeCell?: TLedgerActiveCellInformation;
  bankTransfers?: TBankTransfers;
  decorationQuery?: TDecorationRevenueQuery;
  decorationRevenue?: TDecorationRevenues;
  errorCrudLedger?: ApiError;
  isBankTransfersLoading?: boolean;
  isDecorationRevenuesLoading?: boolean;
  isOtherFurnitureLoading?: boolean;
  isRevenuePerSellerLoading?: boolean;
  month: string;
  otherFurniture?: TOtherFurniture;
  otherFurnitureQuery?: TOtherFurnitureQuery;
  revenuePerSeller?: TRevenuePerSeller;
  year: string;
};

export const initialState: LedgerState = {
  decorationQuery: { year: formatDate(new Date(), 'yyyy') },
  month: formatDate(new Date(), 'yyyy-MM'),
  otherFurnitureQuery: { isOwedAmountZeroExcluded: false, year: formatDate(new Date(), 'yyyy') },
  year: formatDate(new Date(), 'yyyy'),
};

export default function reducer(state = initialState, action: LedgerAction): LedgerState {
  switch (action.type) {
    case LedgerActionType.GetLedgerRevenuePerSeller:
      return {
        ...state,
        errorCrudLedger: null,
        isRevenuePerSellerLoading: true,
      };
    case LedgerActionType.GetLedgerRevenuePerSellerSuccess: {
      return {
        ...state,
        isRevenuePerSellerLoading: false,
        revenuePerSeller: action.payload.data,
      };
    }
    case LedgerActionType.SetActiveCell:
      return {
        ...state,
        activeCell: action.payload.activeCell,
      };
    case LedgerActionType.GetLedgerRevenuePerSellerError:
      return {
        ...state,
        errorCrudLedger: action.payload.error,
        isRevenuePerSellerLoading: false,
      };
    case LedgerActionType.GetLedgerBankTransfers:
      return {
        ...state,
        errorCrudLedger: null,
        isBankTransfersLoading: true,
      };
    case LedgerActionType.GetLedgerBankTransfersSuccess: {
      return {
        ...state,
        bankTransfers: action.payload.data,
        isBankTransfersLoading: false,
      };
    }
    case LedgerActionType.GetLedgerBankTransfersError:
      return {
        ...state,
        errorCrudLedger: action.payload.error,
        isBankTransfersLoading: false,
      };
    case LedgerActionType.GetLedgerDecorationRevenue:
      return {
        ...state,
        errorCrudLedger: null,
        isDecorationRevenuesLoading: true,
      };
    case LedgerActionType.GetLedgerDecorationRevenueSuccess: {
      return {
        ...state,
        decorationRevenue: action.payload.data,
        isDecorationRevenuesLoading: false,
      };
    }
    case LedgerActionType.GetLedgerDecorationRevenueError:
      return {
        ...state,
        errorCrudLedger: action.payload.error,
        isDecorationRevenuesLoading: false,
      };
    case LedgerActionType.GetLedgerOtherFurniture:
      return {
        ...state,
        errorCrudLedger: null,
        isOtherFurnitureLoading: true,
      };
    case LedgerActionType.GetLedgerOtherFurnitureSuccess: {
      return {
        ...state,
        isOtherFurnitureLoading: false,
        otherFurniture: action.payload.data,
      };
    }
    case LedgerActionType.GetLedgerOtherFurnitureError:
      return {
        ...state,
        errorCrudLedger: action.payload.error,
        isOtherFurnitureLoading: false,
      };
    case LedgerActionType.SetLedgerMonth:
      return {
        ...state,
        month: action.payload.month,
      };
    case LedgerActionType.SetLedgerYear:
      return {
        ...state,
        year: action.payload.year,
      };
    case LedgerActionType.SetDecorationRevenueQuery:
      return {
        ...state,
        decorationQuery: action.payload.query,
      };
    case LedgerActionType.SetOtherFurnitureQuery:
      return {
        ...state,
        otherFurnitureQuery: action.payload.query,
      };
    default:
      return state;
  }
}
