import React, { FC } from 'react';
import { translations } from '../../../_translations';
import { Button, InputField, ErrorMessage } from '../../../_shared';
import { useForm } from '../../../_hooks';
import { SubmitFormFunction, FormValidationErrors } from '../../../_hooks/useForm';
import { formValidator } from '../../../_utils/formValidation';
import { ApiError } from '../../../_http';
import { IPricesForm } from '../../_models';
import { errorAsString } from '../../../_utils/errorHelper';
import './pricesform.scss';

type Props = {
  buttons?: JSX.Element | JSX.Element[];
  error?: ApiError;
  initialForm: IPricesForm;
  isSubmitting?: boolean;
  submitForm: SubmitFormFunction<IPricesForm>;
};

function validateForm(values: IPricesForm): FormValidationErrors<IPricesForm> {
  return {
    code: formValidator.required(values.code).error,
  };
}

const PricesForm: FC<Props> = ({ initialForm, submitForm, isSubmitting, error, buttons }) => {
  const form = useForm<IPricesForm>({ error, initialForm, submitForm, validateForm });
  const errorMessage = errorAsString(error);

  return (
    <form className="prices-form" onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>

      <div className="content-wrapper">
        <div className="product-wrapper">
          <InputField
            disabled
            errorMessage={form.validationErrors.code}
            label={translations.getLabel('PRICES.EDIT.CODE')}
            name="code"
            onChange={form.setAttribute}
            type="text"
            value={form.values.code}
          />
          <InputField
            errorMessage={form.validationErrors.title}
            label={translations.getLabel('PRICES.EDIT.TITLE_NAME')}
            name="title"
            onChange={form.setAttribute}
            type="text"
            value={form.values.title}
          />
          <InputField
            errorMessage={form.validationErrors.description}
            label={translations.getLabel('PRICES.EDIT.DESCRIPTION')}
            name="description"
            onChange={form.setAttribute}
            type="text"
            value={form.values.description}
          />
        </div>
        <div className="price-wrapper">
          <InputField
            disabled
            errorMessage={form.validationErrors.grossPrice}
            label={translations.getLabel('PRICES.EDIT.GROSS_PRICE')}
            name="grossPrice"
            onChange={form.setAttribute}
            type="text"
            value={form.values.grossPrice}
          />
          <InputField
            disabled
            errorMessage={form.validationErrors.sellingPrice}
            label={translations.getLabel('PRICES.EDIT.NETTO_PRICE')}
            name="sellingPrice"
            onChange={form.setAttribute}
            type="text"
            value={form.values.sellingPrice}
          />
        </div>
      </div>

      <div className="actions">
        {buttons}
        <Button loading={isSubmitting} primary type="submit">
          {translations.getLabel('SHARED.BUTTONS.CREATE')}
        </Button>
      </div>
    </form>
  );
};

export default PricesForm;
