import { IPermissions } from '../../profile/_models';

export type IUser = {
  createdAt?: string;
  createdBy?: string;
  defaultStoreId: string;
  email: string;
  firstName?: string;
  id: string;
  isAdmin: boolean;
  lastName?: string;
  permissions: IPermissions[];
  state: UserState;
  stores: string[];
  updatedAt?: string;
  updatedBy?: string;
};

export enum UserState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Registering = 'REGISTERING',
}
