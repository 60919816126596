import { ICode } from '../_models';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { insertUpdatedData } from '../../_utils/objectHelpers';
import { AuthActionType, AuthAction } from '../../auth/_store/actions';
import { CodesAction, CodesActionType } from './actions';

export type CodesState = {
  codes?: ICode[];
  detail?: ICode;
  errorCrudCodes?: ApiError;
  isCreateCodeLoading?: boolean;
  isDeleteCodeLoading?: boolean;
  isGetCodeDetailLoading?: boolean;
  isGetCodesLoading?: boolean;
  isUpdateCodeLoading?: boolean;
  metadata?: HttpMetadataPagingResponse;
  query?: HttpMetadataQuery;
};

export const initialState: CodesState = {};

export default function reducer(state = initialState, action: CodesAction | AuthAction): CodesState {
  switch (action.type) {
    case CodesActionType.GetCodeDetail:
      return {
        ...state,
        errorCrudCodes: null,
        isGetCodeDetailLoading: true,
      };
    case CodesActionType.GetCodeDetailSuccess: {
      return {
        ...state,
        detail: action.payload.data,
        isGetCodeDetailLoading: false,
      };
    }
    case CodesActionType.GetCodeDetailError:
      return {
        ...state,
        errorCrudCodes: action.payload.error,
        isGetCodeDetailLoading: false,
      };
    case CodesActionType.GetCodes:
      return {
        ...state,
        errorCrudCodes: null,
        isGetCodesLoading: true,
        metadata: null,
      };
    case CodesActionType.GetCodesSuccess: {
      let currentData = state.codes || [];
      if (!action.payload.meta.skip) currentData = []; // Start overnew when the offset was reset
      return {
        ...state,
        codes: insertUpdatedData(currentData, action.payload.data),
        isGetCodesLoading: false,
        metadata: action.payload.meta,
      };
    }
    case CodesActionType.GetCodesError:
      return {
        ...state,
        errorCrudCodes: action.payload.error,
        isGetCodesLoading: false,
      };
    case CodesActionType.SetCodesQuery:
      return {
        ...state,
        query: action.payload.query,
      };
    case CodesActionType.UpdateCode:
      return {
        ...state,
        errorCrudCodes: null,
        isUpdateCodeLoading: true,
      };
    case CodesActionType.UpdateCodeSuccess:
      return {
        ...state,
        codes: insertUpdatedData(state.codes, [action.payload.updatedCode]),
        detail: action.payload.updatedCode,
        isUpdateCodeLoading: false,
      };
    case CodesActionType.UpdateCodeError:
      return {
        ...state,
        errorCrudCodes: action.payload.error,
        isUpdateCodeLoading: false,
      };
    case CodesActionType.DeleteCode:
      return {
        ...state,
        errorCrudCodes: null,
        isDeleteCodeLoading: action.payload.confirmed,
      };
    case CodesActionType.DeleteCodeSuccess:
      return {
        ...state,
        codes: [...state.codes.filter(Code => Code.id !== action.payload.CodeId)],
        isDeleteCodeLoading: false,
      };
    case CodesActionType.DeleteCodeError:
      return {
        ...state,
        errorCrudCodes: action.payload.error,
        isDeleteCodeLoading: false,
      };
    case AuthActionType.AuthenticateSuccess:
      if (!action.payload.codes) return state;
      // eslint-disable-next-line no-case-declarations
      const currentData = state.codes || [];
      return {
        ...state,
        codes: insertUpdatedData(currentData, action.payload.codes.data),
        isGetCodesLoading: false,
        metadata: action.payload.codes.meta,
      };
    default:
      return state;
  }
}
