import React from 'react';
import classnames from 'classnames';
import { translations } from '../../../_translations';
import { parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TBankTransfers } from '../../_models';
import { bankTransferColumns } from '../bankTransfersPerMonth/BankTransfersPerMonth';
import { BottomBar } from '../../../_shared';
import './bankTransferBottomBar.scss';

type TProps = {
  bankTransfer?: TBankTransfers;
};

const BankTransferBottomBar: React.FC<TProps> = ({ bankTransfer }) => {
  return (
    <BottomBar>
      <div className="bottom-bar__bank-transfer-table-wrapper">
        <table>
          <colgroup>
            {bankTransferColumns.map(column => (
              <col className={classnames(column.className)} key={column.name} />
            ))}
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={2}>{translations.getLabel('LEDGER.GENERAL.TOTAL')}</td>
              <td colSpan={1}>{parseCurrencyWithPlaceholder(bankTransfer?.totalAmountToBank)}</td>
              <td colSpan={4} />
            </tr>
          </tbody>
        </table>
      </div>
    </BottomBar>
  );
};

export default BankTransferBottomBar;
