import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { ItemState } from '../../_utils/stateHelper';
import { StoresState } from './reducer';

const selectNode = (state: AppState) => state.stores;

export const adminStores = createSelector(selectNode, (state: StoresState) => state.adminStores);
export const activeAdminStores = createSelector(selectNode, (state: StoresState) =>
  state.adminStores?.filter(store => store.state === ItemState.Active),
);
export const store = (storeId: string) =>
  createSelector(selectNode, (state: StoresState) => state.adminStores.find(store => store.id === storeId));
export const metadata = createSelector(selectNode, (state: StoresState) => state.metadata);
export const query = createSelector(selectNode, (state: StoresState) => state.query);
export const errorCrudStores = createSelector(selectNode, (state: StoresState) => state.errorCrudStores);
export const errorDeactivateStore = createSelector(selectNode, (state: StoresState) => state.errorDeactivateStore);
export const isCreateStoreLoading = createSelector(selectNode, (state: StoresState) => state.isCreateStoreLoading);
export const isGetStoreDetailLoading = createSelector(selectNode, (state: StoresState) => state.isGetStoreDetailLoading);
export const isGetAdminStoresLoading = createSelector(selectNode, (state: StoresState) => state.isGetAdminStoresLoading);
export const isUpdateStoreLoading = createSelector(selectNode, (state: StoresState) => state.isUpdateStoreLoading);
export const stores = createSelector(selectNode, (state: StoresState) => state.stores);
export const currentStore = createSelector(selectNode, (state: StoresState) => state.currentStore);
