import { HttpMetadataQuery, HttpClient, HttpPagedResponse } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { BestsellerItem, TopTenItem } from '../_models';

export function getTopTen(storeId: string): Promise<{ data: TopTenItem[] }> {
  return HttpClient.get<{ data: TopTenItem[] }>(`reporting/top-10`, null, {
    'store-id': storeId,
  });
}

export function getBestsellers(storeId: string, query?: HttpMetadataQuery): Promise<HttpPagedResponse<BestsellerItem>> {
  return HttpClient.get<HttpPagedResponse<BestsellerItem>>(`reporting/bestsellers/${getQueryParams(query)}`, null, {
    'store-id': storeId,
  });
}

export function exportBestsellers(storeId: string, query: HttpMetadataQuery): Promise<Blob> {
  return HttpClient.get<Blob>(
    `reporting/export-bestsellers${getQueryParams(query)}`,
    null,
    {
      'store-id': storeId,
    },
    'blob',
  );
}
