import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';

import { SearchField, TabMenu, Button, Icon } from '../../_shared';
import { usersSelectors, profileSelectors } from '../../_store/selectors';
import { usersActions } from '../../_store/actions';
import { HttpMetadataQuery, FillMetadataQueryFunction } from '../../_http';
import { translations } from '../../_translations';
import { menuItems } from '../_utils';
import UsersTable from './UsersTable';

import './usersOverview.scss';

const UsersOverview: FC = () => {
  const users = useSelector(usersSelectors.users);
  const isLoading = useSelector(usersSelectors.isGetUsersLoading);
  const query = useSelector(usersSelectors.query);
  const profile = useSelector(profileSelectors.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new usersActions.GetUsers());
  }, []);

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new usersActions.SetUsersQuery({ query: { ...query, ...partialQuery } }));
  };

  return (
    <Container as="main" className="users">
      <div className="header">
        <h4 className="overview-title">{translations.getLabel('MANAGEMENT.MENU.USERS')}</h4>

        <TabMenu menuItems={menuItems} />

        {profile.isAdmin && (
          <Button className="overview" href="/management/users/create" primary>
            <Icon name="SvgAdd" />
            <span>{translations.getLabel('USERS.OVERVIEW.CREATE_USER')}</span>
          </Button>
        )}
      </div>

      <SearchField query={query} setQuery={setQuery} />

      <UsersTable data={users} isLoading={isLoading} setQuery={setQuery} />
    </Container>
  );
};
export default UsersOverview;
