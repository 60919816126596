import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { Code } from '../codes/_models';
import { usePermission } from '../_hooks';
import SecondaryNav from '../_routing/layouts/authorized/menu/SecondaryNav';
import { ForbiddenPage } from '../_shared';
import CashSheetsOverview from './overview/CashSheetsOverview';
import CashSheetsSearch from './overview/CashSheetsSearch';

const CashSheets: React.FC = () => {
  const { storeId } = useParams<{ storeId: string }>();
  const permission = usePermission(Code.CashSheet);

  return (
    <>
      <SecondaryNav />
      <Switch>
        {permission.view ? (
          <>
            <Route exact path="/cash-sheets/:storeId" render={() => <CashSheetsOverview storeId={storeId} />} />
            <Route path="/cash-sheets/:storeId/search" render={() => <CashSheetsSearch storeId={storeId} />} />
          </>
        ) : (
          <ForbiddenPage />
        )}
      </Switch>
    </>
  );
};

export default CashSheets;
