import React from 'react';
import classnames from 'classnames';
import { translations } from '../../../_translations';
import { otherFurnitureTableColumns } from '../table/OtherFurnitureTable';
import './otherFurnitureGroupHeaders.scss';

const OtherFurnitureGroupHeaders: React.FC = () => {
  return (
    <table className="group-header-table">
      <colgroup>
        {otherFurnitureTableColumns.map(column => (
          <col className={classnames(column.className)} key={column.name} />
        ))}
      </colgroup>
      <tbody>
        <tr>
          <td colSpan={6} />
          <td className="active" colSpan={3}>
            {translations.getLabel('LEDGER.TABLE.DEPOSIT').toUpperCase()}
          </td>
          <td className="active" colSpan={3}>
            {translations.getLabel('LEDGER.TABLE.PICKUP').toUpperCase()}
          </td>
          <td className="active" colSpan={4}>
            {translations.getLabel('LEDGER.TABLE.DELIVERY').toUpperCase()}
          </td>
          <td colSpan={3} />
        </tr>
      </tbody>
    </table>
  );
};

export default OtherFurnitureGroupHeaders;
