import { translations } from '../../_translations';

export function calculateGrossPrice(sellingPrice: string): string {
  return `${Math.floor(parseFloat(sellingPrice) * 1.35).toString()}` || '';
}

export function showPricePdf(blob: string) {
  const newBlob = new Blob([blob], { type: 'application/pdf ' });

  const url = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', translations.getLabel('PRICES.PRINT.PDF'));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
