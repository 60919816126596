import { Action } from 'redux';
import { ApiError } from '../../_http';
import { TCashSheetForm } from '../_forms/CashSheetForm';
import { TDecorationForm } from '../_forms/DecorationForm';
import { TDeliveryForm } from '../_forms/DeliveryForm';
import { TSaleForm } from '../_forms/SaleForm';
import { TCashSheetQuery, TActiveCellInformation, TCashSheet, TSearchCashSheetQuery, TCashSheetSearchResults } from '../_models';

export interface IUpdateCashSheetAction {
  payload: {
    cashSheetId: string;
    storeId: string;
  };
}

export enum CashSheetsActionType {
  AddNewRow = '[CashSheets] AddNewRow',
  ClearActiveCell = '[CashSheets] ClearActiveCell',
  ClearCashSheetSearch = '[CashSheets] ClearCashSheetSearch',
  CreateCashSheet = '[CashSheets] CreateCashSheet',
  CreateCashSheetError = '[CashSheets] CreateCashSheetError',
  CreateCashSheetSuccess = '[CashSheets] CreateCashSheetSuccess',
  DeleteDelivery = '[CashSheets] DeleteDelivery',
  DeleteDeliveryError = '[CashSheets] DeleteDeliveryError',
  DeleteDeliverySuccess = '[CashSheets] DeleteDeliverySuccess',
  DeleteRow = '[CashSheets] DeleteRow',
  DeleteSale = '[CashSheets] DeleteSale',
  DeleteSaleError = '[CashSheets] DeleteSaleError',
  DeleteSaleSuccess = '[CashSheets] DeleteSaleSuccess',
  GetCashSheetDetail = '[CashSheets] GetCashSheetDetail',
  GetCashSheetDetailError = '[CashSheets] GetCashSheetDetailError',
  GetCashSheetDetailSearch = '[CashSheets] GetCashSheetDetailSearch',
  GetCashSheetDetailSearchError = '[CashSheets] GetCashSheetDetailSearchError',
  GetCashSheetDetailSearchSuccess = '[CashSheets] GetCashSheetDetailSearchSuccess',
  GetCashSheetDetailSuccess = '[CashSheets] GetCashSheetDetailSuccess',
  SetActiveCell = '[CashSheets] SetActiveCell',
  SetCashSheetQuery = '[CashSheets] SetCashSheetQuery',
  SetCashSheetSearch = '[CashSheets] SetCashSheetSearch',
  UpdateCashSheet = '[CashSheets] UpdateCashSheet',
  UpdateCashSheetError = '[CashSheets] UpdateCashSheetError',
  UpdateCashSheetSuccess = '[CashSheets] UpdateCashSheetSuccess',
  UpdateDecorationRevenue = '[CashSheets] UpdateDecorationRevenue',
  UpdateDecorationRevenueError = '[CashSheets] UpdateDecorationRevenueError',
  UpdateDecorationRevenueSuccess = '[CashSheets] UpdateDecorationRevenueSuccess',
  UpdateDelivery = '[CashSheets] UpdateDelivery',
  UpdateDeliveryError = '[CashSheets] UpdateDeliveryError',
  UpdateDeliverySuccess = '[CashSheets] UpdateDeliverySuccess',
  UpdateSale = '[CashSheets] UpdateSale',
  UpdateSaleError = '[CashSheets] UpdateSaleError',
  UpdateSaleSuccess = '[CashSheets] UpdateSaleSuccess',
}

export class GetCashSheetDetail implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetail;
  constructor(public payload: { storeId: string }) {}
}

export class GetCashSheetDetailSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetailSuccess;
  constructor(public payload: { data: TCashSheet }) {}
}

export class GetCashSheetDetailError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetailError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetCashSheetDetailSearch implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetailSearch;
  constructor(public payload: { storeId: string }) {}
}

export class GetCashSheetDetailSearchSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetailSearchSuccess;
  constructor(public payload: { data: TCashSheetSearchResults }) {}
}

export class GetCashSheetDetailSearchError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.GetCashSheetDetailSearchError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetCashSheetQuery implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.SetCashSheetQuery;
  constructor(public payload: { query: TCashSheetQuery; storeId: string }) {}
}

export class SetCashSheetSearch implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.SetCashSheetSearch;
  constructor(public payload: { search: TSearchCashSheetQuery; storeId: string }) {}
}

export class ClearCashSheetSearch implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.ClearCashSheetSearch;
}

export class SetActiveCell implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.SetActiveCell;
  constructor(public payload: { activeCell: TActiveCellInformation }) {}
}

export class ClearActiveCell implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.ClearActiveCell;
  constructor(public payload: { activeCell: TActiveCellInformation }) {}
}

export class AddNewRow implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.AddNewRow;
}

export class DeleteRow implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteRow;
}
export class UpdateCashSheet implements Action<CashSheetsActionType>, IUpdateCashSheetAction {
  readonly type = CashSheetsActionType.UpdateCashSheet;
  constructor(public payload: { cashSheetId: string; storeId: string; values: TCashSheetForm }) {}
}

export class UpdateCashSheetSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateCashSheetSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateCashSheetError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateCashSheetError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateSale implements Action<CashSheetsActionType>, IUpdateCashSheetAction {
  readonly type = CashSheetsActionType.UpdateSale;
  constructor(public payload: { cashSheetId: string; saleId: string; storeId: string; values: TSaleForm }) {}
}

export class UpdateSaleSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateSaleSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateSaleError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateSaleError;
  constructor(public payload: { error: ApiError }) {}
}

export class DeleteSale implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteSale;
  constructor(public payload: { cashSheetId: string; saleId: string; storeId: string }) {}
}

export class DeleteSaleSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteSaleSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class DeleteSaleError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteSaleError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateDecorationRevenue implements Action<CashSheetsActionType>, IUpdateCashSheetAction {
  readonly type = CashSheetsActionType.UpdateDecorationRevenue;
  constructor(public payload: { cashSheetId: string; decorationRevenueId: string; storeId: string; values: TDecorationForm }) {}
}

export class UpdateDecorationRevenueSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateDecorationRevenueSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateDecorationRevenueError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateDecorationRevenueError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateDelivery implements Action<CashSheetsActionType>, IUpdateCashSheetAction {
  readonly type = CashSheetsActionType.UpdateDelivery;
  constructor(public payload: { cashSheetId: string; deliveryId: string; storeId: string; values: TDeliveryForm }) {}
}

export class UpdateDeliverySuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateDeliverySuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateDeliveryError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.UpdateDeliveryError;
  constructor(public payload: { error: ApiError }) {}
}

export class DeleteDelivery implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteDelivery;
  constructor(public payload: { cashSheetId: string; deliveryId: string; storeId: string }) {}
}

export class DeleteDeliverySuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteDeliverySuccess;
  constructor(public payload: { storeId: string }) {}
}

export class DeleteDeliveryError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.DeleteDeliveryError;
  constructor(public payload: { error: ApiError }) {}
}
export class CreateCashSheet implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.CreateCashSheet;
  constructor(public payload: { storeId: string; values: TCashSheetForm }) {}
}

export class CreateCashSheetSuccess implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.CreateCashSheetSuccess;
  constructor(public payload: { data: TCashSheet }) {}
}

export class CreateCashSheetError implements Action<CashSheetsActionType> {
  readonly type = CashSheetsActionType.CreateCashSheetError;
  constructor(public payload: { error: ApiError }) {}
}

export type CashSheetsAction =
  | GetCashSheetDetail
  | GetCashSheetDetailError
  | GetCashSheetDetailSuccess
  | GetCashSheetDetailSearch
  | GetCashSheetDetailSearchError
  | GetCashSheetDetailSearchSuccess
  | SetCashSheetQuery
  | SetCashSheetSearch
  | ClearCashSheetSearch
  | SetActiveCell
  | ClearActiveCell
  | AddNewRow
  | DeleteRow
  | UpdateCashSheet
  | UpdateCashSheetError
  | UpdateCashSheetSuccess
  | CreateCashSheet
  | CreateCashSheetError
  | CreateCashSheetSuccess
  | UpdateSale
  | UpdateSaleError
  | UpdateSaleSuccess
  | UpdateDelivery
  | UpdateDeliveryError
  | UpdateDeliverySuccess
  | UpdateDecorationRevenue
  | UpdateDecorationRevenueError
  | UpdateDecorationRevenueSuccess;
