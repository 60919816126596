import React, { FC, useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { productsActions } from '../../_store/actions';
import { translations } from '../../_translations';
import { SearchField, Button, Icon, TabMenu, Checkbox } from '../../_shared';
import { codesSelectors, productsSelectors, profileSelectors, storesSelectors } from '../../_store/selectors';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import { Code } from '../../codes/_models';
import { hasViewPermissions } from '../../profile/_utils';
import { ItemState } from '../../_utils/stateHelper';
import { useModal, useToggle } from '../../_hooks';
import CreateProductModal from '../create/CreateProductModal';
import { errorAsString } from '../../_utils/errorHelper';
import ProductsTable from './ProductsTable';

import './productsoverview.scss';

type TProps = {
  storeId: string;
};

const ProductsOverview: FC<TProps> = ({ storeId }) => {
  const [isActive, setIsActive] = useToggle(true);
  const [readOnly, toggleReadOnly] = useToggle(true);
  const [isSaved, setIsSaved] = useToggle(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const profile = useSelector(profileSelectors.profile);
  const products = useSelector(productsSelectors.products);
  const productsMetadata = useSelector(productsSelectors.metadata);
  const query = useSelector(productsSelectors.query);
  const isLoading = useSelector(productsSelectors.isGetProductsLoading);
  const currentStore = useSelector(storesSelectors.currentStore);
  const codes = useSelector(codesSelectors.codes);
  const dispatch = useDispatch();

  const hasTotalPricePermission = hasViewPermissions(codes, Code.TotalPrice, currentStore?.id, profile.permissions);

  const closeError = () => dispatch(new productsActions.UpdateProductError({ error: null }));
  const [renderProductModal, showProductModal] = useModal(
    modalProps => <CreateProductModal {...modalProps} errorAsString={errorAsString} storeId={storeId} />,
    null,
    closeError,
  );

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new productsActions.SetProductsQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useEffect(() => {
    setQuery({ skip: 0, state: isActive ? ItemState.Active : ItemState.Inactive, take: isActive ? 1000 : 200 });
  }, [storeId]);

  function showActiveProducts(checked: boolean) {
    setQuery({ state: checked ? ItemState.Active : ItemState.Inactive, take: checked ? 1000 : 200 });
    setIsActive(checked);
  }

  function onSaveSuccess(): void {
    clearTimeout(timer);
    setIsSaved(true);
    setTimer(setTimeout(() => setIsSaved(false), 3000));
  }

  const menuItems = [
    {
      isChecked: isActive,
      onClick: () => showActiveProducts(true),
      text: translations.getLabel('PRODUCTS.OVERVIEW.SHOW_ACTIVE'),
    },
    {
      isChecked: !isActive,
      onClick: () => showActiveProducts(false),
      text: translations.getLabel('PRODUCTS.OVERVIEW.SHOW_INACTIVE'),
    },
  ];

  const downloadButton = (
    <Button
      negative
      onClick={() =>
        dispatch(
          new productsActions.ExportProducts({
            ids: { store: storeId },
            state: isActive ? ItemState.Active : ItemState.Inactive,
          }),
        )
      }
    >
      <Icon name="SvgDownload" />
      <span>{translations.getLabel('SHARED.BUTTONS.DOWNLOAD')}</span>
    </Button>
  );

  function renderControlButtons() {
    if (currentStore.isWarehouse) {
      return (
        <>
          <Checkbox
            checked={!readOnly}
            className="multiple-products-edit"
            label={translations.getLabel('PRODUCTS.OVERVIEW.TOGGLE_BULK_EDIT')}
            name="readonly"
            onChange={() => toggleReadOnly()}
            toggle
          />
          {downloadButton}
          <Button onClick={showProductModal} primary>
            <Icon name="SvgAdd" />
            <span>{translations.getLabel('PRODUCTS.OVERVIEW.CREATE_PRODUCT')}</span>
          </Button>
        </>
      );
    }
    return downloadButton;
  }

  return (
    <Container as="main" className="products">
      <div className="header">
        <h4 className="overview-title">{translations.getLabel('PRODUCTS.OVERVIEW.TITLE')}</h4>
        <span className={classnames('changes-saved-wrapper', { active: isSaved })}>
          <Icon name="SvgCheck" size={1.8} />
          <p>{translations.getLabel('STOCKITEMS.OVERVIEW.CHANGES_SAVED')}</p>
        </span>
        {profile.isAdmin && (
          <div className="actions">
            {renderProductModal()}
            {renderControlButtons()}
          </div>
        )}
      </div>

      <div className="filter-wrapper">
        <div className="queries">
          <SearchField query={query} setQuery={setQuery} />
          <TabMenu menuItems={menuItems} />
        </div>
        <div className="legend-totals">
          <span className="legend-wrapper">
            <p>{translations.getLabel('PRODUCTS.OVERVIEW.LEGEND')}</p>
            <span className="legend-wrapper__items">
              <p>{translations.getLabel('PRODUCTS.OVERVIEW.LEGEND_PURPLE')}</p>
              <p>{translations.getLabel('PRODUCTS.OVERVIEW.LEGEND_RED')}</p>
              <p>{translations.getLabel('PRODUCTS.OVERVIEW.LEGEND_PINK')}</p>
            </span>
          </span>
          {hasTotalPricePermission && (
            <div className="purchase-total">
              {translations.getLabel('PRODUCTS.TABLE.PURCHASE_TOTAL')}:{' '}
              <span>
                {translations.I18n.toNumber(productsMetadata?.totalPrice || 0, {
                  delimiter: '.',
                  precision: 2,
                  separator: ',',
                  strip_insignificant_zeros: true, // eslint-disable-line @typescript-eslint/camelcase
                })}
              </span>
            </div>
          )}
        </div>
      </div>

      <ProductsTable
        data={products}
        errorAsString={errorAsString}
        isLoading={isLoading}
        onSaveSuccess={onSaveSuccess}
        readOnly={readOnly}
        setQuery={setQuery}
      />
    </Container>
  );
};

export default ProductsOverview;
