import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IUserForm } from '../_models';
import { InputField, Button, ErrorMessage, Dropdown, Checkbox, Icon } from '../../_shared';
import { useForm, useModal } from '../../_hooks';
import { translations } from '../../_translations';
import { ApiError } from '../../_http';
import { FormValidationErrors, SubmitFormFunction } from '../../_hooks/useForm';
import { formValidator } from '../../_utils/formValidation';
import { storesSelectors } from '../../_store/selectors';
import { storesActions } from '../../_store/actions';
import PermissionsField from '../permissions/PermissionsField';
import { DropdownOption } from '../../_shared/input/dropdown/Dropdown';
import { Actions, setIcon, IAssignPermissionsRequest } from '../../profile/_models';
import { errorAsString } from '../../_utils/errorHelper';
import UserDeleteModal from '../delete/UserDeleteModal';

import './UserForm.scss';

type Props = {
  buttons?: JSX.Element | JSX.Element[];
  error?: ApiError;
  initialForm: IUserForm;
  isCreate?: boolean;
  isSubmitting?: boolean;
  submitForm: SubmitFormFunction<IUserForm>;
  userId?: string;
};

type IUserFormErrors = IUserForm & {
  roleIds?: string;
};

const UserForm: FC<Props> = ({ userId, initialForm, isCreate, submitForm, isSubmitting, error, buttons }) => {
  function validateForm(values: IUserForm): FormValidationErrors<IUserFormErrors> {
    const errors: FormValidationErrors<IUserFormErrors> = {};
    if (values.email) errors.email = formValidator.email(values.email).error;
    else if (!userId) errors.email = formValidator.required(values.email).error;
    if (!values.firstName) errors.firstName = formValidator.required(values.firstName).error;
    if (!values.lastName) errors.lastName = formValidator.required(values.lastName).error;
    if (!values.defaultStoreId) errors.defaultStoreId = formValidator.required(values.defaultStoreId).error;
    return errors;
  }

  const [renderUserDeleteModal, showUserDeleteModal] = useModal(modalProps => (
    <UserDeleteModal {...modalProps} userId={userId} />
  ));

  const dispatch = useDispatch();
  const adminStores = useSelector(storesSelectors.activeAdminStores);
  const form = useForm<IUserForm, IUserFormErrors>({ error, initialForm, submitForm, validateForm });

  useEffect(() => {
    dispatch(new storesActions.GetAdminStores());
  }, []);

  const errorMessage = errorAsString(error);

  const updateDefaultStoreIdAndStores = (value: string, name: string) => {
    form.setValues(values => {
      values[name] = value;
      if (!values.stores.includes(value)) values.stores = [...values.stores, value];
    });
  };

  const updateStores = (value: string, checked: boolean, name: string) => {
    if (checked) {
      form.setAttribute([...form.values.stores, value], name);
    } else {
      form.setAttribute(
        form.values.stores.filter(_store => _store !== value),
        name,
      );
    }
  };

  const updateAdminRights = (value: string) => {
    //todo add warning if user is changing their own admin rights in update user
    if (value === Actions.Edit) form.setAttribute(true, 'isAdmin');
    else form.setAttribute(false, 'isAdmin');
  };

  const updatePermissions = (value: IAssignPermissionsRequest[]) => {
    form.setAttribute(value, 'permissions');
  };

  const possibleActions: string[] = [Actions.Edit, Actions.NoAccess];
  const permissionOptions: DropdownOption[] = possibleActions.map(action => ({
    icon: setIcon(action),
    text: translations.getLabel(`USERS.CREATE.PERMISSIONS.${action.toUpperCase()}`),
    value: action,
  }));
  let chosenAction: string | string[];
  if (form.values.isAdmin) {
    chosenAction = Actions.Edit;
  } else chosenAction = Actions.NoAccess;

  return (
    <form className="userForm" noValidate onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      <div className="row">
        <div className="column">
          <InputField
            className="bold"
            errorMessage={form.validationErrors.firstName}
            label={translations.getLabel('USERS.FIRST_NAME')}
            name="firstName"
            onChange={form.setAttribute}
            required
            type="text"
            value={form.values.firstName}
          />
          <InputField
            className="bold"
            errorMessage={form.validationErrors.lastName}
            label={translations.getLabel('USERS.LAST_NAME')}
            name="lastName"
            onChange={form.setAttribute}
            required
            type="text"
            value={form.values.lastName}
          />
          <InputField
            className="bold"
            errorMessage={form.validationErrors.email}
            label={translations.getLabel('USERS.EMAIL')}
            name="email"
            onChange={form.setAttribute}
            required
            type="email"
            value={form.values.email}
          />

          {!!adminStores && (
            <>
              <Dropdown
                className="bold"
                errorMessage={form.validationErrors.defaultStoreId}
                label={translations.getLabel('USERS.CREATE.DEFAULT_STORE')}
                name="defaultStoreId"
                onChange={(value, name) => updateDefaultStoreIdAndStores(value.toString(), name)}
                options={adminStores.map(store => ({
                  text: `${store.code} - ${store.name}`,
                  value: store.id,
                }))}
                required
                value={form.values.defaultStoreId}
              />

              <div className="marked store-access">
                <div className="titel">
                  <label>{translations.getLabel('USERS.CREATE.ACCESS')}</label>
                </div>
                <div className="items">
                  {adminStores.map(store => {
                    return (
                      <Checkbox
                        checked={form.values.stores.includes(store.id)}
                        disabled={form.values.defaultStoreId === store.id}
                        key={store.id}
                        label={store.name}
                        name="stores"
                        onChange={(checked, name) => updateStores(store.id, checked, name)}
                        type="checkbox"
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="column">
          {!!adminStores && (
            <>
              <PermissionsField
                formPermissions={form.values.permissions}
                stores={form.values.stores}
                updatePermissions={updatePermissions}
              />
              <div className="marked admin-rights">
                <div className="titel">
                  <label>{translations.getLabel('USERS.CREATE.ADMIN_EDIT')}</label>
                </div>
                <p>{translations.getLabel('USERS.CREATE.ADMIN_WARNING')}</p>
                <div className="items" role="group">
                  <label>{translations.getLabel('USERS.CREATE.ADMIN')}</label>
                  <Dropdown name="admin_rights" onChange={updateAdminRights} options={permissionOptions} value={chosenAction} />
                </div>
              </div>
            </>
          )}
          {!isCreate && (
            <Button asText className="delete-button" negative onClick={showUserDeleteModal}>
              <Icon className="negative" name="SvgTrash" />
              {translations.getLabel('SHARED.BUTTONS.DELETE')}
            </Button>
          )}
        </div>
      </div>
      <div className="actions">
        {buttons}
        <div className="action-buttons">
          <Button asText href="/management/users">
            {translations.getLabel('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button loading={isSubmitting} primary type="submit">
            {translations.getLabel('SHARED.BUTTONS.SAVE')}
          </Button>
        </div>
      </div>
      {renderUserDeleteModal()}
    </form>
  );
};

export default UserForm;
