import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TabMenu } from '../../_shared';
import { translations } from '../../_translations';
import './ledgerMenu.scss';

const LedgerMenu: React.FC = () => {
  const { url } = useRouteMatch();
  const strippedUrl = url.substring(0, url.lastIndexOf('/'));
  const menuItems = [
    {
      path: `${strippedUrl}/other-furniture`,
      text: translations.getLabel('LEDGER.MENU.OTHER_FURNITURE'),
    },
    {
      path: `${strippedUrl}/decoration`,
      text: translations.getLabel('LEDGER.MENU.DECORATION'),
    },
    {
      path: `${strippedUrl}/revenue-per-seller`,
      text: translations.getLabel('LEDGER.MENU.REVENUE_PER_SELLER'),
    },
    {
      path: `${strippedUrl}/to-bank`,
      text: translations.getLabel('LEDGER.MENU.TO_BANK'),
    },
  ];
  return (
    <div className="ledger-menu">
      <TabMenu menuItems={menuItems} />
    </div>
  );
};

export default LedgerMenu;
