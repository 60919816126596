import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translations } from '../../_translations';
import { pricesSelectors } from '../../_store/selectors';
import { Modal, Button, ErrorMessage } from '../../_shared';
import { ApiError } from '../../_http';
import { StockItem } from '../../stock/_models';
import { IPricesForm, PricesRequest } from '../_models';
import { pricesActions } from '../../_store/actions';
import { calculateGrossPrice } from '../_utils';
import PriceForm from './form/PricesForm';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
  stockItem?: StockItem;
};

const PricesEditModal: FC<Props> = ({ closeModal, errorAsString, stockItem }) => {
  const isLoading = useSelector(pricesSelectors.isUpdatePriceLoading);
  const error = useSelector(pricesSelectors.errorCrudPrices);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  const initialForm: IPricesForm = {
    code: stockItem.product.code,
    description: stockItem.product.price.description,
    grossPrice: calculateGrossPrice(stockItem.product.price.sellingPrice),
    sellingPrice: `${stockItem.product.price.sellingPrice || ''}`,
    title: stockItem.product.price.title || '',
  };

  function submitForm({ title, description }: IPricesForm) {
    const requestBody: PricesRequest = {
      description,
      title,
    };

    const ids = {
      price: stockItem.product.price.id,
      stockItem: stockItem.id,
      store: stockItem.store.id,
    };

    dispatch(new pricesActions.UpdatePrice({ ids, onSuccess: closeModal, showToaster: true, values: requestBody }));
  }

  return (
    <Modal className="prices-modal" onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('PRICES.EDIT.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <PriceForm
          buttons={
            <>
              <Button asText onClick={closeModal}>
                {translations.getLabel('SHARED.BUTTONS.CANCEL')}
              </Button>
            </>
          }
          error={error}
          initialForm={initialForm}
          isSubmitting={isLoading}
          submitForm={(values: IPricesForm) => submitForm(values)}
        />
      </Modal.Content>
    </Modal>
  );
};
export default PricesEditModal;
