import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { TDecoration, TDelivery, TSale } from '../_models';
import { CashSheetsState } from './reducer';

const selectNode = (state: AppState) => state.cashSheets;

export const detail = createSelector(selectNode, (state: CashSheetsState) =>
  state.query.date === state.detail?.date ? state.detail : null,
);
export const searchResults = createSelector(selectNode, (state: CashSheetsState) => state.searchResults);
export const query = createSelector(selectNode, (state: CashSheetsState) => state.query);
export const activeCell = createSelector(selectNode, (state: CashSheetsState) => state.activeCell);
export const activeObject = createSelector(selectNode, (state: CashSheetsState): TSale | TDelivery | TDecoration => {
  if (!state.activeCell) return null;
  if (state.activeCell.array == 'decorationRevenue') return state.detail?.decorationRevenue;
  return state.detail?.[state.activeCell.array]?.[state.activeCell.index];
});
export const errorCrudCashSheets = createSelector(selectNode, (state: CashSheetsState) => state.errorCrudCashSheets);
export const isGetCashSheetDetailLoading = createSelector(
  selectNode,
  (state: CashSheetsState) => state.isGetCashSheetDetailLoading,
);
export const isSearchLoading = createSelector(selectNode, (state: CashSheetsState) => state.isSearchLoading);
export const isUpdateCasheetLoading = createSelector(selectNode, (state: CashSheetsState) => state.isUpdateCasheetLoading);
export const search = createSelector(selectNode, (state: CashSheetsState) => state.search);
