import React from 'react';
import classnames from 'classnames';
import { translations } from '../../../_translations';
import { parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TRevenuePerSeller } from '../../_models';
import { revenuePerSellerColumns } from '../revenuePerSeller/RevenuePerSeller';
import { BottomBar } from '../../../_shared';
import './revenuePerSellerBottomBar.scss';

type TProps = {
  revenue?: TRevenuePerSeller;
};

const RevenuePerSellerBottomBar: React.FC<TProps> = ({ revenue }) => {
  return (
    <BottomBar>
      <div className="bottom-bar__revenue-table-wrapper">
        <table>
          <colgroup>
            {revenuePerSellerColumns.map(column => (
              <col className={classnames(column.className)} key={column.name} />
            ))}
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={3}>{translations.getLabel('LEDGER.GENERAL.TOTAL')}</td>
              <td colSpan={1}>{parseCurrencyWithPlaceholder(revenue?.totalPrice)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </BottomBar>
  );
};

export default RevenuePerSellerBottomBar;
