import React, { FC } from 'react';
import { Container } from 'semantic-ui-react';
import { translations } from '../../_translations';
import './forbiddenpage.scss';

const ForbiddenPage: FC = () => {
  return (
    <Container as="main" className="forbidden-page">
      <div className="header">
        <h4 className="overview-title">{translations.getLabel('ERRORS.NO_ACCESS')}</h4>
      </div>
      <p>{translations.getLabel('ERRORS.NO_ACCESS_DESCRIPTION')}</p>
    </Container>
  );
};

export default ForbiddenPage;
