import React from 'react';
import { NavLink } from 'react-router-dom';
import { SvgLogo } from '../../_assets/svg';
import './logoTop.scss';

const LogoTop = () => {
  return (
    <div className="logobox">
      <NavLink to="/">
        <SvgLogo />
      </NavLink>
    </div>
  );
};

export default LogoTop;
