import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { translations } from '../../_translations';
import { storesSelectors } from '../../_store/selectors';
import { storesActions } from '../../_store/actions';
import { Modal, Button, ErrorMessage } from '../../_shared';
import StoreForm from '../edit/StoreForm';
import { IAdminStore } from '../_models';
import { ApiError } from '../../_http';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
  storeId?: string;
};

const EditStoreModal: FC<Props> = ({ closeModal, storeId, errorAsString }) => {
  const store = useSelector(storesSelectors.store(storeId));
  const isUpdateStoreLoading = useSelector(storesSelectors.isUpdateStoreLoading);
  const error = useSelector(storesSelectors.errorCrudStores);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  if (!store && error) return <Redirect to="/management/stores" />;

  return (
    <Modal onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('MANAGEMENT.STORES.EDIT_STORE.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <StoreForm
          buttons={
            <Button asText onClick={closeModal}>
              {translations.getLabel('SHARED.BUTTONS.CANCEL')}
            </Button>
          }
          error={error}
          initialForm={store}
          isSubmitting={isUpdateStoreLoading}
          submitForm={(values: IAdminStore) =>
            dispatch(new storesActions.UpdateStore({ onSuccess: closeModal, storeId, values }))
          }
        />
      </Modal.Content>
    </Modal>
  );
};

export default EditStoreModal;
