import { translations } from '../_translations';

export enum ItemState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export function labelForItemState(state: ItemState): string {
  return translations.getLabel(`CODES.STATE.OPTIONS.${state}`);
}
