import React, { FC } from 'react';
import { Container } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '../../_translations';
import { TabMenu } from '../../_shared';
import { codesSelectors } from '../../_store/selectors';
import { codesActions } from '../../_store/actions';
import { HttpMetadataQuery, FillMetadataQueryFunction } from '../../_http';
import { menuItems } from '../../users/_utils';
import { errorAsString } from '../../_utils/errorHelper';
import CodesTable from './CodesTable';
import './CodesOverview.scss';

const CodesOverview: FC = () => {
  const codes = useSelector(codesSelectors.codes);
  const isLoading = useSelector(codesSelectors.isGetCodesLoading);
  const query = useSelector(codesSelectors.query);
  const dispatch = useDispatch();

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new codesActions.SetCodesQuery({ query: { ...query, ...partialQuery } }));
  };

  return (
    <Container as="main" className="codes">
      <div className="header">
        <h4 className="overview-title">{translations.getLabel('MANAGEMENT.MENU.CODES')}</h4>
        <TabMenu menuItems={menuItems} />
        <div className="spacer" />
      </div>
      <CodesTable data={codes} errorAsString={errorAsString} isLoading={isLoading} setQuery={setQuery} />
    </Container>
  );
};

export default CodesOverview;
