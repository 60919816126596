import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import Datepicker from '../../datepicker/Datepicker';
import { formatDate, dateFromString } from '../../../_utils/timeHelpers';
import Icon from '../../icon/Icon';
import { translations } from '../../../_translations';
import InputField, { InputFieldProps } from '../inputField/InputField';
import './inputFieldDate.scss';

type TProps = InputFieldProps & {
  dateFormat?: string;
  maxDate?: Date;
  minDate?: Date;
  showMonthYearPicker?: boolean;
  showYearDropdown?: boolean;
  showYearPicker?: boolean;
};

const InputFieldDate: FC<TProps> = props => {
  const {
    label,
    maxDate,
    minDate,
    name,
    showMonthYearPicker,
    dateFormat = 'yyyy-MM',
    showYearDropdown,
    showYearPicker,
    onChange,
  } = props;

  const [value, setValue] = useState<string>(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div className={classnames('input-date-wrapper', { 'has-label': !!label })}>
      <InputField
        {...props}
        onBlur={() => {
          if (!dateFromString(value, dateFormat)) return;
          onChange(value, name);
        }}
        onChange={value => setValue(value)}
        placeholder={translations.getLabel('SHARED.PLACEHOLDER.DATE')}
        type="text"
        value={value}
      />
      <Datepicker
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        onChange={(date: Date) => onChange(formatDate(date, dateFormat), name)}
        selected={dateFromString(value, dateFormat)}
        showMonthYearPicker={showMonthYearPicker}
        showYearDropdown={showYearDropdown}
        showYearPicker={showYearPicker}
        trigger={
          <button className="plain datepicker-icon" type="button">
            <Icon name="SvgCalendar" size={2.4} />
          </button>
        }
        yearItemNumber={9}
      />
    </div>
  );
};

export default InputFieldDate;
