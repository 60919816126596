import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../_shared';
import { StockItem } from '../../_models';
import { stockActions } from '../../../_store/actions';
import { codesSelectors, profileSelectors, storesSelectors } from '../../../_store/selectors';
import { Code } from '../../../codes/_models';
import { hasEditPermissions, hasViewPermissions } from '../../../profile/_utils';

type Props = {
  className: string;
  saveChanges: () => void;
  stockItem: StockItem;
};

const StockTableRow: FC<Props> = ({ saveChanges, stockItem, className }) => {
  const [addition, setAddition] = useState<string>('');
  const [zone, setZone] = useState(stockItem.zone);

  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const permissions = useSelector(profileSelectors.permissions);
  const codes = useSelector(codesSelectors.codes);

  const hasEditPermission = hasEditPermissions(codes, Code.Stock, currentStore?.id, permissions);
  const hasPurchasePricePermission = hasViewPermissions(codes, Code.PurchasePrice, currentStore?.id, permissions);
  const hasSellingPricePermission = hasViewPermissions(codes, Code.SellPrice, currentStore?.id, permissions);

  function updateStock(): void {
    if (addition !== '' || zone !== stockItem.zone) {
      let nAddition = 0;
      if (addition) {
        nAddition = parseFloat(addition);
      }
      dispatch(
        new stockActions.UpdateStockItem({
          ids: {
            stock: stockItem.id,
            store: stockItem.store.id,
          },
          onSuccess: saveChanges,
          values: {
            addition: nAddition,
            zone,
          },
        }),
      );
      setAddition('');
    }
  }

  return (
    <Table.Row className={className} key={stockItem.id} onBlur={() => updateStock()}>
      <Table.Cell>{stockItem.product.code || '-'}</Table.Cell>
      <Table.Cell>{stockItem.product.description || '-'}</Table.Cell>
      <Table.Cell>{stockItem.product.remark || '-'}</Table.Cell>
      {hasPurchasePricePermission && (
        <Table.Cell textAlign="center">{`${stockItem.product.price.purchasePrice}` || '-'}</Table.Cell>
      )}
      {hasSellingPricePermission && (
        <Table.Cell textAlign="center">{`${stockItem.product.price.sellingPrice}` || '-'}</Table.Cell>
      )}
      <Table.Cell textAlign="center">{stockItem.value || '-'}</Table.Cell>
      <Table.Cell>
        {hasEditPermission ? <input onChange={addition => setAddition(addition.target.value)} value={addition} /> : addition}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {!addition ? stockItem.value : stockItem.value + (isNaN(parseFloat(addition)) ? 0 : parseFloat(addition))}
      </Table.Cell>
      <Table.Cell>
        {hasEditPermission && currentStore.isWarehouse ? (
          <input defaultValue={zone} onChange={e => setZone(e.target.value)} type="string" />
        ) : (
          zone
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default StockTableRow;
