import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Table, { TableColumn } from '../../../_shared/table/Table';
import { FillMetadataQueryFunction, HttpSortDirection } from '../../../_http';
import { translations } from '../../../_translations';
import { useTableSort, useInfiniteScroll } from '../../../_hooks';
import { StockChange } from '../../_models';
import { labelForStockChangeState } from '../../_utils';
import { dateFromISOString, formatDate } from '../../../_utils/timeHelpers';
import { stockSelectors } from '../../../_store/selectors';

import './stockchange-table.scss';

type Props = {
  isLoading: boolean;
  setQuery: FillMetadataQueryFunction;
  storeId: string;
};

const columns: TableColumn[] = [
  { label: 'STOCKITEMS.TABLE.CODE', name: 'product.code', sortable: true },
  { label: 'STOCKITEMS.TABLE.CHANGE', name: 'stock-change.value', sortable: true },
  { label: 'STOCKITEMS.TABLE.DATE', name: 'stock-change.updatedAt', sortable: true },
  { label: 'STOCKITEMS.TABLE.TYPE', name: 'stock-change.type', sortable: true },
  { label: 'STOCKITEMS.TABLE.USER', name: 'stock-change.updatedBy', sortable: true },
];

const StockChangeTable: FC<Props> = ({ isLoading, setQuery }) => {
  const metadata = useSelector(stockSelectors.stockChangeMetadata);
  const stockChange = useSelector(stockSelectors.stockChange);

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  function renderRow(stockChange: StockChange, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });
    return (
      <Table.Row className={className} key={stockChange.id}>
        <Table.Cell>{stockChange.stockItem.product.code}</Table.Cell>
        <Table.Cell>{stockChange.value}</Table.Cell>
        <Table.Cell>{formatDate(dateFromISOString(stockChange.updatedAt))}</Table.Cell>
        <Table.Cell>{labelForStockChangeState(stockChange.type)}</Table.Cell>
        <Table.Cell>{stockChange.updatedBy}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table
      columns={columns}
      data={stockChange}
      emptyLabel={translations.getLabel('STOCKITEMS.TABLE.EMPTY')}
      fixed={false}
      isLoading={isLoading}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default StockChangeTable;
