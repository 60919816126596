import React from 'react';
import { ICode } from '../../codes/_models';
import { IStore } from '../../management/_models';
import { IUser } from '../../users/_models';
import { SvgEdit, SvgView, SvgNoAccess } from '../../_assets/svg';

export type IPermissions = {
  permission: IPermission;
  role: ICode;
  store: IStore;
  user: IUser;
};

export type IAssignPermissionsRequest = {
  permission: IPermission;
  roleId: string;
  storeId: string;
};

export type IPermission = {
  edit: boolean;
  view: boolean;
};

export enum Actions {
  Edit = 'edit',
  NoAccess = 'no_access',
  View = 'view',
}

export function updatePermission(action: string) {
  switch (action) {
    case Actions.Edit:
      return {
        edit: true,
        view: true,
      };
    case Actions.View:
      return {
        edit: false,
        view: true,
      };
    case Actions.NoAccess:
      return {
        edit: false,
        view: false,
      };
  }
}

export function setCurrentPermissionType(permission: IPermission) {
  if (!permission) return Actions.NoAccess;
  if (permission.edit) {
    return Actions.Edit;
  } else if (permission.view) {
    return Actions.View;
  } else return Actions.NoAccess;
}

export function setIcon(value: string) {
  switch (value) {
    case Actions.Edit:
      return <SvgEdit />;
    case Actions.View:
      return <SvgView />;
    case Actions.NoAccess:
      return <SvgNoAccess />;
  }
}
