import { Action } from 'redux';
import { ApiError } from '../../_http';
import {
  TBankTransfers,
  TDecorationForm,
  TLedgerActiveCellInformation,
  TRevenuePerSeller,
  TOtherFurnitureForm,
} from '../_models';
import { TDecorationRevenueQuery, TDecorationRevenues } from '../_models/Decoration';
import { TOtherFurniture, TOtherFurnitureQuery } from '../_models/OtherFurniture';

export enum LedgerActionType {
  ClearActiveCell = '[Ledger] ClearActiveCell',
  GetLedgerBankTransfers = '[Ledger] GetLedgerBankTransfers',
  GetLedgerBankTransfersError = '[Ledger] GetLedgerBankTransfersError',
  GetLedgerBankTransfersSuccess = '[Ledger] GetLedgerBankTransfersSuccess',
  GetLedgerDecorationRevenue = '[Ledger] GetLedgerDecorationRevenue',
  GetLedgerDecorationRevenueError = '[Ledger] GetLedgerDecorationRevenueError',
  GetLedgerDecorationRevenueSuccess = '[Ledger] GetLedgerDecorationRevenueSuccess',
  GetLedgerOtherFurniture = '[Ledger] GetLedgerOtherFurniture',
  GetLedgerOtherFurnitureError = '[Ledger] GetLedgerOtherFurnitureError',
  GetLedgerOtherFurnitureSuccess = '[Ledger] GetLedgerOtherFurnitureSuccess',
  GetLedgerRevenuePerSeller = '[Ledger] GetLedgerRevenuePerSeller',
  GetLedgerRevenuePerSellerError = '[Ledger] GetLedgerRevenuePerSellerError',
  GetLedgerRevenuePerSellerSuccess = '[Ledger] GetLedgerRevenuePerSellerSuccess',
  SetActiveCell = '[Ledger] SetActiveCell',
  SetDecorationRevenueQuery = '[Ledger] SetDecorationRevenueQuery',
  SetLedgerMonth = '[Ledger] SetLedgerMonth',
  SetLedgerYear = '[Ledger] SetLedgerYear',
  SetOtherFurnitureQuery = '[Ledger] SetOtherFurnitureQuery',
  UpdateDecorationRevenue = '[Ledger ] UpdateDecorationRevenue',
  UpdateDecorationRevenueError = '[Ledger] UpdateDecorationRevenueError',
  UpdateDecorationRevenueSuccess = '[Ledger] UpdateDecorationRevenueSuccess',
  UpdateOtherFurniture = '[Ledger ] UpdateOtherFurniture',
  UpdateOtherFurnitureError = '[Ledger] UpdateOtherFurnitureError',
  UpdateOtherFurnitureSuccess = '[Ledger] UpdateOtherFurnitureSuccess',
}

export class SetActiveCell implements Action<LedgerActionType> {
  readonly type = LedgerActionType.SetActiveCell;
  constructor(public payload: { activeCell: TLedgerActiveCellInformation }) {}
}

export class ClearActiveCell implements Action<LedgerActionType> {
  readonly type = LedgerActionType.ClearActiveCell;
  constructor(public payload: { activeCell: TLedgerActiveCellInformation }) {}
}

export class UpdateDecorationRevenue implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateDecorationRevenue;
  constructor(public payload: { decorationRevenueId: string; storeId: string; values: TDecorationForm }) {}
}

export class UpdateDecorationRevenueSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateDecorationRevenueSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateDecorationRevenueError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateDecorationRevenueError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateOtherFurniture implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateOtherFurniture;
  constructor(public payload: { saleId: string; storeId: string; values: TOtherFurnitureForm }) {}
}

export class UpdateOtherFurnitureSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateOtherFurnitureSuccess;
  constructor(public payload: { storeId: string }) {}
}

export class UpdateOtherFurnitureError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.UpdateOtherFurnitureError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetLedgerMonth implements Action<LedgerActionType> {
  readonly type = LedgerActionType.SetLedgerMonth;
  constructor(public payload: { month: string; storeId: string }) {}
}

export class SetLedgerYear implements Action<LedgerActionType> {
  readonly type = LedgerActionType.SetLedgerYear;
  constructor(public payload: { storeId: string; year: string }) {}
}

export class SetDecorationRevenueQuery implements Action<LedgerActionType> {
  readonly type = LedgerActionType.SetDecorationRevenueQuery;
  constructor(public payload: { query: TDecorationRevenueQuery; storeId: string }) {}
}
export class SetOtherFurnitureQuery implements Action<LedgerActionType> {
  readonly type = LedgerActionType.SetOtherFurnitureQuery;
  constructor(public payload: { query: TOtherFurnitureQuery; storeId: string }) {}
}

export class GetLedgerBankTransfers implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerBankTransfers;
  constructor(public payload: { storeId: string }) {}
}

export class GetLedgerBankTransfersSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerBankTransfersSuccess;
  constructor(public payload: { data: TBankTransfers }) {}
}

export class GetLedgerBankTransfersError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerBankTransfersError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetLedgerDecorationRevenue implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerDecorationRevenue;
  constructor(public payload: { storeId: string }) {}
}

export class GetLedgerDecorationRevenueSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerDecorationRevenueSuccess;
  constructor(public payload: { data: TDecorationRevenues }) {}
}

export class GetLedgerDecorationRevenueError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerDecorationRevenueError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetLedgerOtherFurniture implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerOtherFurniture;
  constructor(public payload: { storeId: string }) {}
}

export class GetLedgerOtherFurnitureSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerOtherFurnitureSuccess;
  constructor(public payload: { data: TOtherFurniture }) {}
}

export class GetLedgerOtherFurnitureError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerOtherFurnitureError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetLedgerRevenuePerSeller implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerRevenuePerSeller;
  constructor(public payload: { storeId: string }) {}
}

export class GetLedgerRevenuePerSellerSuccess implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerRevenuePerSellerSuccess;
  constructor(public payload: { data: TRevenuePerSeller }) {}
}

export class GetLedgerRevenuePerSellerError implements Action<LedgerActionType> {
  readonly type = LedgerActionType.GetLedgerRevenuePerSellerError;
  constructor(public payload: { error: ApiError }) {}
}

export type LedgerAction =
  | GetLedgerRevenuePerSeller
  | GetLedgerRevenuePerSellerError
  | GetLedgerRevenuePerSellerSuccess
  | GetLedgerBankTransfers
  | GetLedgerBankTransfersError
  | GetLedgerBankTransfersSuccess
  | GetLedgerDecorationRevenue
  | GetLedgerDecorationRevenueError
  | GetLedgerDecorationRevenueSuccess
  | GetLedgerOtherFurniture
  | GetLedgerOtherFurnitureError
  | GetLedgerOtherFurnitureSuccess
  | SetLedgerMonth
  | SetLedgerYear
  | SetDecorationRevenueQuery
  | SetOtherFurnitureQuery
  | UpdateDecorationRevenue
  | UpdateDecorationRevenueError
  | UpdateDecorationRevenueSuccess
  | UpdateOtherFurniture
  | UpdateOtherFurnitureError
  | UpdateOtherFurnitureSuccess
  | SetActiveCell
  | ClearActiveCell;
