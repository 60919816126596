import { Action } from 'redux';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { ICode, ICodeForm } from '../_models';

export enum CodesActionType {
  DeleteCode = '[Codes] DeleteCode',
  DeleteCodeError = '[Codes] DeleteCodeError',
  DeleteCodeSuccess = '[Codes] DeletevSuccess',
  GetCodeDetail = '[Codes] GetCodeDetail',
  GetCodeDetailError = '[Codes] GetCodeDetailError',
  GetCodeDetailSuccess = '[Codes] GetCodeDetailSuccess',
  GetCodes = '[Codes] GetCodes',
  GetCodesError = '[Codes] GetCodesError',
  GetCodesSuccess = '[Codes] GetCodesSuccess',
  SetCodesQuery = '[Codes] SetCodesQuery',
  UpdateCode = '[Codes] UpdateCode',
  UpdateCodeError = '[Codes] UpdateCodeError',
  UpdateCodeSuccess = '[Codes] UpdateCodeSuccess',
}

export class GetCodeDetail implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodeDetail;
  constructor(public payload: { codeId: string }) {}
}

export class GetCodeDetailSuccess implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodeDetailSuccess;
  constructor(public payload: { data: ICode }) {}
}

export class GetCodeDetailError implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodeDetailError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetCodes implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodes;
}

export class GetCodesSuccess implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodesSuccess;
  constructor(public payload: { data: ICode[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetCodesError implements Action<CodesActionType> {
  readonly type = CodesActionType.GetCodesError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetCodesQuery implements Action<CodesActionType> {
  readonly type = CodesActionType.SetCodesQuery;
  constructor(public payload: { query: HttpMetadataQuery }) {}
}

export class UpdateCode implements Action<CodesActionType> {
  readonly type = CodesActionType.UpdateCode;
  constructor(public payload: { codeId: string; onSuccess?: () => void; values: ICodeForm }) {}
}

export class UpdateCodeSuccess implements Action<CodesActionType> {
  readonly type = CodesActionType.UpdateCodeSuccess;
  constructor(public payload: { updatedCode: ICode }) {}
}

export class UpdateCodeError implements Action<CodesActionType> {
  readonly type = CodesActionType.UpdateCodeError;
  constructor(public payload: { error: ApiError }) {}
}

export class DeleteCode implements Action<CodesActionType> {
  readonly type = CodesActionType.DeleteCode;
  constructor(public payload: { code: ICode; confirmed?: boolean }) {}
}

export class DeleteCodeSuccess implements Action<CodesActionType> {
  readonly type = CodesActionType.DeleteCodeSuccess;
  constructor(public payload: { CodeId: string }) {}
}

export class DeleteCodeError implements Action<CodesActionType> {
  readonly type = CodesActionType.DeleteCodeError;
  constructor(public payload: { error: ApiError }) {}
}

export type CodesAction =
  | DeleteCode
  | DeleteCodeError
  | DeleteCodeSuccess
  | GetCodeDetail
  | GetCodeDetailError
  | GetCodeDetailSuccess
  | GetCodes
  | GetCodesError
  | GetCodesSuccess
  | SetCodesQuery
  | UpdateCode
  | UpdateCodeError
  | UpdateCodeSuccess;
