import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { reportsActions } from '../../_store/actions';
import { reportsSelectors } from '../../_store/selectors';
import { downloadFile } from '../../_utils/downloadHelper';
import { ReportsActionType } from './actions';
import * as reportsApi from './api';

export const exportBestsellersEpic$: Epic = action$ =>
  action$.ofType(ReportsActionType.ExportBestsellers).pipe(
    exhaustMap(({ payload }: reportsActions.ExportBestsellers) =>
      from(reportsApi.exportBestsellers(payload.storeId, payload.query)).pipe(
        map((file: Blob) => {
          const date = new Date();

          return new reportsActions.ExportBestsellersSuccess({
            file,
            name: `${date.getTime()}_bestsellers.csv`,
          });
        }),
        catchError(error => of(new reportsActions.ExportBestsellersError({ error }))),
      ),
    ),
  );

export const exportProductsSuccessEpic$: Epic = action$ =>
  action$.ofType(ReportsActionType.ExportBestsellersSuccess).pipe(
    exhaustMap(({ payload }: reportsActions.ExportBestsellersSuccess) => {
      downloadFile(payload.file, payload.name);
      return of();
    }),
  );

const getReportTopTenEpic$: Epic = action$ =>
  action$.ofType(ReportsActionType.GetTopTen).pipe(
    exhaustMap(({ payload }: reportsActions.GetTopTen) => {
      return from(reportsApi.getTopTen(payload.storeId)).pipe(
        map(({ data }) => new reportsActions.GetTopTenSuccess({ data })),
        catchError(error => of(new reportsActions.GetTopTenError({ error }))),
      );
    }),
  );

const getReportBestellers$: Epic = (action$, state$) =>
  action$.ofType(ReportsActionType.GetBestSellers).pipe(
    exhaustMap(({ payload }: reportsActions.GetBestSellers) => {
      const query = reportsSelectors.query(state$.value);
      return from(reportsApi.getBestsellers(payload.storeId, query)).pipe(
        map(({ data, meta }) => new reportsActions.GetBestSellersSuccess({ data, meta })),
        catchError(error => of(new reportsActions.GetBestSellersError({ error }))),
      );
    }),
  );

const setReportsQueryEpic$: Epic = action$ =>
  action$
    .ofType(ReportsActionType.SetReportsQuery)
    .pipe(map(({ payload }): reportsActions.GetBestSellers => new reportsActions.GetBestSellers({ storeId: payload.storeId })));

export default [
  exportBestsellersEpic$,
  exportProductsSuccessEpic$,
  getReportTopTenEpic$,
  getReportBestellers$,
  setReportsQueryEpic$,
];
