import { Action } from 'redux';
import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { StockItem, Ids } from '../../stock/_models';
import { PricesRequest, PricesPrintRequest } from '../_models';

export enum PricesActionType {
  GetPrices = '[Prices] GetPrices',
  GetPricesError = '[Prices] GetPricesError',
  GetPricesSuccess = '[Prices] GetPricesSuccess',
  PrintPrice = '[Prices] PrintPrice',
  PrintPriceError = '[Prices] PrintPriceError',
  PrintPriceSuccess = '[Prices] PrintPriceSuccess',
  SetPricesQuery = '[Prices] SetPricesQuery',
  UpdatePrice = '[Prices] UpdatePrice',
  UpdatePriceError = '[Prices] UpdatePriceError',
  UpdatePriceSuccess = '[Prices] UpdatePriceSuccess',
}

export class GetPrices implements Action<PricesActionType> {
  readonly type = PricesActionType.GetPrices;
  constructor(public payload: { storeId: string }) {}
}

export class GetPricesSuccess implements Action<PricesActionType> {
  readonly type = PricesActionType.GetPricesSuccess;
  constructor(public payload: { data: StockItem[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetPricesError implements Action<PricesActionType> {
  readonly type = PricesActionType.GetPricesError;
  constructor(public payload: { error: ApiError }) {}
}

export class PrintPrice implements Action<PricesActionType> {
  readonly type = PricesActionType.PrintPrice;
  constructor(public payload: { ids: Ids; onSuccess?: () => void; values: PricesPrintRequest }) {}
}

export class PrintPriceSuccess implements Action<PricesActionType> {
  readonly type = PricesActionType.PrintPriceSuccess;
  constructor(public payload: { printPrice: string }) {}
}

export class PrintPriceError implements Action<PricesActionType> {
  readonly type = PricesActionType.PrintPriceError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetPricesQuery implements Action<PricesActionType> {
  readonly type = PricesActionType.SetPricesQuery;
  constructor(public payload: { query: HttpMetadataQuery; storeId: string }) {}
}

export class UpdatePrice implements Action<PricesActionType> {
  readonly type = PricesActionType.UpdatePrice;
  constructor(public payload: { ids: Ids; onSuccess?: () => void; showToaster?: boolean; values: PricesRequest }) {}
}

export class UpdatePriceSuccess implements Action<PricesActionType> {
  readonly type = PricesActionType.UpdatePriceSuccess;
  constructor(public payload: { ids: Ids; values: PricesRequest }) {}
}

export class UpdatePriceError implements Action<PricesActionType> {
  readonly type = PricesActionType.UpdatePriceError;
  constructor(public payload: { error: ApiError }) {}
}

export type PricesAction =
  | GetPrices
  | GetPricesSuccess
  | GetPricesError
  | PrintPrice
  | PrintPriceSuccess
  | PrintPriceError
  | SetPricesQuery
  | UpdatePrice
  | UpdatePriceSuccess
  | UpdatePriceError;
