import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Code } from '../codes/_models';
import SecondaryNav from '../_routing/layouts/authorized/menu/SecondaryNav';
import { codesSelectors, profileSelectors, storesSelectors } from '../_store/selectors';
import { hasViewPermissions } from '../profile/_utils';
import { ForbiddenPage } from '../_shared';
import ProductsOverview from './overview/ProductsOverview';

const Products: React.FC = () => {
  const { url } = useRouteMatch();
  const { storeId } = useParams<{ storeId: string }>();
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);

  const hasViewPermission = hasViewPermissions(codes, Code.Product, currentStore?.id, profile.permissions);

  return (
    <>
      <SecondaryNav />
      <Switch>
        <Route
          exact
          path={url}
          render={props => (hasViewPermission ? <ProductsOverview {...props} storeId={storeId} /> : <ForbiddenPage />)}
        />
      </Switch>
    </>
  );
};

export default Products;
