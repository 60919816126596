import { IAdminStore } from '../_models';
import { HttpMetadataQuery, HttpPagedResponse, HttpClient } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { removeEmptyKeys } from '../../_utils/objectHelpers';

export function getAdminStores(query?: HttpMetadataQuery): Promise<HttpPagedResponse<IAdminStore>> {
  return HttpClient.get<HttpPagedResponse<IAdminStore>>(`stores${getQueryParams(query)}`);
}

export function getStoreDetail(storeId: string): Promise<IAdminStore> {
  return HttpClient.get<IAdminStore>(`stores/${storeId}`);
}

export function createStore(body: IAdminStore): Promise<IAdminStore> {
  return HttpClient.post<IAdminStore>('stores', body);
}

export function updateStore(storeId: string, body: IAdminStore): Promise<IAdminStore> {
  return HttpClient.put<IAdminStore>(`stores/${storeId}`, removeEmptyKeys(body));
}
