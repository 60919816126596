import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../../_shared';
import { translations } from '../../../_translations';
import { cashSheetActions } from '../../../_store/actions';
import { cashSheetsSelectors, storesSelectors } from '../../../_store/selectors';
import { Highlight } from '../../_models';
import './toolbar.scss';

const Toolbar: React.FC = () => {
  const activeCell = useSelector(cashSheetsSelectors.activeCell);
  const cashSheet = useSelector(cashSheetsSelectors.detail);
  const currentStore = useSelector(storesSelectors.currentStore);
  const activeObject = useSelector(cashSheetsSelectors.activeObject);
  const dispatch = useDispatch();

  const updateHighlight = (highlight: Highlight) => {
    const payload = {
      cashSheetId: cashSheet.id,
      storeId: currentStore.id,
      values: { highlight },
    };

    switch (activeCell.array) {
      case 'deliveries':
        return dispatch(new cashSheetActions.UpdateDelivery({ ...payload, deliveryId: activeObject.id }));
      case 'sales':
        return dispatch(new cashSheetActions.UpdateSale({ ...payload, saleId: activeObject.id }));
      case 'decorationRevenue':
        return dispatch(new cashSheetActions.UpdateDecorationRevenue({ ...payload, decorationRevenueId: activeObject.id }));
      default:
        break;
    }
  };

  const deleteRow = () => {
    const payload = {
      cashSheetId: cashSheet.id,
      storeId: currentStore.id,
    };
    if (!activeObject.id) return dispatch(new cashSheetActions.DeleteRow());
    if (activeCell.array === 'deliveries')
      return dispatch(new cashSheetActions.DeleteDelivery({ ...payload, deliveryId: activeObject.id }));
    if (activeCell.array === 'sales') return dispatch(new cashSheetActions.DeleteSale({ ...payload, saleId: activeObject.id }));
  };

  const getHighlightSvg = (highlight: Highlight, color: Highlight) => {
    return (
      <Icon
        className={`toolbar__tools__highlight__${color.toLowerCase()}`}
        clickable
        key={color}
        name={highlight === color ? 'SvgCircleCheck' : 'SvgCircle'}
        onClick={() => updateHighlight(color)}
      />
    );
  };

  const hasMultipleRows = (): boolean => {
    if (activeCell.array === 'decorationRevenue') return false;
    return cashSheet[activeCell?.array]?.length > 1;
  };

  const canAddRow = (): boolean => {
    return activeCell.array !== 'decorationRevenue';
  };

  const canDeleteRow = (): boolean => {
    if (!hasMultipleRows()) return false;
    if (activeCell.array === 'decorationRevenue') return false;
    return true;
  };

  if (!cashSheet) return null;
  if (!activeObject) return null;
  return (
    <div className="toolbar ">
      <div className="toolbar__tools">
        <Button
          className="toolbar__tools__edit"
          disabled={!canDeleteRow()}
          negative
          onClick={() => dispatch(new cashSheetActions.AddNewRow())}
        >
          <Icon name="SvgAddRow" />
          <span>{translations.getLabel('CASH_SHEETS.OVERVIEW.ADD_ROW')}</span>
        </Button>
        <Button className="toolbar__tools__edit" disabled={!canAddRow()} negative onClick={deleteRow}>
          <Icon name="SvgDeleteRow" />
          <span>{translations.getLabel('CASH_SHEETS.OVERVIEW.DELETE_ROW')}</span>
        </Button>
        <div className="toolbar__tools__highlight">
          {[Highlight.None, Highlight.Negative, Highlight.Positive].map(color => getHighlightSvg(activeCell.highlight, color))}
        </div>
      </div>
      <div className="toolbar__cancel">
        <Button
          className="toolbar__tools__edit"
          negative
          onClick={() => {
            dispatch(new cashSheetActions.SetActiveCell({ activeCell: undefined }));
          }}
        >
          <span>{translations.getLabel('SHARED.BUTTONS.CANCEL')}</span>
        </Button>
      </div>
    </div>
  );
};

export default Toolbar;
