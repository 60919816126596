import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, tap, catchError, exhaustMap, switchMap, filter } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { codesActions, modalActions } from '../../_store/actions';
import { codesSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { CodesActionType } from './actions';
import * as codesApi from './api';

const GetCodeDetailEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.GetCodeDetail).pipe(
    exhaustMap(({ payload }: codesActions.GetCodeDetail) => {
      return from(codesApi.getCodeDetail(payload.codeId)).pipe(
        map(data => new codesActions.GetCodeDetailSuccess({ data })),
        catchError(error => of(new codesActions.GetCodeDetailError({ error }))),
      );
    }),
  );

const getCodesEpic$: Epic = (action$, state$) =>
  action$.ofType(CodesActionType.GetCodes).pipe(
    exhaustMap(() => {
      const query = codesSelectors.query(state$.value);
      return from(codesApi.getCodes(query)).pipe(
        map(({ data, meta }) => new codesActions.GetCodesSuccess({ data, meta })),
        catchError(error => of(new codesActions.GetCodesError({ error }))),
      );
    }),
  );

const setCodesQueryEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.SetCodesQuery).pipe(map(() => new codesActions.GetCodes()));

const createDeleteCodeSuccessEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.DeleteCodeSuccess).pipe(switchMap(() => of(push('/management/codes'))));

const updateCodeEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.UpdateCode).pipe(
    exhaustMap(({ payload }: codesActions.UpdateCode) =>
      from(codesApi.updateCode(payload.codeId, payload.values)).pipe(
        tap(() => toast.success(translations.getLabel('CODES.TOASTER.ROLE_UPDATED'))),
        map(updatedCode => {
          payload.onSuccess?.();
          return new codesActions.UpdateCodeSuccess({ updatedCode });
        }),
        catchError(error => of(new codesActions.UpdateCodeError({ error }))),
      ),
    ),
  );

const deleteCodeWithConfirmationEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.DeleteCode).pipe(
    filter(({ payload }: codesActions.DeleteCode) => !payload.confirmed),
    map(({ payload }: codesActions.DeleteCode) => {
      return new modalActions.ShowConfirmationModal({
        confirmAction: () => new codesActions.DeleteCode({ code: payload.code, confirmed: true }),
        confirmText: translations.getLabel('CODES.DELETE.CONFIRM'),
        content: translations.getLabel('CODES.DELETE.CONTENT'),
        title: payload.code.name,
      });
    }),
  );

const deleteCodeEpic$: Epic = action$ =>
  action$.ofType(CodesActionType.DeleteCode).pipe(
    filter(({ payload }: codesActions.DeleteCode) => payload.confirmed),
    exhaustMap(({ payload }: codesActions.DeleteCode) =>
      from(codesApi.deleteCode(payload.code.id)).pipe(
        tap(() => toast.success(translations.getLabel('CODES.TOASTER.ROLE_DELETED'))),
        map(() => new codesActions.DeleteCodeSuccess({ CodeId: payload.code.id })),
        catchError(error => of(new codesActions.DeleteCodeError({ error }))),
      ),
    ),
  );

export default [
  GetCodeDetailEpic$,
  getCodesEpic$,
  setCodesQueryEpic$,
  createDeleteCodeSuccessEpic$,
  updateCodeEpic$,
  deleteCodeEpic$,
  deleteCodeWithConfirmationEpic$,
];
