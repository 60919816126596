import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import CustomInputField, { TInputFieldProps } from '../customInputField/CustomInputField';
import './inputFieldText.scss';

type TProps = Omit<TInputFieldProps, 'value' | 'onChange'> & {
  getDisplayValue?: (value: string) => string;
  mapDisplayValueToEditValue?: (value: string) => string;
  onBlur?: (name: string, value?: string) => void;
  onClick?: (name: string) => void;
  value?: string;
};

const InputFieldText: FC<TProps> = ({
  getDisplayValue = value => value,
  mapDisplayValueToEditValue = value => value,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { value, className, onClick, onBlur, name } = props;
  const [text, setText] = useState<string>();

  useEffect(() => setText(mapDisplayValueToEditValue(value)), [value]);

  function renderDisplayValue() {
    return (
      <div className={classnames('input-text-wrapper__display-value', className)}>
        <span>{getDisplayValue(value)}</span>
      </div>
    );
  }

  function renderInputField() {
    return <CustomInputField {...props} onChange={(text: string) => setText(text)} value={text} />;
  }

  return (
    <div
      className={classnames('input-text-wrapper', { active: isActive, input: !isActive })}
      onBlur={() => {
        setIsActive(false);
        onBlur?.(name, text);
      }}
      onClick={() => {
        if (!isActive) setIsActive(true);
        onClick?.(name);
      }}
    >
      {isActive ? renderInputField() : renderDisplayValue()}
    </div>
  );
};

export default InputFieldText;
