import { ApiError, HttpMetadataQuery, ProductsHttpMetadataPagingResponse } from '../../_http';
import { keepRetrievedDataPage } from '../../_utils/objectHelpers';
import { StockItem } from '../../stock/_models';
import { StoresActionType, StoresAction } from '../../management/_store/actions';
import { ProductsAction, ProductsActionType } from './actions';

export type ProductsState = {
  errorActivate?: ApiError;
  errorCrudProducts?: ApiError;
  errorDeactivateError?: ApiError;
  errorDownloadError?: ApiError;
  isActivateProductLoading?: boolean;
  isCreateProductLoading?: boolean;
  isDeactivateProductLoading?: boolean;
  isDownloading?: boolean;
  isGetProductsLoading?: boolean;
  isUpdateProductsLoading?: boolean;
  metadata?: ProductsHttpMetadataPagingResponse;
  products?: StockItem[];
  query?: HttpMetadataQuery;
};

const initialState: ProductsState = {};

export default function reducer(state = initialState, action: ProductsAction | StoresAction): ProductsState {
  switch (action.type) {
    case StoresActionType.UpdateCurrentStore:
      return {
        ...state,
        metadata: null,
        products: [],
      };
    case ProductsActionType.CreateProduct:
      return {
        ...state,
        errorCrudProducts: null,
        isCreateProductLoading: true,
      };
    case ProductsActionType.CreateProductSuccess:
      return {
        ...state,
        isCreateProductLoading: false,
      };
    case ProductsActionType.CreateProductError:
      return {
        ...state,
        errorCrudProducts: action.payload.error,
        isCreateProductLoading: false,
      };
    case ProductsActionType.DeactivateProduct:
      return {
        ...state,
        errorDeactivateError: null,
        isDeactivateProductLoading: true,
      };
    case ProductsActionType.DeactivateProductSuccess:
      return {
        ...state,
        isDeactivateProductLoading: false,
      };
    case ProductsActionType.DeactivateProductError:
      return {
        ...state,
        errorDeactivateError: action.payload.error,
        isDeactivateProductLoading: false,
      };
    case ProductsActionType.ExportProducts:
      return {
        ...state,
        isDownloading: true,
      };
    case ProductsActionType.ExportProductsSuccess:
      return {
        ...state,
        isDownloading: false,
      };
    case ProductsActionType.ExportProductsError:
      return {
        ...state,
        errorDownloadError: action.payload.error,
        isDownloading: false,
      };
    case ProductsActionType.GetProducts:
      return {
        ...state,
        isGetProductsLoading: true,
      };
    case ProductsActionType.SilentGetProductsSuccess:
    case ProductsActionType.GetProductsSuccess: {
      return {
        ...state,
        isGetProductsLoading: false,
        metadata: action.payload.meta,
        products: keepRetrievedDataPage(state.products, action.payload.data, action.payload.meta),
      };
    }
    case ProductsActionType.GetProductsError:
      return {
        ...state,
        errorCrudProducts: action.payload.error,
        isGetProductsLoading: false,
      };
    case ProductsActionType.SetProductsQuery:
      // if we have switched from state, we need to reset the values
      if (action.payload.query?.state !== state.query?.state) {
        return {
          ...state,
          metadata: null,
          products: [],
          query: {
            ...action.payload.query,
            skip: 0,
          },
        };
      } else {
        return {
          ...state,
          query: action.payload.query,
        };
      }
    case ProductsActionType.UpdateProduct:
      return {
        ...state,
        errorCrudProducts: null,
        isUpdateProductsLoading: true,
      };
    case ProductsActionType.UpdateProductSuccess:
      return {
        ...state,
        isUpdateProductsLoading: false,
        products: state.products.map(product => {
          if (product.product.id === action.payload.data.product.id) {
            return action.payload.data;
          }
          return product;
        }),
      };
    case ProductsActionType.UpdateProductError:
      return {
        ...state,
        errorCrudProducts: action.payload.error,
        isUpdateProductsLoading: false,
      };
    case ProductsActionType.ActivateProduct:
      return {
        ...state,
        errorActivate: null,
        isActivateProductLoading: true,
      };
    case ProductsActionType.ActivateProductSuccess:
      return {
        ...state,
        isActivateProductLoading: false,
      };
    case ProductsActionType.ActivateProductError:
      return {
        ...state,
        errorActivate: action.payload.error,
        isActivateProductLoading: false,
      };
    default:
      return state;
  }
}
