import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiError } from '../../_http';
import { codesSelectors, productsSelectors, profileSelectors, storesSelectors } from '../../_store/selectors';
import { Modal, ErrorMessage, Button } from '../../_shared';
import { translations } from '../../_translations';
import { IProductForm, ICreateProductRequest } from '../_models';
import { productsActions } from '../../_store/actions';
import { useForm } from '../../_hooks';
import { hasEditPermissions } from '../../profile/_utils';
import { Code } from '../../codes/_models';
import ProductForm, { validateForm } from './form/ProductForm';

type Props = {
  closeModal: () => void;
  errorAsString: (error?: ApiError) => string;
  storeId: string;
};

const initialForm: IProductForm = {
  code: '',
  description: '',
  dimensions: '',
  expected: '',
  extraPrice: '0',
  initalStock: '0',
  isChair: false,
  purchasePrice: '0',
  remark: '',
  sellingPrice: '0',
  warehouseStockCount: '0',
  zone: '',
};

// Use "global" var, since we don't want a state trigger when toggled, just an instant changing variable
let isSaveAndRepeat = false;

const CreateProductModal: FC<Props> = ({ closeModal, errorAsString, storeId }) => {
  const isSubmitting = useSelector(productsSelectors.isCreateProductLoading);
  const error = useSelector(productsSelectors.errorCrudProducts);
  const currentStore = useSelector(storesSelectors.currentStore);
  const codes = useSelector(codesSelectors.codes);
  const permissions = useSelector(profileSelectors.permissions);

  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  const hasPurchasePricePermission = hasEditPermissions(codes, Code.PurchasePrice, currentStore?.id, permissions);
  const hasSellingPricePermission = hasEditPermissions(codes, Code.SellPrice, currentStore?.id, permissions);
  const hasExtraPricePermission = hasEditPermissions(codes, Code.ExtraPrice, currentStore?.id, permissions);
  const hasTotalPricePermission = hasEditPermissions(codes, Code.TotalPrice, currentStore?.id, permissions);

  function submitProductForm({
    code,
    description,
    dimensions,
    expected,
    extraPrice,
    initalStock,
    isChair,
    purchasePrice,
    remark,
    sellingPrice,
    zone,
    warehouseStockCount,
  }: IProductForm) {
    const requestBody: ICreateProductRequest = {
      expected,
      product: {
        code,
        description,
        dimensions,
        isChair,
        price: {
          extraPrice: parseFloat(extraPrice),
          purchasePrice: parseFloat(purchasePrice),
          sellingPrice: parseFloat(sellingPrice),
        },
        remark,
        warehouseStockCount: parseInt(warehouseStockCount),
      },
      value: parseInt(initalStock),
      zone,
    };

    const ids = {
      store: storeId,
    };

    if (isSaveAndRepeat) {
      dispatch(new productsActions.CreateProduct({ ids, onSuccess: () => form.clearValues(), values: requestBody })); // eslint-disable-line
    } else {
      dispatch(new productsActions.CreateProduct({ ids, onSuccess: closeModal, values: requestBody }));
    }
  }
  const form = useForm<IProductForm>({ error, initialForm, submitForm: submitProductForm, validateForm });

  const save = () => {
    isSaveAndRepeat = false;
    form.submit(null);
  };
  const saveAndRepeat = () => {
    isSaveAndRepeat = true;
    form.submit(null);
  };

  return (
    <Modal className="product-modal" onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('PRODUCTS.OVERVIEW.CREATE_PRODUCT')}</label>
      </header>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      <Modal.Content>
        <ProductForm
          buttons={
            <div className="actions">
              <Button asText onClick={closeModal}>
                {translations.getLabel('SHARED.BUTTONS.CANCEL')}
              </Button>
              <Button loading={isSubmitting} negative onClick={saveAndRepeat} type="button">
                {translations.getLabel('SHARED.BUTTONS.SAVE_AND_REPEAT')}
              </Button>
              <Button loading={isSubmitting} onClick={save} primary type="button">
                {translations.getLabel('SHARED.BUTTONS.CREATE')}
              </Button>
            </div>
          }
          form={form}
          isWarehouse={currentStore?.isWarehouse}
          showExtraPrice={hasExtraPricePermission}
          showPurchasePrice={hasPurchasePricePermission}
          showSellingPrice={hasSellingPricePermission}
          showWarehouseStockCount={hasTotalPricePermission}
        />
      </Modal.Content>
    </Modal>
  );
};

export default CreateProductModal;
