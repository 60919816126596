import React, { FC, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import { storesSelectors } from '../../_store/selectors';
import { storesActions } from '../../_store/actions';
import { translations } from '../../_translations';
import { Button, Icon, TabMenu } from '../../_shared';
import { useModal } from '../../_hooks';
import { menuItems } from '../../users/_utils';
import { errorAsString } from '../../_utils/errorHelper';
import StoresTable from './StoresTable';
import NewStoreModal from './NewStoreModal';
import './StoresOverview.scss';

const StoresOverview: FC = () => {
  const stores = useSelector(storesSelectors.adminStores);
  const isLoading = useSelector(storesSelectors.isGetAdminStoresLoading);
  const query = useSelector(storesSelectors.query);
  const dispatch = useDispatch();

  const [renderNewStoreModal, showNewStoreModal] = useModal(modalProps => (
    <NewStoreModal {...modalProps} errorAsString={errorAsString} />
  ));

  useEffect(() => {
    dispatch(new storesActions.GetAdminStores());
  }, []);

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new storesActions.SetStoresQuery({ query: { ...query, ...partialQuery } }));
  };

  return (
    <Container as="main" className="stores">
      <div className="header">
        <h4 className="overview-title">{translations.getLabel('MANAGEMENT.MENU.STORES')}</h4>

        <TabMenu menuItems={menuItems} />

        <Button className="overview" onClick={() => showNewStoreModal()} primary>
          <Icon name="SvgAdd" />
          <span>{translations.getLabel('MANAGEMENT.BUTTON.CREATE_STORE')}</span>
        </Button>

        {renderNewStoreModal()}
      </div>

      <StoresTable data={stores} errorAsString={errorAsString} isLoading={isLoading} setQuery={setQuery} />
    </Container>
  );
};

export default StoresOverview;
