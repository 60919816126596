import React, { FC } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import './customDropdown.scss';
import InputWrapper, { InputWrapperProps } from '../../input/InputWrapper';
import { translations } from '../../../_translations';

export type TDropdownOption = {
  label: string;
  value: string;
};

type TProps = InputWrapperProps & {
  multiple?: boolean;
  normalize?: (value: string) => string;
  onChange: (value: string | string[], name: string) => void;
  options: TDropdownOption[] | [];
  placeholder?: string;
  value: string | string[];
};

const CustomDropdown: FC<TProps> = ({
  multiple,
  normalize = (value: string) => value,
  onChange,
  options,
  placeholder,
  value,
  ...wrapperProps
}) => {
  const { disabled, name } = wrapperProps;
  let allOptions: TDropdownOption[] = options || [];
  const containsEmptyOption = value && !multiple;
  if (containsEmptyOption) {
    allOptions = [{ label: translations.getLabel('SHARED.DROPDOWN.PLACEHOLDER'), value: null }, ...allOptions];
  }

  return (
    <InputWrapper {...wrapperProps}>
      <Select
        className={classnames('custom-dropdown', { 'contains-empty-option': containsEmptyOption })}
        classNamePrefix="dropdown"
        isClearable={false}
        isDisabled={disabled}
        isMulti={multiple}
        isSearchable
        name={name}
        noOptionsMessage={() => translations.getLabel('SHARED.DROPDOWN.NO_OPTIONS')}
        onChange={(option: TDropdownOption | TDropdownOption[]) => {
          let normalizedValue = null;
          if (!option && Array.isArray(value)) normalizedValue = [];
          else if (Array.isArray(option)) normalizedValue = option.map(opt => normalize(opt.value));
          else normalizedValue = normalize(option.value);
          onChange(normalizedValue, name);
        }}
        options={allOptions}
        placeholder={placeholder}
        value={allOptions?.filter(option => (Array.isArray(value) ? value.includes(option.value) : option.value === value))}
      />
    </InputWrapper>
  );
};

export default CustomDropdown;
