import React, { FC } from 'react';
import { translations } from '../../../_translations';
import { Button, InputField, ErrorMessage, Dropdown } from '../../../_shared';
import { useForm } from '../../../_hooks';
import { SubmitFormFunction, FormValidationErrors } from '../../../_hooks/useForm';
import { ApiError } from '../../../_http';
import { PricesPrintRequest, PrintFormat } from '../../_models';
import { formValidator } from '../../../_utils/formValidation';
import { errorAsString } from '../../../_utils/errorHelper';
import './print-price-form.scss';

type Props = {
  buttons?: JSX.Element | JSX.Element[];
  error?: ApiError;
  initialForm: PricesPrintRequest;
  isSinglePrint: boolean;
  isSubmitting?: boolean;
  submitForm: SubmitFormFunction<PricesPrintRequest>;
};

function validateForm(values: PricesPrintRequest, isSinglePrint: boolean): FormValidationErrors<PricesPrintRequest> {
  if (isSinglePrint) {
    const stockItem = values.productsToPrint[0];
    return {
      productsToPrint: [
        {
          price: {
            brutoPrice: formValidator.required(stockItem.price.brutoPrice).error,
            description: formValidator.required(stockItem.price.description).error,
            sellingPrice: formValidator.required(stockItem.price.sellingPrice).error,
            title: formValidator.required(stockItem.price.title).error,
          },
          product: {
            code: formValidator.required(stockItem.product.code).error,
            dimensions: formValidator.required(stockItem.product.dimensions).error,
          },
        },
      ],
    };
  }
  return {};
}

const printOptions = [
  {
    text: translations.getLabel('PRICES.PRINT.A6'),
    value: PrintFormat.A6,
  },
  {
    text: translations.getLabel('PRICES.PRINT.A5'),
    value: PrintFormat.A5,
  },
];

const PrintPriceForm: FC<Props> = ({ initialForm, submitForm, isSinglePrint, isSubmitting, error, buttons }) => {
  const form = useForm<PricesPrintRequest>({
    error,
    initialForm,
    submitForm,
    validateForm: values => validateForm(values, isSinglePrint),
  });
  const errorMessage = errorAsString(error);
  const firstItem = form.values.productsToPrint[0];
  const firstItemValidation = form.validationErrors?.productsToPrint?.[0];

  return (
    <form className="prices-form" onSubmit={form.submit}>
      <ErrorMessage isGlobal isVisible={!!errorMessage}>
        {errorMessage}
      </ErrorMessage>
      {isSinglePrint && (
        <>
          <InputField
            disabled
            errorMessage={firstItemValidation?.product?.code}
            label={translations.getLabel('PRICES.EDIT.CODE')}
            name="code"
            onChange={() => {}}
            required
            type="text"
            value={firstItem.product.code}
          />

          <InputField
            errorMessage={firstItemValidation?.price?.title}
            label={translations.getLabel('PRICES.PRINT.TITLE_NAME')}
            name="title"
            onChange={value =>
              form.setValues(values => {
                values.productsToPrint[0].price.title = value;
              })
            }
            type="text"
            value={firstItem.price.title}
          />

          <InputField
            errorMessage={firstItemValidation?.price?.description}
            label={translations.getLabel('PRICES.PRINT.DESCRIPTION')}
            name="description"
            onChange={value =>
              form.setValues(values => {
                values.productsToPrint[0].price.description = value;
              })
            }
            type="text"
            value={firstItem.price.description || '-'}
          />

          <InputField
            errorMessage={firstItemValidation?.product?.dimensions}
            label={translations.getLabel('PRICES.PRINT.DIMENSIONS')}
            name="dimensions"
            onChange={value =>
              form.setValues(values => {
                values.productsToPrint[0].product.dimensions = value;
              })
            }
            type="text"
            value={firstItem.product.dimensions || '-'}
          />

          <div className="price-wrapper">
            <InputField
              errorMessage={firstItemValidation?.price?.brutoPrice}
              label={translations.getLabel('PRICES.PRINT.ORIGINAL_PRICE')}
              name="originalPrice"
              onChange={value =>
                form.setValues(values => {
                  values.productsToPrint[0].price.brutoPrice = value;
                })
              }
              type="text"
              value={String(firstItem.price.brutoPrice)}
            />

            <InputField
              errorMessage={firstItemValidation?.price?.sellingPrice}
              label={translations.getLabel('PRICES.PRINT.SALE_PRICE')}
              name="salePrice"
              onChange={value =>
                form.setValues(values => {
                  values.productsToPrint[0].price.sellingPrice = value;
                })
              }
              type="text"
              value={String(firstItem.price.sellingPrice)}
            />
          </div>
        </>
      )}
      <Dropdown
        className="print-format"
        errorMessage={form.validationErrors.printFormat}
        label={translations.getLabel('PRICES.PRINT.FORMAT')}
        name="printFormat"
        onChange={form.setAttribute}
        options={printOptions}
        required
        value={form.values.printFormat}
      />

      <div className="actions">
        {buttons}
        <Button loading={isSubmitting} primary type="submit">
          {translations.getLabel('PRICES.PRINT.PRINT')}
        </Button>
      </div>
    </form>
  );
};

export default PrintPriceForm;
