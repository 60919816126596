import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Table, { TableColumn } from '../../_shared/table/Table';
import { translations } from '../../_translations';
import { useInfiniteScroll, useModal } from '../../_hooks';
import { FillMetadataQueryFunction, ApiError } from '../../_http';
import { storesSelectors } from '../../_store/selectors';
import { Icon, Button } from '../../_shared';
import { IAdminStore } from '../_models';
import { labelForItemState } from '../../_utils/stateHelper';
import EditStoreModal from './EditStoreModal';
import './StoresTable.scss';

type Props = {
  data?: IAdminStore[];
  errorAsString: (error?: ApiError) => string;
  isLoading: boolean;
  setQuery: FillMetadataQueryFunction;
};

const colums: TableColumn[] = [
  { label: '', name: 'edit', sortable: false },
  { label: 'MANAGEMENT.STORES.CODE', name: 'code', sortable: false, width: 1 },
  { label: 'MANAGEMENT.STORES.NAME', name: 'name', sortable: false, width: 2 },
  { label: 'MANAGEMENT.STORES.DESCRIPTION', name: 'description', sortable: false, width: 2 },
  { label: 'MANAGEMENT.STORES.CONTACT_PERSON', name: 'contactPerson', sortable: false, width: 3 },
  { label: 'MANAGEMENT.STORES.CONTACT_EMAIL', name: 'contactEmail', sortable: false, width: 3 },
  { label: 'MANAGEMENT.STORES.SERIAL_NUMBER', name: 'serialNumber', sortable: false, textAlign: 'center', width: 2 },
  { label: 'MANAGEMENT.STORES.STATE.OPTIONS.ACTIVE', name: 'active', sortable: false, width: 2 },
];

const StoresTable: FC<Props> = ({ data, isLoading, setQuery, errorAsString }) => {
  const metadata = useSelector(storesSelectors.metadata);

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);
  const [renderEditStoreModal, showEditStoreModal] = useModal<{ storeId: string }>(modalProps => (
    <EditStoreModal {...modalProps} errorAsString={errorAsString} />
  ));

  function renderRow(store: IAdminStore, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });
    return (
      <Table.Row className={className} key={store.code}>
        <Table.Cell className="edit">
          <Button asText onClick={() => showEditStoreModal({ storeId: store.id })}>
            <Icon name="SvgPencil" />
          </Button>
        </Table.Cell>
        <Table.Cell>{store.code || '-'}</Table.Cell>
        <Table.Cell>{store.name || '-'}</Table.Cell>
        <Table.Cell>{store.description || '-'}</Table.Cell>
        <Table.Cell>{store.contactPerson || '-'}</Table.Cell>
        <Table.Cell>{store.contactEmail || '-'}</Table.Cell>
        <Table.Cell className="serialNumber">{store.serialNumber || '-'}</Table.Cell>
        <Table.Cell>{labelForItemState(store.state) || '-'}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <Table
        columns={colums}
        data={data}
        emptyLabel={translations.getLabel('MANAGEMENT.STORES.OVERVIEW.EMPTY')}
        isLoading={isLoading}
        renderRow={renderRow}
      />
      {renderEditStoreModal()}
    </>
  );
};

export default StoresTable;
