import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Icon, Table } from '../../_shared';
import { createProductForm, IProductForm, mapProductItem } from '../_models';
import { validateForm } from '../create/form/ProductForm';
import { useForm } from '../../_hooks';
import { productsActions } from '../../_store/actions';
import { AppState } from '../../_store/rootReducer';
import { productsSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';

type Props = {
  className?: string;
  hasEditPermission: boolean;
  hasExtraPricePermission: boolean;
  hasLedgerPermission: boolean;
  hasPurchasePricePermission: boolean;
  hasSellingPricePermission: boolean;
  hasTotalPricePermission: boolean;
  id: string;
  isWarehouse: boolean;
  onEdit: ({ stockItem }) => void;
  onSaveSuccess: () => void;
  readOnly: boolean;
};

const ProductRow = ({
  id,
  className,
  onEdit,
  hasEditPermission,
  readOnly,
  onSaveSuccess,
  hasPurchasePricePermission,
  hasSellingPricePermission,
  hasTotalPricePermission,
  hasExtraPricePermission,
  isWarehouse,
}: Props) => {
  const dispatch = useDispatch();
  const stockItem = useSelector((state: AppState) => productsSelectors.product(state, id));
  const initialForm = createProductForm(stockItem);

  const submitForm = (values: IProductForm) =>
    dispatch(
      new productsActions.UpdateProduct({
        ids: { product: stockItem.product.id, store: stockItem.store.id },
        onSuccess: onSaveSuccess,
        values: mapProductItem(values, stockItem.product.price.id),
      }),
    );

  const form = useForm<IProductForm>({ error: null, initialForm, submitForm, validateForm });

  useEffect(() => {
    if (!form.isDirty) {
      form.setFormValues(createProductForm(stockItem));
    }
  }, [stockItem]);

  const classNames = classnames(className, {
    'low-stock': stockItem.value < 2,
    'medium-stock': stockItem.value >= 2 && stockItem.value < 6,
    'sync-failed': stockItem.isSyncFailed,
  });

  function submitOnBlur() {
    if (form.isDirty) form.submit(null);
  }

  function renderInput(result: string | number, name: string, small = false, maxLength?: number) {
    if (hasEditPermission && !readOnly) {
      return (
        <input
          className={classnames({ small })}
          maxLength={maxLength}
          onBlur={submitOnBlur}
          onChange={(event: React.BaseSyntheticEvent) => form.setAttribute(event.target.value, name)}
          type="text"
          value={result || ''}
        />
      );
    }

    return result || '-';
  }

  function renderCheckbox(result: boolean, name: string) {
    if (hasEditPermission && !readOnly) {
      return <Checkbox checked={result} name={name} onBlur={submitOnBlur} onChange={value => form.setAttribute(value, name)} />;
    }

    return result ? translations.getLabel('PRODUCTS.YES') : translations.getLabel('PRODUCTS.NO');
  }

  return (
    <Table.Row className={classNames} key={stockItem.id}>
      {readOnly && hasEditPermission && isWarehouse && (
        <Table.Cell className="edit" name="edit">
          <Button asText onClick={() => onEdit({ stockItem })}>
            <Icon name="SvgPencil" />
          </Button>
        </Table.Cell>
      )}
      <Table.Cell className="code-cell" name="product.code">
        {stockItem.product.code}
      </Table.Cell>
      <Table.Cell name="product.description">{renderInput(form.values.description, 'description')}</Table.Cell>
      <Table.Cell name="product.dimensions">{renderInput(form.values.dimensions, 'dimensions')}</Table.Cell>
      <Table.Cell name="product.remark">{renderInput(form.values.remark, 'remark')}</Table.Cell>
      {isWarehouse && <Table.Cell name="stock-item.zone">{renderInput(form.values.zone, 'zone', true)}</Table.Cell>}
      <Table.Cell name="product.reserved">{renderInput(form.values.reserved, 'reserved', true)}</Table.Cell>
      {isWarehouse && (
        <Table.Cell name="stock-item.expected" title={stockItem.expected}>
          {renderInput(form.values.expected, 'expected')}
        </Table.Cell>
      )}
      {readOnly && (
        <Table.Cell name="stock-item.value" textAlign="center">
          {stockItem.value}
        </Table.Cell>
      )}
      {hasPurchasePricePermission && (
        <Table.Cell name="price.purchasePrice" textAlign="center">
          {renderInput(form.values.purchasePrice, 'purchasePrice', true)}
        </Table.Cell>
      )}
      {hasSellingPricePermission && (
        <Table.Cell name="price.sellingPrice" textAlign="center">
          {renderInput(form.values.sellingPrice, 'sellingPrice', true)}
        </Table.Cell>
      )}
      {readOnly && hasTotalPricePermission && (
        <Table.Cell name="price.total" textAlign="center">
          {stockItem.value * parseInt(stockItem.product.price.purchasePrice)}
        </Table.Cell>
      )}
      {hasExtraPricePermission && (
        <Table.Cell name="price.extraPrice" textAlign="center">
          {renderInput(form.values.extraPrice, 'extraPrice', true)}
        </Table.Cell>
      )}
      {isWarehouse && hasTotalPricePermission && (
        <Table.Cell name="product.warehouseStockCount">
          {renderInput(form.values.warehouseStockCount, 'warehouseStockCount', null, 4)}
        </Table.Cell>
      )}
      <Table.Cell name="product.isChair">{renderCheckbox(form.values.isChair, 'isChair')}</Table.Cell>
    </Table.Row>
  );
};

export default ProductRow;
