import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Container } from 'semantic-ui-react';

import { Button, Icon, InputFieldDate, Table } from '../../_shared';
import { reportsActions } from '../../_store/actions';
import { reportsSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { BestsellerItem } from '../_models';
import { TableColumn } from '../../_shared/table/Table';
import { useInfiniteScroll } from '../../_hooks';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import { dateFromString, formatDate } from '../../_utils/timeHelpers';

import './reportsBestsellers.scss';

type Props = {
  sortBy: 'quantity' | 'sellValue';
  storeId: string;
};

const columns: TableColumn[] = [
  { label: 'REPORTS.TABLE.CODE', name: 'code', sortable: false },
  { label: 'REPORTS.TABLE.DESCRIPTION', name: 'description', sortable: false },
  { label: 'REPORTS.TABLE.QUANTITY', name: 'quantity', sortable: false },
  { label: 'REPORTS.TABLE.STOCK_VALUE', name: 'stockValue', sortable: false },
  { label: 'REPORTS.TABLE.SELL_VALUE', name: 'sellValue', sortable: false },
];

const ReportsBestsellers = ({ storeId, sortBy }: Props) => {
  const bestsellers = useSelector(reportsSelectors.bestsellers);
  const isLoading = useSelector(reportsSelectors.isGetBestsellersLoading);
  const query = useSelector(reportsSelectors.query);
  const metadata = useSelector(reportsSelectors.metadata);
  const dispatch = useDispatch();
  const dateFormat = 'dd/MM/yyyy';

  const setQuery: FillMetadataQueryFunction = (partialQuery: HttpMetadataQuery) => {
    dispatch(new reportsActions.SetReportsQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  useEffect(() => {
    dispatch(new reportsActions.SetReportsQuery({ query: { ...query, sortBy }, storeId }));
  }, [sortBy]);

  const renderRow = (item: BestsellerItem, index: number) => {
    const className = classnames({ 'greyed-out': index % 2 });

    return (
      <Table.Row className={className} key={item.id}>
        <Table.Cell className="code-cell">{item.code}</Table.Cell>
        <Table.Cell className="code-cell">{item.description}</Table.Cell>
        <Table.Cell className="code-cell">{item.quantity}</Table.Cell>
        <Table.Cell className="code-cell">{item.stockvalue}</Table.Cell>
        <Table.Cell className="code-cell">{item.sellvalue}</Table.Cell>
      </Table.Row>
    );
  };

  const setDateQuery = (date: string, name: string) => {
    const dateObj = dateFromString(date);
    const filterDate = formatDate(dateObj, 'yyyy-MM-dd');
    setQuery({ [name]: filterDate });
  };

  return (
    <Container as="main" className="reports-bestsellers">
      <div className="actions">
        <div className="filters">
          <InputFieldDate
            dateFormat={dateFormat}
            label={translations.getLabel('REPORTS.BESTSELLERS.DATE_FROM')}
            name="fromDate"
            onChange={setDateQuery}
            value={formatDate(dateFromString(query?.fromDate, 'yyyy-MM-dd'), dateFormat)}
          />
          <InputFieldDate
            dateFormat={dateFormat}
            label={translations.getLabel('REPORTS.BESTSELLERS.DATE_TO')}
            name="toDate"
            onChange={setDateQuery}
            value={formatDate(dateFromString(query?.toDate, 'yyyy-MM-dd'), dateFormat)}
          />
        </div>
        <Button
          negative
          onClick={() =>
            dispatch(
              new reportsActions.ExportBestsellers({
                query,
                storeId,
              }),
            )
          }
        >
          <Icon name="SvgDownload" />
          <span>{translations.getLabel('SHARED.BUTTONS.DOWNLOAD')}</span>
        </Button>
      </div>
      <Table
        columns={columns}
        data={bestsellers || []}
        emptyLabel={translations.getLabel('REPORTS.BESTSELLERS.EMPTY')}
        fixed={false}
        isLoading={isLoading}
        renderRow={renderRow}
      />
    </Container>
  );
};

export default ReportsBestsellers;
