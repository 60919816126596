import React, { FC, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { SortFunctions } from '../../../_hooks/useTableSort';
import Spinner from '../../spinner/Spinner';
import { translations } from '../../../_translations';
import './customTable.scss';

export type TTableColumn = {
  className?: string;
  label?: string;
  name: string;
  sortable?: boolean;
};

type TProps = {
  columns: TTableColumn[];
  data?: Record<string, unknown>[];
  isLoading: boolean;
  readonly?: boolean;
  renderRow: (item: unknown, key?: number) => ReactElement;
  sortFunctions?: SortFunctions;
};

const Row: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <tr className={className}>{children}</tr>
);
const Cell: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <td className={classnames('cell', className)}>{children}</td>
);
const CellEditable: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <Cell className={classnames('cell-editable', { className })}>{children}</Cell>
);

const CustomTable: FC<TProps> = ({ columns, renderRow, data = [], isLoading, sortFunctions, readonly = false }) => {
  function renderHeaderCell(column: TTableColumn) {
    return (
      <th
        className={classnames(
          column.className || '',
          column.sortable ? sortFunctions.getSortDirectionForColumn(column.name)?.toLowerCase() : '',
          {
            sortable: column.sortable,
          },
        )}
        key={column.name}
        onClick={column.sortable ? () => sortFunctions.onChangeSortColumn(column.name) : null}
      >
        {!!column.label && column.label}
      </th>
    );
  }

  if (!isLoading && !data?.length) return <p>{translations.getLabel('SHARED.PLACEHOLDER.NO_RESULTS')}</p>;
  return (
    <table
      className={classnames(
        'custom-table',
        { sortable: columns.some(col => col.sortable) },
        { 'is-loading': isLoading },
        { readonly },
      )}
    >
      <thead>
        <tr>{columns.map(column => renderHeaderCell(column))}</tr>
      </thead>
      <tbody>
        {data?.map((item, key) => renderRow(item, key))}
        {isLoading && (
          <tr className="row-spinner">
            <td className="centered-text" colSpan={columns.length}>
              <Spinner />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Object.assign(CustomTable, { Cell, CellEditable, Row });
