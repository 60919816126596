import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer, Action } from 'redux';
import codesReducer, { CodesState } from '../codes/_store/reducer';
import usersReducer, { UsersState } from '../users/_store/reducer';
import authReducer, { AuthState } from '../auth/_store/reducer';
import modalReducer, { ModalState } from '../modal/_store/reducer';
import profileReducer, { ProfileState } from '../profile/_store/reducer';
import { AuthActionType } from '../auth/_store/actions';
import storesReducer, { StoresState } from '../management/_store/reducer';
import stockReducer, { StockState } from '../stock/_store/reducer';
import pricesReducer, { PricesState } from '../prices/_store/reducer';
import productsReducer, { ProductsState } from '../products/_store/reducer';
import reportsReducer, { ReportsState } from '../reports/_store/reducer';
import cashSheetsReducer, { CashSheetsState } from '../cashSheets/_store/reducer';
import ledgerReducer, { LedgerState } from '../ledger/_store/reducer';

export type AppState = {
  auth: AuthState;
  cashSheets: CashSheetsState;
  codes: CodesState;
  ledger: LedgerState;
  modal: ModalState;
  prices: PricesState;
  products: ProductsState;
  profile: ProfileState;
  reports: ReportsState;
  router: RouterState;
  stock: StockState;
  stores: StoresState;
  users: UsersState;
};

function appReducer(history: History): Reducer {
  return combineReducers<AppState>({
    auth: authReducer,
    cashSheets: cashSheetsReducer,
    codes: codesReducer,
    ledger: ledgerReducer,
    modal: modalReducer,
    prices: pricesReducer,
    products: productsReducer,
    profile: profileReducer,
    reports: reportsReducer,
    router: connectRouter(history),
    stock: stockReducer,
    stores: storesReducer,
    users: usersReducer,
  });
}

export default (history: History) => (state: AppState, action: Action) => {
  if (action.type === AuthActionType.LogoutSuccess) {
    return appReducer(history)(undefined, action);
  }
  return appReducer(history)(state, action);
};
