import React from 'react';
import CustomTable, { TTableColumn } from '../../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../../_translations';
import { TCashSheet, TDecoration } from '../../../_models';
import { TDecorationForm } from '../../../_forms/DecorationForm';
import DecorationForm from './DecorationForm';

const columns: TTableColumn[] = [
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.DECORATION'),
    name: 'name',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.RECEIPT_CODE'), name: 'receiptCode' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.AMOUNT'), name: 'totalPrice' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH'), name: 'paidCash' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANCONTACT'), name: 'paidBancontact' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CREDITCARD'), name: 'paidCreditcard' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_BANCONTACT'),
    name: 'paidBancontactPortable',
  },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_CREDITCARD'),
    name: 'paidCreditcardPortable',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.COUPON'), name: 'paidCheck' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANK_TRANSFER'),
    name: 'paidBankTransfer',
  },
];

type TProps = {
  data?: TCashSheet;
  isLoading?: boolean;
  readOnly?: boolean;
};

const DecorationTable: React.FC<TProps> = ({ data, isLoading, readOnly }) => {
  function renderRow(decoration: TDecoration, index: number): JSX.Element {
    const initialForm: TDecorationForm = {
      name: decoration.name || '',
      paidBancontact: decoration.paidBancontact || 0,
      paidBancontactPortable: decoration.paidBancontactPortable || 0,
      paidBankTransfer: decoration.paidBankTransfer || 0,
      paidCash: decoration.paidCash || 0,
      paidCheck: decoration.paidCheck || 0,
      paidCreditCard: decoration.paidCreditCard || 0,
      paidCreditCardPortable: decoration.paidCreditCardPortable || 0,
      receiptCode: decoration.receiptCode || '',
      totalPrice: decoration.totalPrice || 0,
    };

    return (
      <DecorationForm
        cashSheet={data}
        decorationRevenueId={decoration.id}
        highlight={decoration.highlight}
        index={index}
        initialForm={initialForm}
        key={index}
      ></DecorationForm>
    );
  }

  return (
    <CustomTable
      columns={columns}
      data={data?.decorationRevenue ? [data?.decorationRevenue] : [{}]}
      isLoading={isLoading}
      readonly={readOnly}
      renderRow={renderRow}
    />
  );
};

export default DecorationTable;
