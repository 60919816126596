import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import Table, { TableColumn } from '../../_shared/table/Table';
import { translations } from '../../_translations';
import { useInfiniteScroll } from '../../_hooks';
import { codesSelectors, profileSelectors, stockSelectors, storesSelectors } from '../../_store/selectors';
import { StockItem } from '../_models';
import StockTableRow from '../overview/stock-table-row/StockTableRow';
import { stockActions } from '../../_store/actions';
import { Code } from '../../codes/_models';
import { hasViewPermissions } from '../../profile/_utils';
import { ItemState } from '../../_utils/stateHelper';

type Props = {
  isLoading: boolean;
  saveChanges: () => void;
  storeId: string;
};

const columns: TableColumn[] = [
  { label: 'STOCKITEMS.TABLE.CODE', name: 'product.code', sortable: false },
  { label: 'STOCKITEMS.TABLE.DESCRIPTION', name: 'product.description', sortable: false },
  { label: 'STOCKITEMS.TABLE.REMARK', name: 'product.remark', sortable: false },
  { label: 'STOCKITEMS.TABLE.PURCHASE_PRICE', name: 'price.purchasePrice', sortable: false, textAlign: 'center' },
  { label: 'STOCKITEMS.TABLE.SELLING_PRICE', name: 'price.sellingPrice', sortable: false, textAlign: 'center' },
  { label: 'STOCKITEMS.TABLE.B_STOCK', name: 'stock-item.b_stock', sortable: false, textAlign: 'center' },
  { label: 'STOCKITEMS.TABLE.VW', name: 'stock-item.value', sortable: false, textAlign: 'center' },
  { label: 'STOCKITEMS.TABLE.STOCK', name: 'stock-item.stock', sortable: false, textAlign: 'center' },
  { label: 'STOCKITEMS.TABLE.ZONE', name: ' stock-item.zone', sortable: false, textAlign: 'center' },
];

const StockItemsTableEdit: FC<Props> = ({ storeId, isLoading, saveChanges }) => {
  const metadata = useSelector(stockSelectors.stockItemMetadata);
  const stockItems = useSelector(stockSelectors.stockItems);
  const currentStore = useSelector(storesSelectors.currentStore);
  const codes = useSelector(codesSelectors.codes);
  const permissions = useSelector(profileSelectors.permissions);

  const hasPurchasePricePermission = hasViewPermissions(codes, Code.PurchasePrice, currentStore?.id, permissions);
  const hasSellingPricePermission = hasViewPermissions(codes, Code.SellPrice, currentStore?.id, permissions);

  const dispatch = useDispatch();

  useInfiniteScroll(
    (skip: number) => dispatch(new stockActions.GetStockItems({ skip, state: ItemState.Active, storeId, take: 1000 })),
    metadata,
    isLoading,
  );

  function renderRow(stockItem: StockItem, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });
    return <StockTableRow className={className} key={stockItem.id} saveChanges={saveChanges} stockItem={stockItem} />;
  }

  const filteredColumns = columns.filter(col => {
    if (!hasPurchasePricePermission && col.name === 'price.purchasePrice') return false;
    if (!hasSellingPricePermission && col.name === 'price.sellingPrice') return false;
    return true;
  });

  return (
    <Table
      columns={filteredColumns}
      data={stockItems}
      emptyLabel={translations.getLabel('STOCKITEMS.TABLE.EMPTY')}
      fixed={false}
      isLoading={isLoading}
      renderRow={renderRow}
    />
  );
};

export default StockItemsTableEdit;
