import React, { FC } from 'react';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { translations } from '../../_translations';
import { usersSelectors } from '../../_store/selectors';
import { IUserForm } from '../_models';
import UserForm from '../edit/UserForm';
import { usersActions } from '../../_store/actions';

const initialForm: IUserForm = {
  defaultStoreId: '',
  email: '',
  firstName: '',
  isAdmin: false,
  lastName: '',
  permissions: [],
  stores: [],
};

const CreateUser: FC = () => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(usersSelectors.isCreateUserLoading);
  const error = useSelector(usersSelectors.errorCrudUsers);

  return (
    <Container as="main">
      <header>
        <h4 className="bold userFormHeader">{translations.getLabel('USERS.CREATE.TITLE')}</h4>
      </header>
      <section className="create-user">
        <UserForm
          error={error}
          initialForm={initialForm}
          isCreate
          isSubmitting={isSubmitting}
          submitForm={(values: IUserForm) => dispatch(new usersActions.CreateUser({ values }))}
        />
      </section>
    </Container>
  );
};

export default CreateUser;
