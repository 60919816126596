import React from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import SecondaryNav from '../_routing/layouts/authorized/menu/SecondaryNav';
import { translations } from '../_translations';
import { Code } from '../codes/_models';
import { ForbiddenPage } from '../_shared';
import { usePermission } from '../_hooks';
import LedgerDecoration from './ledgerDecoration/LedgerDecoration';
import LedgerOtherFurniture from './ledgerOtherFurniture/LedgerOtherFurniture';
import LedgerRevenuePerSeller from './ledgerRevenuePerSeller/LedgerRevenue';
import LedgerToBank from './ledgerToBank/LedgerToBank';
import LedgerToolbar from './toolbar/LedgerToolbar';
import './ledger.scss';

const Ledger: React.FC = () => {
  const { url } = useRouteMatch();
  const { storeId } = useParams<{ storeId: string }>();

  const permission = usePermission(Code.Ledger);

  return (
    <>
      <main className="ledger-page">
        <SecondaryNav />
        {permission.view ? (
          <div className="ledger-page__content">
            <h3>{translations.getLabel('LEDGER.TITLE')}</h3>
            <Switch>
              <Route
                exact
                path={`${url}/other-furniture`}
                render={props => <LedgerOtherFurniture {...props} storeId={storeId} />}
              />
              <Route exact path={`${url}/decoration`} render={props => <LedgerDecoration {...props} storeId={storeId} />} />
              <Route
                exact
                path={`${url}/revenue-per-seller`}
                render={props => <LedgerRevenuePerSeller {...props} storeId={storeId} />}
              />
              <Route exact path={`${url}/to-bank`} render={props => <LedgerToBank {...props} storeId={storeId} />} />
              <Redirect to={`${url}/other-furniture`} />
            </Switch>
          </div>
        ) : (
          <ForbiddenPage />
        )}
        <LedgerToolbar />
      </main>
    </>
  );
};

export default Ledger;
