import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cashSheetsSelectors } from '../_store/selectors';

const IS_RECENTLY_SAVED_TIMESPAN = 3000;

function useIsRecentlySaved(): boolean {
  const isLoading = useSelector(cashSheetsSelectors.isUpdateCasheetLoading);
  const hasError = useSelector(cashSheetsSelectors.errorCrudCashSheets);
  const [timeoutFunction, setTimeoutFunction] = useState<NodeJS.Timeout>(null);
  const [isRecentlySaved, setIsRecentlySaved] = useState<boolean>(false);
  const [previousIsLoading, setPreviousIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (previousIsLoading && !isLoading && !hasError) {
      setIsRecentlySaved(true);
      clearTimeout(timeoutFunction);
      setTimeoutFunction(
        setTimeout(function() {
          setIsRecentlySaved(false);
        }, IS_RECENTLY_SAVED_TIMESPAN),
      );
    }
    setPreviousIsLoading(isLoading);
  }, [isLoading]);

  return isRecentlySaved;
}

export default useIsRecentlySaved;
