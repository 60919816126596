import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Products from '../../../products/Products';
import Prices from '../../../prices/Prices';
import Management from '../../../management/Management';
import { profileSelectors, storesSelectors } from '../../../_store/selectors';
import Profile from '../../../profile/Profile';
import Stock from '../../../stock/Stock';
import Reports from '../../../reports/Reports';
import CashSheets from '../../../cashSheets/CashSheets';
import Ledger from '../../../ledger/Ledger';
import { config } from '../../../config';
import AuthorizedLayoutMenu from './menu/AuthorizedLayoutMenu';
import './authorizedLayout.scss';

const AuthorizedLayout: React.FC = () => {
  const profile = useSelector(profileSelectors.profile);
  const currentStore = useSelector(storesSelectors.currentStore);

  return (
    <div className="authorized-layout">
      <AuthorizedLayoutMenu />
      <Switch>
        <Route component={Profile} path="/profile" />
        <Route component={Products} exact path="/products/:storeId" />
        <Route component={Stock} path="/stock/:storeId" />
        <Route component={Prices} path="/prices/:storeId" />
        <Route component={Reports} path="/reports/:storeId" />
        {config.canShowV2() && <Route component={CashSheets} path="/cash-sheets/:storeId" />}
        {config.canShowV2() && <Route component={Ledger} path="/ledger/:storeId" />}
        {profile.isAdmin && <Route component={Management} path="/management" />}
        <Redirect to={`/products/${currentStore?.id}`} />
      </Switch>
    </div>
  );
};

export default AuthorizedLayout;
