import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, InputFieldDate } from '../../_shared';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import LedgerEmpty from '../ledgerEmpty/LedgerEmpty';
import LedgerMenu from '../ledgerMenu/LedgerMenu';
import BankTransfersPerMonth from './bankTransfersPerMonth/BankTransfersPerMonth';
import BankTransferBottomBar from './bottomBar/BankTransferBottomBar';
import './ledgerToBank.scss';

type TProps = {
  storeId: string;
};

const LedgerToBank: React.FC<TProps> = ({ storeId }) => {
  const bankTransfers = useSelector(ledgerSelectors.bankTransfers);
  const year = useSelector(ledgerSelectors.year);
  const isLoading = useSelector(ledgerSelectors.isBankTransfersLoading);
  const dispatch = useDispatch();
  const dateFormat = 'yyyy';
  const setYear = (year: string) => {
    dispatch(new ledgerActions.SetLedgerYear({ storeId, year }));
  };

  useEffect(() => {
    dispatch(new ledgerActions.GetLedgerBankTransfers({ storeId }));
  }, []);

  function renderContent() {
    if (bankTransfers?.months.length === 0) {
      return <LedgerEmpty />;
    }
    return (
      <>
        {bankTransfers?.months.map((month, key) => (
          <BankTransfersPerMonth isLoading={isLoading} key={key} monthlyBankTransfers={month} />
        ))}
        <BankTransferBottomBar bankTransfer={bankTransfers} />
      </>
    );
  }

  return (
    <main className="ledger-to-bank">
      <div className="ledger-to-bank__header">
        <LedgerMenu />
        <div className="ledger-to-bank__header__controls">
          <InputFieldDate
            dateFormat={dateFormat}
            name="month"
            onChange={(date: string) => setYear(date)}
            showYearPicker
            value={year}
          />
          <Button
            onClick={() => {
              window.print();
            }}
            primary
          >
            <Icon name="SvgPrint" size={2} />
            {translations.getLabel('CASH_SHEETS.OVERVIEW.PRINT_PAGE')}
          </Button>
        </div>
      </div>
      {renderContent()}

      <BankTransferBottomBar bankTransfer={bankTransfers} />
    </main>
  );
};

export default LedgerToBank;
