import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from '../../../../_translations';
import { authActions } from '../../../../_store/actions';
import { codesSelectors, profileSelectors, storesSelectors } from '../../../../_store/selectors';
import { SvgLogo } from '../../../../_assets/svg';
import { Icon } from '../../../../_shared';
import { Code } from '../../../../codes/_models';
import { hasViewPermissionOverall } from '../../../../profile/_utils';

import './authorizedLayoutMenu.scss';
import { config } from '../../../../config';
import { usePermission } from '../../../../_hooks';

const AuthorizedLayoutMenu: FC = () => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const profile = useSelector(profileSelectors.profile);
  const codes = useSelector(codesSelectors.codes);

  const hasTotalPriceViewPermission = hasViewPermissionOverall(codes, Code.TotalPrice, profile?.permissions);
  const ledgerPermission = usePermission(Code.Ledger);
  const cashSheetPermission = usePermission(Code.CashSheet);

  return (
    <header className="main-menu">
      <NavLink className="logo" to="/">
        <SvgLogo />
      </NavLink>
      <nav>
        <div>
          <NavLink to={`/products/${currentStore?.id}`}>{translations.getLabel('SHARED.NAVIGATION.PRODUCTS')}</NavLink>
          <NavLink to={`/stock/${currentStore?.id}/stock-item`}>{translations.getLabel('SHARED.NAVIGATION.STOCK')}</NavLink>
          <NavLink to={`/prices/${currentStore?.id}`}>{translations.getLabel('SHARED.NAVIGATION.PRICES')}</NavLink>
          {hasTotalPriceViewPermission && (
            <NavLink to={`/reports/${currentStore?.id}`}>{translations.getLabel('SHARED.NAVIGATION.REPORTS')}</NavLink>
          )}
          {config.canShowV2() && cashSheetPermission.view && (
            <NavLink to={`/cash-sheets/${currentStore?.id}`}>{translations.getLabel('SHARED.NAVIGATION.CASH_SHEETS')}</NavLink>
          )}
          {config.canShowV2() && ledgerPermission.view && (
            <NavLink to={`/ledger/${currentStore?.id}`}>{translations.getLabel('SHARED.NAVIGATION.LEDGER')}</NavLink>
          )}
          {profile.isAdmin && <NavLink to="/management">{translations.getLabel('SHARED.NAVIGATION.MANAGEMENT')}</NavLink>}
        </div>
        <NavLink to="/profile">
          <span>
            {translations.getLabel('SHARED.NAVIGATION.WELCOME')}
            {profile.firstName || profile.lastName ? ',' : ''} {profile.firstName} {profile.lastName}
          </span>
        </NavLink>
      </nav>
      <Icon
        label={translations.getLabel('AUTH.LOGOUT')}
        name="SvgLogout"
        onClick={() => dispatch(new authActions.Logout())}
        size={1.6}
      />
    </header>
  );
};

export default AuthorizedLayoutMenu;
