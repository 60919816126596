import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { translations } from '../../_translations';
import { InputField, Button, ErrorMessage } from '../../_shared';
import { useForm } from '../../_hooks';
import { authSelectors } from '../../_store/selectors';
import { authActions } from '../../_store/actions';
import { FormValidationErrors } from '../../_hooks/useForm';
import { formValidator } from '../../_utils/formValidation';
import { ILoginForm } from '../_models';
import LogoTop from '../logo-top/LogoTop';
import { errorAsString } from '../../_utils/errorHelper';
import './login.scss';

const initialForm: ILoginForm = {
  password: '',
  username: '',
};

function validateForm(values: ILoginForm): FormValidationErrors<ILoginForm> {
  return {
    password: formValidator.required(values.password).error,
    username: !values.username ? formValidator.required(values.username).error : null, //formValidator.email(values.username).error,
  };
}

const Login = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<{ pathname: string }>();
  const isSubmitting = useSelector(authSelectors.isLoginLoading);
  const error = useSelector(authSelectors.errorLogin);
  const form = useForm<ILoginForm>({
    error,
    initialForm,
    submitForm: values => dispatch(new authActions.Login({ pathname: state?.pathname, values })),
    validateForm,
  });

  useEffect(() => {
    // Do nothing on mount, but clean error on unmount
    return () => dispatch(new authActions.LoginError({ error: null }));
  }, []);

  const errorMessage = errorAsString(error);

  return (
    <section className="login">
      <div className="loginbox">
        <LogoTop />
        <div className="formbox">
          <h1>{translations.getLabel('AUTH.LOGIN.TITLE')}</h1>
          <form onSubmit={form.submit}>
            <ErrorMessage isGlobal isVisible={!!errorMessage}>
              {errorMessage}
            </ErrorMessage>
            <InputField
              autoComplete="username"
              errorMessage={form.validationErrors.username}
              label={translations.getLabel('AUTH.LOGIN.USERNAME')}
              name="username"
              onChange={form.setAttribute}
              type="text"
              // type="email"
              value={form.values.username}
            />
            <InputField
              autoComplete="current-password"
              errorMessage={form.validationErrors.password}
              label={translations.getLabel('AUTH.LOGIN.PASSWORD')}
              name="password"
              onChange={form.setAttribute}
              type="password"
              value={form.values.password}
            />
            <div className="actions">
              <Button loading={isSubmitting} primary type="submit">
                {translations.getLabel('AUTH.LOGIN.LOGIN')}
              </Button>
              <Link to="/auth/request-password-reset">{translations.getLabel('AUTH.LOGIN.FORGOT_PASSWORD')}</Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
