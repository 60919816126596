import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '../../../../_shared';
import InputFieldCurrency from '../../../../_shared/custom/inputFieldCurrency/InputFieldCurrency';
import Spinner from '../../../../_shared/spinner/Spinner';
import { translations } from '../../../../_translations';
import { TCashSheet } from '../../../_models';
import { TCashSheetForm } from '../../../_forms/CashSheetForm';
import { canMoreBanksBeAdded } from '../../../_models/rules';
import { cashSheetActions } from '../../../../_store/actions';
import { storesSelectors } from '../../../../_store/selectors';
import './bankForm.scss';
import { TUseFormResponse } from '../../../../_hooks/useForm';

type TProps = {
  cashSheet?: TCashSheet;
  form: TUseFormResponse<TCashSheetForm, TCashSheetForm>;
  isLoading?: boolean;
  onBlur?: (name: string, value?: unknown) => void;
};

const BankSheet: React.FC<TProps> = ({ cashSheet, isLoading, form, onBlur }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);

  if (isLoading && !cashSheet) return <Spinner />;
  return (
    <div className="bank-sheet">
      <div className="bank-sheet__bank">
        <h5>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.TO_BANK')}</h5>
        <InputFieldCurrency autoFocus name="amountToBank" onBlur={onBlur} value={form.values.amountToBank} />
      </div>
      <div className="bank-sheet__bank">
        <h5>{translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.TO_BANK_TES')}</h5>
        <InputFieldCurrency autoFocus name="amountToBankBKF" onBlur={onBlur} value={form.values.amountToBankBKF} />
      </div>
      {form.values.amountToBankExtra?.map((bank, index) => (
        <div className="bank-sheet__bank" key={index}>
          <h5>{translations.getLabel('CASH_SHEETS.OVERVIEW.EXTRA_BANK')}</h5>
          <div className="bank-sheet__bank__added-bank">
            <Icon
              clickable
              label={translations.getLabel('CASH_SHEETS.OVERVIEW.DELETE_BANK')}
              name="SvgTrash"
              onClick={() => {
                const remainingBanks = [
                  ...form.values.amountToBankExtra.slice(0, index),
                  ...form.values.amountToBankExtra.slice(index + 1),
                ];
                form.setAttribute(remainingBanks, 'amountToBankExtra');
                dispatch(
                  new cashSheetActions.UpdateCashSheet({
                    cashSheetId: cashSheet.id,
                    storeId: currentStore.id,
                    values: { ...form.values, amountToBankExtra: remainingBanks },
                  }),
                );
              }}
              size={2.4}
            />
            <InputFieldCurrency
              autoFocus
              name="amountToBankExtra"
              onBlur={(name, value) => {
                const newArray = [
                  ...form.values.amountToBankExtra.slice(0, index),
                  value,
                  ...form.values.amountToBankExtra.slice(index + 1),
                ];
                form.setAttribute(newArray, 'amountToBankExtra');
                onBlur(name, newArray);
              }}
              value={bank}
            />
          </div>
        </div>
      ))}
      {canMoreBanksBeAdded(form.values.amountToBankExtra) && (
        <div className="bank-sheet__add-bank">
          <Icon
            clickable
            label={translations.getLabel('CASH_SHEETS.OVERVIEW.ADD_BANK')}
            name="SvgAddCircle"
            onClick={() => {
              form.setAttribute([...form.values.amountToBankExtra, 0], 'amountToBankExtra');
            }}
          />
          <p>{translations.getLabel('CASH_SHEETS.OVERVIEW.ADD_BANK')}</p>
        </div>
      )}
    </div>
  );
};

export default BankSheet;
