import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { stockActions } from '../../_store/actions';
import { stockSelectors } from '../../_store/selectors';
import { StockActionType } from './actions';
import * as stockApi from './api';

const getStockChangeEpic$: Epic = (action$, state$) =>
  action$.ofType(StockActionType.GetStockChange).pipe(
    exhaustMap(({ payload }: stockActions.GetStockChange) => {
      const query = stockSelectors.stockChangeQuery(state$.value);
      return from(stockApi.getStockChange(payload.storeId, query)).pipe(
        map(({ data, meta }) => new stockActions.GetStockChangeSuccess({ data, meta })),
        catchError(error => of(new stockActions.GetStockChangeError({ error }))),
      );
    }),
  );

const getStockItemsEpic$: Epic = action$ =>
  action$.ofType(StockActionType.GetStockItems).pipe(
    exhaustMap(({ payload }: stockActions.GetStockItems) => {
      return from(stockApi.getStockItems(payload.storeId, payload.state, payload.skip, payload.take)).pipe(
        map(({ data, meta }) => new stockActions.GetStockItemsSuccess({ data, meta })),
        catchError(error => of(new stockActions.GetStockItemsError({ error }))),
      );
    }),
  );

const updateStockItemEpic$: Epic = action$ =>
  action$.ofType(StockActionType.UpdateStockItem).pipe(
    exhaustMap(({ payload }: stockActions.UpdateStockItem) =>
      from(stockApi.updateStockItem(payload.ids, payload.values)).pipe(
        map(updatedStockItem => {
          payload?.onSuccess?.();
          return new stockActions.UpdateStockItemSuccess({ updatedStockItem });
        }),
        catchError(error => of(new stockActions.UpdateStockItemError({ error }))),
      ),
    ),
  );

const updateStockItemSuccessEpic$: Epic = action$ =>
  action$.ofType(StockActionType.UpdateStockItemSuccess).pipe(
    map(({ payload }: stockActions.UpdateStockItemSuccess) => {
      return new stockActions.SetStockChangeQuery({ query: {}, storeId: payload.updatedStockItem.store.id });
    }),
  );

const SetStockChangeQueryEpic$: Epic = action$ =>
  action$
    .ofType(StockActionType.SetStockChangeQuery)
    .pipe(map(({ payload }: stockActions.SetStockChangeQuery) => new stockActions.GetStockChange({ storeId: payload.storeId })));

export default [
  getStockChangeEpic$,
  getStockItemsEpic$,
  updateStockItemEpic$,
  updateStockItemSuccessEpic$,
  SetStockChangeQueryEpic$,
];
