import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Table, { TableColumn } from '../../_shared/table/Table';
import { FillMetadataQueryFunction, HttpSortDirection } from '../../_http';
import { translations } from '../../_translations';
import { useTableSort, useInfiniteScroll } from '../../_hooks';
import { usersSelectors } from '../../_store/selectors';
import { IUser } from '../_models';
import { labelForUserState } from '../_utils';
import { Button, Icon } from '../../_shared';
import './UsersTable.scss';

type Props = {
  data?: IUser[];
  isLoading: boolean;
  setQuery: FillMetadataQueryFunction;
};

const columns: TableColumn[] = [
  { label: '', name: 'edit', sortable: false },
  { label: 'USERS.FIRST_NAME', name: 'firstName', sortable: true, width: 3 },
  { label: 'USERS.LAST_NAME', name: 'lastName', sortable: true, width: 3 },
  { className: 'email-column', label: 'USERS.EMAIL', name: 'email', sortable: true, width: 4 },
  { label: 'USERS.STATE.TITLE', name: 'state', sortable: true, width: 3 },
];

const UsersTable: FC<Props> = ({ data, isLoading, setQuery }) => {
  const metadata = useSelector(usersSelectors.metadata);

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
  );
  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  function renderRow(user: IUser, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });
    return (
      <Table.Row className={className} key={user.email}>
        <Table.Cell className="edit">
          <Button asText href={`/management/users/${user.id}`}>
            <Icon name="SvgPencil" />
          </Button>
        </Table.Cell>
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{labelForUserState(user.state)}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      emptyLabel={translations.getLabel('USERS.OVERVIEW.EMPTY')}
      isLoading={isLoading}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default UsersTable;
