import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InputField, ErrorMessage } from '../../_shared';
import { useForm } from '../../_hooks';
import { translations } from '../../_translations';
import { authActions } from '../../_store/actions';
import { authSelectors } from '../../_store/selectors';
import { formValidator } from '../../_utils/formValidation';
import { FormValidationErrors } from '../../_hooks/useForm';
import { IChoosePasswordForm } from '../_models';
import LogoTop from '../logo-top/LogoTop';
import { errorAsString } from '../../_utils/errorHelper';
import './choosePassword.scss';

type Props = {
  isPasswordReset?: boolean;
};

const initialForm: IChoosePasswordForm = {
  newPassword: '',
  repeatNewPassword: '',
};

function validateForm(values: IChoosePasswordForm): FormValidationErrors<IChoosePasswordForm> {
  return {
    newPassword: formValidator.password(values.newPassword).error,
    repeatNewPassword: formValidator.matchingPasswords(values.newPassword, values.repeatNewPassword).error,
  };
}

const ChoosePassword: FC<Props> = ({ isPasswordReset }) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(authSelectors.isChoosePasswordLoading);
  const error = useSelector(authSelectors.errorChoosePassword);
  const { token } = useParams();
  const form = useForm<IChoosePasswordForm>({
    error,
    initialForm,
    submitForm: values => dispatch(new authActions.ChoosePassword({ token, values })),
    validateForm,
  });
  const errorMessage = errorAsString(error);

  return (
    <section className="choose-password">
      <div className="choose-pw-box">
        <LogoTop />
        <div className="formbox">
          <h1>{translations.getLabel(isPasswordReset ? 'AUTH.RESET_PASSWORD.TITLE' : 'AUTH.REGISTER.TITLE')}</h1>
          <p>{translations.getLabel('AUTH.RESET_PASSWORD.DESCRIPTION')}</p>
          <ul>
            <li>{translations.getLabel('AUTH.RESET_PASSWORD.LENGTH_REQUIREMENT')}</li>
            <li>{translations.getLabel('AUTH.RESET_PASSWORD.CAPS_REQUIREMENT')}</li>
            <li>{translations.getLabel('AUTH.RESET_PASSWORD.NUMBER_REQUIREMENT')}</li>
          </ul>
          <form onSubmit={form.submit}>
            <ErrorMessage isGlobal isVisible={!!errorMessage}>
              {errorMessage}
            </ErrorMessage>
            <InputField
              errorMessage={form.validationErrors.newPassword}
              label={translations.getLabel('AUTH.RESET_PASSWORD.NEW_PASSWORD')}
              name="newPassword"
              onChange={form.setAttribute}
              type="password"
              value={form.values.newPassword}
            />
            <InputField
              errorMessage={form.validationErrors.repeatNewPassword}
              label={translations.getLabel('AUTH.RESET_PASSWORD.REPEAT_NEW_PASSWORD')}
              name="repeatNewPassword"
              onChange={form.setAttribute}
              type="password"
              value={form.values.repeatNewPassword}
            />
            <div className="actions">
              <Button loading={isSubmitting} primary type="submit">
                {translations.getLabel(isPasswordReset ? 'AUTH.RESET_PASSWORD.RESET' : 'AUTH.REGISTER.REGISTER')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ChoosePassword;
