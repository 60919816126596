import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, InputFieldDate } from '../../_shared';
import { ledgerActions } from '../../_store/actions';
import { ledgerSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { formatDate, dateFromString } from '../../_utils/timeHelpers';
import LedgerEmpty from '../ledgerEmpty/LedgerEmpty';
import LedgerMenu from '../ledgerMenu/LedgerMenu';
import { TDecorationRevenueQuery } from '../_models/Decoration';
import DecorationBottomBar from './bottomBar/DecorationBottomBar';
import './ledgerDecoration.scss';
import DecorationRevenueTable from './table/DecorationRevenueTable';

type TProps = {
  storeId: string;
};

const LedgerDecoration: React.FC<TProps> = ({ storeId }) => {
  const decorationRevenue = useSelector(ledgerSelectors.decorationRevenue);
  const query = useSelector(ledgerSelectors.decorationRevenueQuery);
  const isLoading = useSelector(ledgerSelectors.isDecorationRevenuesLoading);
  const dispatch = useDispatch();
  const dateFormat = 'yyyy';

  const setQuery = (partialQuery: TDecorationRevenueQuery) => {
    dispatch(new ledgerActions.SetDecorationRevenueQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  useEffect(() => {
    dispatch(new ledgerActions.SetDecorationRevenueQuery({ query, storeId }));
  }, [storeId]);

  function renderContent() {
    if (decorationRevenue?.decorationRevenues.length === 0) {
      return <LedgerEmpty />;
    }
    return (
      <>
        <DecorationRevenueTable isLoading={isLoading} totalDecorationRevenues={decorationRevenue} />
        <DecorationBottomBar decorationRevenues={decorationRevenue} />
      </>
    );
  }

  return (
    <main className="ledger-decoration">
      <div className="ledger-decoration__header">
        <LedgerMenu />
        <div className="ledger-decoration__header__controls">
          <InputFieldDate
            dateFormat={dateFormat}
            name="year"
            onChange={(date: string) => setQuery({ year: formatDate(dateFromString(date, dateFormat), dateFormat) })}
            showYearPicker
            value={query.year}
          />
          <Button
            onClick={() => {
              window.print();
            }}
            primary
          >
            <Icon name="SvgPrint" size={2} />
            {translations.getLabel('CASH_SHEETS.OVERVIEW.PRINT_PAGE')}
          </Button>
        </div>
      </div>
      {renderContent()}
    </main>
  );
};

export default LedgerDecoration;
