import { StoresAction, StoresActionType } from '../../management/_store/actions';
import { HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { keepRetrievedDataPage } from '../../_utils/objectHelpers';
import { BestsellerItem, TopTenItem } from '../_models';
import { ReportsAction, ReportsActionType } from './actions';

export type ReportsState = {
  bestsellers?: BestsellerItem[];
  isGetBestsellersLoading?: boolean;
  isGetTopTenLoading?: boolean;
  metadata?: HttpMetadataPagingResponse;
  query?: HttpMetadataQuery;
  topTen?: TopTenItem[];
};

const initialState: ReportsState = {};

export default function reducer(state = initialState, action: ReportsAction | StoresAction): ReportsState {
  switch (action.type) {
    case StoresActionType.UpdateCurrentStore:
      return {
        ...state,
        bestsellers: [],
        metadata: null,
      };
    case ReportsActionType.GetTopTen:
      return {
        ...state,
        isGetTopTenLoading: true,
        topTen: null,
      };
    case ReportsActionType.GetTopTenError:
      return {
        ...state,
        isGetTopTenLoading: false,
      };
    case ReportsActionType.GetTopTenSuccess:
      return {
        ...state,
        isGetTopTenLoading: false,
        topTen: action.payload.data,
      };
    case ReportsActionType.GetBestSellers:
      return {
        ...state,
        isGetBestsellersLoading: true,
        metadata: null,
      };
    case ReportsActionType.GetBestSellersError:
      return {
        ...state,
        isGetBestsellersLoading: false,
      };
    case ReportsActionType.GetBestSellersSuccess:
      return {
        ...state,
        bestsellers: keepRetrievedDataPage(state.bestsellers, action.payload.data, action.payload.meta),
        isGetBestsellersLoading: false,
        metadata: action.payload.meta,
      };

    case ReportsActionType.SetReportsQuery:
      if (
        action.payload.query?.fromDate !== state.query?.fromDate ||
        action.payload.query?.sortBy !== state.query?.sortBy ||
        action.payload.query?.toDate !== state.query?.toDate
      ) {
        return {
          ...state,
          bestsellers: null,
          metadata: null,
          query: action.payload.query,
        };
      } else {
        return {
          ...state,
          query: action.payload.query,
        };
      }
    default:
      return state;
  }
}
