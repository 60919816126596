import { ApiError, HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { keepRetrievedDataPage } from '../../_utils/objectHelpers';
import { StockItem } from '../../stock/_models';
import { StoresAction, StoresActionType } from '../../management/_store/actions';
import { PricesAction, PricesActionType } from './actions';

export type PricesState = {
  errorCrudPrices?: ApiError;
  isGetPricesLoading?: boolean;
  isPrintPriceLoading?: boolean;
  isUpdatePriceLoading?: boolean;
  metadata?: HttpMetadataPagingResponse;
  prices?: StockItem[];
  printPrice?: string;
  query?: HttpMetadataQuery;
};

const initialState: PricesState = {};

export default function reducer(state = initialState, action: PricesAction | StoresAction): PricesState {
  switch (action.type) {
    case StoresActionType.UpdateCurrentStore:
      return {
        ...state,
        metadata: null,
        prices: [],
      };
    case PricesActionType.GetPrices:
      return {
        ...state,
        errorCrudPrices: null,
        isGetPricesLoading: true,
        metadata: null,
      };
    case PricesActionType.GetPricesSuccess: {
      return {
        ...state,
        isGetPricesLoading: false,
        metadata: action.payload.meta,
        prices: keepRetrievedDataPage(state.prices, action.payload.data, action.payload.meta),
      };
    }
    case PricesActionType.GetPricesError:
      return {
        ...state,
        errorCrudPrices: action.payload.error,
        isGetPricesLoading: false,
      };
    case PricesActionType.PrintPrice:
      return {
        ...state,
        errorCrudPrices: null,
        isPrintPriceLoading: true,
      };
    case PricesActionType.PrintPriceSuccess: {
      return {
        ...state,
        isPrintPriceLoading: false,
        printPrice: action.payload.printPrice,
      };
    }
    case PricesActionType.PrintPriceError:
      return {
        ...state,
        errorCrudPrices: action.payload.error,
        isPrintPriceLoading: false,
      };
    case PricesActionType.SetPricesQuery:
      // if we have switched from state, we need to reset the values
      if (action.payload.query?.state !== state.query?.state) {
        return {
          ...state,
          metadata: null,
          prices: [],
          query: {
            ...action.payload.query,
            skip: 0,
          },
        };
      } else {
        return {
          ...state,
          query: action.payload.query,
        };
      }
    case PricesActionType.UpdatePrice:
      return {
        ...state,
        errorCrudPrices: null,
        isUpdatePriceLoading: true,
      };
    case PricesActionType.UpdatePriceSuccess:
      // eslint-disable-next-line no-case-declarations
      const prices = state.prices.map(price => {
        if (price.id === action.payload.ids.stockItem) {
          return {
            ...price,
            product: {
              ...price.product,
              price: {
                ...price.product.price,
                description: action.payload.values.description,
                title: action.payload.values.title,
              },
            },
          };
        }
        return price;
      });
      return {
        ...state,
        isUpdatePriceLoading: false,
        prices,
      };
    case PricesActionType.UpdatePriceError:
      return {
        ...state,
        errorCrudPrices: action.payload.error,
        isUpdatePriceLoading: false,
      };
    default:
      return state;
  }
}
