import React, { ReactNode } from 'react';
import './bottomBar.scss';

type TProps = {
  children: ReactNode;
};

const BottomBar: React.FC<TProps> = ({ children }) => {
  return <div className="bottom-bar">{children}</div>;
};

export default BottomBar;
