import { IPermissions } from '../_models/Permissions';
import { ICode, Code } from '../../codes/_models';

export function hasViewPermissions(
  codes: ICode[],
  requiredCode: Code,
  requiredStore: string,
  permissions: IPermissions[],
): boolean {
  const code = codes?.find(_code => _code.code === requiredCode);
  if (!code) return false;
  const permission = permissions?.find(_permission => _permission.role.id === code.id && _permission.store.id === requiredStore);
  return permission?.permission?.view;
}

export function hasEditPermissions(
  codes: ICode[],
  requiredCode: Code,
  requiredStore: string,
  permissions: IPermissions[],
): boolean {
  const code = codes?.find(_code => _code.code === requiredCode);
  if (!code) return false;
  const permission = permissions?.find(_permission => _permission.role.id === code.id && _permission.store.id === requiredStore);
  return permission?.permission?.edit;
}

export function hasViewPermissionOverall(codes: ICode[], requiredCode: Code, permissions: IPermissions[]): boolean {
  const code = codes?.find(_code => _code.code === requiredCode);
  if (!code) return false;
  const permission = permissions?.filter(_permission => _permission.role.id === code.id);
  return permission?.some(permission => permission.permission.view);
}
