import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { ReportsState } from './reducer';

const selectNode = (state: AppState) => state.reports;

export const bestsellers = createSelector(selectNode, (state: ReportsState) => state.bestsellers);
export const topTen = createSelector(selectNode, (state: ReportsState) => state.topTen);
export const metadata = createSelector(selectNode, (state: ReportsState) => state.metadata);
export const query = createSelector(selectNode, (state: ReportsState) => state.query);
export const isGetTopTenLoading = createSelector(selectNode, (state: ReportsState) => state.isGetTopTenLoading);
export const isGetBestsellersLoading = createSelector(selectNode, (state: ReportsState) => state.isGetBestsellersLoading);
