import React, { FC } from 'react';
import { TInputFieldProps } from '../customInputField/CustomInputField';
import { parseCurrency } from '../../../_utils/parseHelpers';
import InputFieldText from '../inputFieldText/InputFieldText';

type TProps = Omit<TInputFieldProps, 'value' | 'onChange'> & {
  onBlur?: (name: string, value?: number) => void;
  onClick?: (name: string) => void;
  value?: number;
};

const InputFieldCurrency: FC<TProps> = props => {
  const { onBlur, value } = props;
  return (
    <InputFieldText
      {...props}
      getDisplayValue={value => parseCurrency(parseFloat(value))}
      mapDisplayValueToEditValue={value => (!value ? '' : `${parseFloat(value) / 100}`)}
      normalize={value => value.replace(',', '.').replace(/[^\d.-]/g, '')}
      onBlur={(name, value) => onBlur?.(name, Math.round(parseFloat(value) * 100) || 0)}
      value={value ? `${value}` : null}
    />
  );
};

export default InputFieldCurrency;
