import React from 'react';
import classnames from 'classnames';
import { translations } from '../../../_translations';
import { parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TDecorationRevenues } from '../../_models';
import { decorationRevenueColumns } from '../table/DecorationRevenueTable';
import { BottomBar } from '../../../_shared';
import './decorationBottomBar.scss';

type TProps = {
  decorationRevenues?: TDecorationRevenues;
};

const DecorationBottomBar: React.FC<TProps> = ({ decorationRevenues }) => {
  return (
    <BottomBar>
      <div className="bottom-bar__decoration-table-wrapper">
        <table>
          <colgroup>
            {decorationRevenueColumns.map(column => (
              <col className={classnames(column.className)} key={column.name} />
            ))}
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={3}>{translations.getLabel('LEDGER.GENERAL.TOTAL')}</td>
              <td colSpan={1}>{parseCurrencyWithPlaceholder(decorationRevenues?.totalPrice)}</td>
              <td colSpan={5} />
              <td className={decorationRevenues?.totalDifference >= 0 ? 'positive' : 'negative'} colSpan={2}>
                {parseCurrencyWithPlaceholder(decorationRevenues?.totalDifference)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </BottomBar>
  );
};

export default DecorationBottomBar;
