import { ItemState } from '../../_utils/stateHelper';

export type ICode = {
  code: string;
  createdAt?: string;
  createdBy?: string;
  description: string;
  id: string;
  name: string;
  serialNumber: number;
  state: ItemState;
  type: CodeType;
  updatedAt?: string;
  updatedBy?: string;
};

export enum CodeType {
  Role = 'ROLE',
  StockChange = 'STOCK_CHANGE',
}

export enum Code {
  CashSheet = 'CASH_SHEET',
  ExtraPrice = 'EXTRA_PRICE',
  Ledger = 'LEDGER',
  Price = 'PRICE',
  Product = 'PRODUCT',
  PurchasePrice = 'BUY_PRICE',
  SellPrice = 'SELL_PRICE',
  Stock = 'STOCK',
  TotalPrice = 'TOTAL_PRICE',
}
