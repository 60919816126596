import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { PricesState } from './reducer';

const selectNode = (state: AppState) => state.prices;

export const prices = createSelector(selectNode, (state: PricesState) => state.prices);
export const metadata = createSelector(selectNode, (state: PricesState) => state.metadata);
export const query = createSelector(selectNode, (state: PricesState) => state.query);
export const errorCrudPrices = createSelector(selectNode, (state: PricesState) => state.errorCrudPrices);
export const isGetPricesLoading = createSelector(selectNode, (state: PricesState) => state.isGetPricesLoading);
export const isUpdatePriceLoading = createSelector(selectNode, (state: PricesState) => state.isUpdatePriceLoading);
export const isPrintPriceLoading = createSelector(selectNode, (state: PricesState) => state.isPrintPriceLoading);
