import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { cashSheetsSelectors, profileSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { Button, Icon, InputFieldDate } from '../../_shared';
import { dateFromString, formatDate, isBeforeDate } from '../../_utils/timeHelpers';
import { TCashSheetQuery } from '../_models';
import { cashSheetActions } from '../../_store/actions';
import useIsRecentlySaved from '../../_hooks/useIsRecentlySaved';
import { usePermission } from '../../_hooks';
import { Code } from '../../codes/_models';
import DeliveryTable from './tables/delivery/DeliveryTable';
import SalesTable from './tables/sale/SalesTable';
import DecorationTable from './tables/decoration/DecorationTable';
import CashSheetTable from './tables/cashSheet/CashSheetTotals';
import Toolbar from './toolbar/Toolbar';
import './cashSheetsOverview.scss';

type TProps = {
  storeId: string;
};

const CashSheetsOverview: React.FC<TProps> = ({ storeId }) => {
  const cashSheet = useSelector(cashSheetsSelectors.detail);
  const isLoading = useSelector(cashSheetsSelectors.isGetCashSheetDetailLoading);
  const profile = useSelector(profileSelectors.profile);
  const query = useSelector(cashSheetsSelectors.query);
  const isChangeSaved = useIsRecentlySaved();
  const permission = usePermission(Code.CashSheet);
  const dateFormat = 'dd/MM/yyyy';
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const setQuery = (partialQuery: TCashSheetQuery) => {
    dispatch(new cashSheetActions.SetCashSheetQuery({ query: { ...query, ...partialQuery }, storeId }));
  };

  function isReadOnly(): boolean {
    if (profile.isAdmin) return !permission.edit;
    return !permission.edit || isBeforeDate(addDays(new Date(query.date), 2), new Date());
  }

  useEffect(() => {
    dispatch(new cashSheetActions.SetCashSheetQuery({ query, storeId }));
  }, [storeId]);

  return (
    <main className="cash-sheets-overview">
      <h2 className="cash-sheets-overview__title">{translations.getLabel('CASH_SHEETS.OVERVIEW.PAGE_TITLE')}</h2>
      <div className="cash-sheets-overview__input">
        <InputFieldDate
          dateFormat={dateFormat}
          name="date"
          onChange={(date: string) => setQuery({ date: formatDate(dateFromString(date, dateFormat), 'yyyy-MM-dd') })}
          value={formatDate(dateFromString(query?.date, 'yyyy-MM-dd'), dateFormat)}
        />
        <Button onClick={() => window.print()} primary>
          <Icon name="SvgPrint" size={2} />
          {translations.getLabel('CASH_SHEETS.OVERVIEW.PRINT_PAGE')}
        </Button>
        <Button negative onClick={() => history.push(url + '/search')}>
          <Icon name="SvgSearch" size={2} />
          {translations.getLabel('SHARED.PLACEHOLDER.SEARCH')}
        </Button>
        <div className={classnames('cash-sheets-overview__input__check', { 'change-saved': isChangeSaved })}>
          <Icon name="SvgCheck" />
          <p className="cash-sheets-overview__input__check__text">
            {translations.getLabel('CASH_SHEETS.OVERVIEW.CHANGES_SAVED')}
          </p>
        </div>
      </div>
      <h4 className="cash-sheets-overview__table-title">{translations.getLabel('CASH_SHEETS.OVERVIEW.SALES_TITLE')}</h4>
      <SalesTable data={cashSheet} isLoading={isLoading} readOnly={isReadOnly()} setQuery={setQuery} />
      <h4 className={classnames('cash-sheets-overview__table-title', 'print-page')}>
        {translations.getLabel('CASH_SHEETS.OVERVIEW.DELIVERIES_TITLE')}
      </h4>
      <DeliveryTable data={cashSheet} isLoading={isLoading} readOnly={isReadOnly()} setQuery={setQuery} />
      <h4 className={classnames('cash-sheets-overview__table-title', 'print-page')}>
        {translations.getLabel('CASH_SHEETS.OVERVIEW.DECORATION_TITLE')}
      </h4>
      <DecorationTable data={cashSheet} isLoading={isLoading} readOnly={isReadOnly()} />
      <h4 className="cash-sheets-overview__table-title">{translations.getLabel('CASH_SHEETS.OVERVIEW.OVERVIEW_TITLE')}</h4>
      <CashSheetTable cashSheet={cashSheet} isLoading={isLoading} readOnly={isReadOnly()} />
      <Toolbar />
    </main>
  );
};

export default CashSheetsOverview;
