import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import CodesOverview from '../codes/overview/CodesOverview';
import Users from '../users/Users';
import StoresOverview from './overview/StoresOverview';

const Management: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route component={StoresOverview} exact path={`${url}/stores`} />
      <Route component={Users} path={`${url}/users`} />
      <Route component={CodesOverview} exact path={`${url}/codes`} />
      <Redirect to={`${url}/stores`} />
    </Switch>
  );
};
export default Management;
