import { IAdminStore, IStore } from '../_models';
import { HttpMetadataQuery, HttpMetadataPagingResponse, ApiError } from '../../_http';
import { insertUpdatedData } from '../../_utils/objectHelpers';
import { AuthActionType, AuthAction } from '../../auth/_store/actions';
import { StoresActionType, StoresAction } from './actions';

export type StoresState = {
  adminStores?: IAdminStore[];
  currentStore?: IStore;
  detail?: IAdminStore;
  errorCrudStores?: ApiError;
  errorDeactivateStore?: ApiError;
  isCreateStoreLoading?: boolean;
  isDeactivateStoreLoading?: boolean;
  isGetAdminStoresLoading?: boolean;
  isGetStoreDetailLoading?: boolean;
  isUpdateStoreLoading?: boolean;
  metadata?: HttpMetadataPagingResponse;
  query?: HttpMetadataQuery;
  stores?: IStore[];
};

export const initialState: StoresState = {};

export default function reducer(state = initialState, action: StoresAction | AuthAction): StoresState {
  switch (action.type) {
    case StoresActionType.GetStoreDetail:
      return {
        ...state,
        errorCrudStores: null,
        isGetStoreDetailLoading: true,
      };
    case StoresActionType.GetStoreDetailSuccess: {
      return {
        ...state,
        detail: action.payload.data,
        isGetStoreDetailLoading: false,
      };
    }
    case StoresActionType.GetStoreDetailError:
      return {
        ...state,
        errorCrudStores: action.payload.error,
        isGetStoreDetailLoading: false,
      };
    case StoresActionType.GetAdminStores:
      return {
        ...state,
        errorCrudStores: null,
        isGetAdminStoresLoading: true,
        metadata: null,
      };
    case StoresActionType.GetAdminStoresSuccess: {
      let currentData = state.adminStores;
      if (!action.payload.meta.skip) currentData = [];
      return {
        ...state,
        adminStores: insertUpdatedData(currentData, action.payload.data),
        isGetAdminStoresLoading: false,
        metadata: action.payload.meta,
      };
    }
    case StoresActionType.GetAdminStoresError: {
      return {
        ...state,
        errorCrudStores: action.payload.error,
        isGetAdminStoresLoading: false,
      };
    }
    case StoresActionType.CreateStore:
      return {
        ...state,
        errorCrudStores: null,
        isCreateStoreLoading: true,
      };
    case StoresActionType.CreateStoreSuccess:
      return {
        ...state,
        adminStores: insertUpdatedData(state.adminStores, [action.payload.createdStore]),
        isCreateStoreLoading: false,
      };
    case StoresActionType.CreateStoreError:
      return {
        ...state,
        errorCrudStores: action.payload.error,
        isCreateStoreLoading: false,
      };
    case StoresActionType.UpdateStore:
      return {
        ...state,
        errorCrudStores: null,
        isUpdateStoreLoading: true,
      };
    case StoresActionType.UpdateStoreSuccess:
      const updatedStore = action.payload.updatedStore; // eslint-disable-line no-case-declarations
      return {
        ...state,
        adminStores: insertUpdatedData(state.adminStores, [updatedStore]),
        currentStore:
          state.currentStore !== undefined &&
          (state.currentStore.id === updatedStore.id
            ? {
                ...state.currentStore,
                color: updatedStore.color,
                name: updatedStore.name,
              }
            : state.currentStore),
        isUpdateStoreLoading: false,
        stores:
          state.stores !== undefined &&
          state.stores.map(store => {
            if (store.id === updatedStore.id) {
              return {
                ...store,
                color: updatedStore.color,
                name: updatedStore.name,
              };
            }
            return store;
          }),
      };
    case StoresActionType.UpdateStoreError:
      return {
        ...state,
        errorCrudStores: action.payload.error,
        isUpdateStoreLoading: false,
      };
    case AuthActionType.AuthenticateSuccess:
      return {
        ...state,
        currentStore: action.payload.profile.stores.find(store => {
          return store.isDefaultStore === true;
        }),
        stores: insertUpdatedData(state.stores, action.payload.profile.stores),
      };

    case StoresActionType.UpdateCurrentStore: {
      return {
        ...state,
        currentStore: action.payload.store,
      };
    }

    default:
      return state;
  }
}
