import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import { NavLink, useRouteMatch, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { storesSelectors } from '../../../../_store/selectors';
import { storesActions } from '../../../../_store/actions';
import { Icon } from '../../../../_shared';
import { useToggle } from '../../../../_hooks';

import './secondaryNav.scss';

const SecondaryNav: FC = () => {
  const stores = useSelector(storesSelectors.stores);
  const currentStore = useSelector(storesSelectors.currentStore);
  const dispatch = useDispatch();
  const [showMenu, toggleMenu] = useToggle(false);
  const { url } = useRouteMatch();
  const urlWithoutId = url.substr(0, url.lastIndexOf('/'));
  const { storeId } = useParams<{ storeId: string }>();

  useEffect(() => {
    if (currentStore?.id !== storeId) {
      dispatch(new storesActions.UpdateCurrentStore({ store: stores.find(store => store.id === storeId) }));
    }
  }, []);

  function routeToSecondaryNav(storeId: string) {
    if (urlWithoutId === '/stock') return `${urlWithoutId}/${storeId}/stock-item`;
    return `${urlWithoutId}/${storeId}`;
  }

  return (
    <div>
      <nav className="secondary-nav">
        <div className={classnames('mobile-menu', { open: showMenu })} onClick={toggleMenu}>
          <span>{currentStore?.name}</span>
          <Icon name="SvgChevronDown" size={2.4} />
        </div>
        <div className={classnames('store-links', { 'mobile-menu-show': showMenu })}>
          {stores.map(store => {
            return (
              <div key={store.id}>
                <NavLink
                  className={classnames('btn', { selected: store.id === storeId })}
                  onClick={() => {
                    dispatch(new storesActions.UpdateCurrentStore({ store: store }));
                    toggleMenu(false);
                  }}
                  to={routeToSecondaryNav(store.id)}
                >
                  {store.name}
                </NavLink>
              </div>
            );
          })}
        </div>
      </nav>

      <div className="color-bar" style={{ backgroundColor: currentStore?.color }} />
    </div>
  );
};

export default SecondaryNav;
