import React, { FC, ReactNode, useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { pricesActions } from '../../../_store/actions';
import { calculateGrossPrice } from '../../_utils';
import { StockItem } from '../../../stock/_models';
import { Button, Checkbox, Icon } from '../../../_shared';

type Props = {
  className: string;
  isChecked: boolean;
  isSelected: boolean;
  isWarehouse?: boolean;
  onSelect?: (id: string) => void;
  readOnly?: boolean;
  saveChanges: () => void;
  showPricesEditModal: (props?: Partial<{ stockItem: StockItem }>) => void;
  showPrintPrice: (props?: Partial<{ stockItems: StockItem[] }>) => void;
  stockItem: StockItem;
};

const PriceRow: FC<Props> = ({
  className,
  readOnly,
  isChecked,
  saveChanges,
  showPricesEditModal,
  showPrintPrice,
  stockItem,
  onSelect,
  isSelected,
  isWarehouse,
}) => {
  const { price } = stockItem.product;

  const [title, setTitle] = useState<string>(price.title);
  const [description, setDescription] = useState<string>(price.description);
  const dispatch = useDispatch();
  const ids = {
    price: price.id,
    store: stockItem.store.id,
  };

  useEffect(() => {
    if (price.title !== title) setTitle(price.title);
    if (price.description !== description) setDescription(price.description);
  }, [stockItem]);

  const requestBody = {
    description,
    title,
  };

  function submitRow() {
    if (description !== price.description || price.title !== title) {
      dispatch(new pricesActions.UpdatePrice({ ids, onSuccess: saveChanges, values: requestBody }));
    }
  }

  function renderInput(result: string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void): ReactNode | string {
    if (!readOnly && isChecked && isWarehouse) {
      return <input onBlur={() => submitRow()} onChange={onChange} type="text" value={result || ''} />;
    }

    return result;
  }

  return (
    <Table.Row className={className} key={stockItem.id}>
      <Table.Cell className="print icon" textAlign="right">
        {onSelect ? (
          <Checkbox checked={isSelected} name="stockitem" onChange={() => onSelect(stockItem.id)} />
        ) : (
          <Button asText onClick={() => showPrintPrice({ stockItems: [stockItem] })}>
            <Icon name="SvgPrint" />
          </Button>
        )}
      </Table.Cell>
      {!readOnly && isWarehouse && (
        <Table.Cell className="edit icon" textAlign="right">
          <Button asText onClick={() => showPricesEditModal({ stockItem })}>
            <Icon name="SvgPencil" />
          </Button>
        </Table.Cell>
      )}
      <Table.Cell>{stockItem.product.code || '-'}</Table.Cell>
      <Table.Cell>{renderInput(title, title => setTitle(title?.target.value)) || '-'}</Table.Cell>
      <Table.Cell>{renderInput(description, description => setDescription(description?.target.value)) || '-'}</Table.Cell>
      <Table.Cell textAlign="center">{calculateGrossPrice(price.sellingPrice) || '-'}</Table.Cell>
      <Table.Cell textAlign="center">{price.sellingPrice || '-'}</Table.Cell>
    </Table.Row>
  );
};

export default PriceRow;
