import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { FillMetadataQueryFunction, ApiError } from '../../_http';
import Table, { TableColumn } from '../../_shared/table/Table';
import { useInfiniteScroll, useModal } from '../../_hooks';
import { translations } from '../../_translations';
import { codesSelectors, profileSelectors } from '../../_store/selectors';
import { ICode } from '../_models';
import { labelForCodeType } from '../_utils';
import { Icon, Button } from '../../_shared';
import EditCodeModal from '../edit/EditCodeModal';
import './CodesTable.scss';
import { labelForItemState } from '../../_utils/stateHelper';

type Props = {
  data?: ICode[];
  errorAsString: (error: ApiError) => string;
  isLoading: boolean;
  setQuery: FillMetadataQueryFunction;
};

const columns: TableColumn[] = [
  { label: '', name: 'edit', sortable: false },
  { label: 'CODES.TYPE.OVERVIEW.TITLE', name: 'type', sortable: false },
  { label: 'CODES.CODE', name: 'code', sortable: false },
  { label: 'CODES.NAME', name: 'name', sortable: false },
  { label: 'CODES.DESCRIPTION', name: 'description', sortable: false },
  { label: 'CODES.SERIALNUMBER', name: 'serialNumber', sortable: false },
  { label: 'CODES.STATE.TITLE', name: 'active', sortable: false },
];

const CodesTable: FC<Props> = ({ data, errorAsString, isLoading, setQuery }) => {
  const metadata = useSelector(codesSelectors.metadata);
  const profile = useSelector(profileSelectors.profile);

  useInfiniteScroll((skip: number) => setQuery({ skip }), metadata, isLoading);

  const [renderEditCodeModal, showEditCodeModal] = useModal<{ code: ICode }>(modalProps => (
    <EditCodeModal {...modalProps} errorAsString={errorAsString} />
  ));

  function renderRow(code: ICode, index: number): JSX.Element {
    const className = classnames({ 'greyed-out': index % 2 });
    return (
      <Table.Row className={className} key={code.id}>
        <Table.Cell className="edit">
          <Button asText onClick={() => showEditCodeModal({ code })}>
            <Icon name="SvgPencil" />
          </Button>
        </Table.Cell>
        <Table.Cell>{labelForCodeType(code.type) || '-'}</Table.Cell>
        <Table.Cell>{code.code || '-'}</Table.Cell>
        <Table.Cell>{code.name || '-'}</Table.Cell>
        <Table.Cell>{code.description || '-'}</Table.Cell>
        <Table.Cell className="serialNumber">{code.serialNumber || '-'}</Table.Cell>
        <Table.Cell>{labelForItemState(code.state) || '-'}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        data={data}
        emptyLabel={translations.getLabel('CODES.OVERVIEW.EMPTY')}
        isLoading={isLoading}
        renderRow={renderRow}
      />
      {profile.isAdmin && renderEditCodeModal()}
    </>
  );
};

export default CodesTable;
