import { Action } from 'redux';
import { ApiError, HttpMetadataQuery, ProductsHttpMetadataPagingResponse } from '../../_http';
import { StockItem, Ids } from '../../stock/_models';
import { ICreateProductRequest } from '../_models';
import { ItemState } from '../../_utils/stateHelper';

export enum ProductsActionType {
  ActivateProduct = '[Products] ActivateProduct',
  ActivateProductError = '[Products] ActivateProductError',
  ActivateProductSuccess = '[Products] ActivateProductSuccess',
  CreateProduct = '[Products] CreateProduct',
  CreateProductError = '[Products] CreateProductError',
  CreateProductSuccess = '[Products] CreateProductSuccess',
  DeactivateProduct = '[Products] DeactivateProduct',
  DeactivateProductError = '[Products] DeactivateProductError',
  DeactivateProductSuccess = '[Products] DeactivateProductSuccess',
  ExportProducts = '[Products] ExportProducts',
  ExportProductsError = '[Products] ExportProductsError',
  ExportProductsSuccess = '[Products] ExportProductsSuccess',
  GetProducts = '[Products] GetProducts',
  GetProductsError = '[Products] GetProductsError',
  GetProductsSuccess = '[Products] GetProductsSuccess',
  SetProductsQuery = '[Products] SetProductsQuery',
  SilentGetProducts = '[Products] SilentGetProducts',
  SilentGetProductsError = '[Products] SilentGetProductsError',
  SilentGetProductsSuccess = '[Products] SilentGetProductsSuccess',
  UpdateProduct = '[Products] UpdateProduct',
  UpdateProductError = '[Products] UpdateProductError',
  UpdateProductSuccess = '[Products] UpdateProductSuccess',
}

export class CreateProduct implements Action<ProductsActionType> {
  readonly type = ProductsActionType.CreateProduct;
  constructor(public payload: { ids: Ids; onSuccess?: () => void; values: ICreateProductRequest }) {}
}

export class CreateProductSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.CreateProductSuccess;
}

export class CreateProductError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.CreateProductError;
  constructor(public payload: { error: ApiError }) {}
}

export class DeactivateProduct implements Action<ProductsActionType> {
  readonly type = ProductsActionType.DeactivateProduct;
  constructor(public payload: { ids: Ids; onSuccess?: () => void }) {}
}

export class DeactivateProductSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.DeactivateProductSuccess;
}

export class DeactivateProductError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.DeactivateProductError;
  constructor(public payload: { error: ApiError }) {}
}

export class ExportProducts implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ExportProducts;
  constructor(public payload: { ids: Ids; state?: ItemState }) {}
}

export class ExportProductsSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ExportProductsSuccess;
  constructor(public payload: { file: Blob; name: string }) {}
}

export class ExportProductsError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ExportProductsError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetProducts implements Action<ProductsActionType> {
  readonly type = ProductsActionType.GetProducts;
  constructor(public payload: { storeId: string }) {}
}

export class GetProductsSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.GetProductsSuccess;
  constructor(public payload: { data: StockItem[]; meta: ProductsHttpMetadataPagingResponse }) {}
}

export class GetProductsError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.GetProductsError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetProductsQuery implements Action<ProductsActionType> {
  readonly type = ProductsActionType.SetProductsQuery;
  constructor(public payload: { query: HttpMetadataQuery; storeId: string }) {}
}

export class SilentGetProducts implements Action<ProductsActionType> {
  readonly type = ProductsActionType.SilentGetProducts;
}

export class SilentGetProductsSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.SilentGetProductsSuccess;
  constructor(public payload: { data: StockItem[]; meta: ProductsHttpMetadataPagingResponse }) {}
}

export class SilentGetProductsError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.SilentGetProductsError;
  constructor(public payload: { error: ApiError }) {}
}

export class UpdateProduct implements Action<ProductsActionType> {
  readonly type = ProductsActionType.UpdateProduct;
  constructor(public payload: { ids: Ids; onSuccess?: (item: StockItem) => void; values: ICreateProductRequest }) {}
}

export class UpdateProductSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.UpdateProductSuccess;
  constructor(public payload: { data: StockItem }) {}
}

export class UpdateProductError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.UpdateProductError;
  constructor(public payload: { error: ApiError }) {}
}

export class ActivateProduct implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ActivateProduct;
  constructor(public payload: { ids: Ids; onSuccess?: () => void }) {}
}

export class ActivateProductSuccess implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ActivateProductSuccess;
}

export class ActivateProductError implements Action<ProductsActionType> {
  readonly type = ProductsActionType.ActivateProductError;
  constructor(public payload: { error: ApiError }) {}
}

export type ProductsAction =
  | CreateProduct
  | CreateProductError
  | CreateProductSuccess
  | DeactivateProduct
  | DeactivateProductSuccess
  | DeactivateProductError
  | ExportProducts
  | ExportProductsSuccess
  | ExportProductsError
  | GetProducts
  | GetProductsSuccess
  | GetProductsError
  | SetProductsQuery
  | SilentGetProducts
  | SilentGetProductsSuccess
  | SilentGetProductsError
  | UpdateProduct
  | UpdateProductSuccess
  | UpdateProductError
  | ActivateProduct
  | ActivateProductSuccess
  | ActivateProductError;
