import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown, { DropdownOption } from '../../_shared/input/dropdown/Dropdown';
import { translations } from '../../_translations';
import { IAssignPermissionsRequest, Actions, setIcon, updatePermission, setCurrentPermissionType } from '../../profile/_models';
import { codesSelectors, storesSelectors } from '../../_store/selectors';
import { storesActions } from '../../_store/actions';
import { IAdminStore } from '../../management/_models';

type Props = {
  formPermissions: IAssignPermissionsRequest[];
  stores: string[];
  updatePermissions: (value: IAssignPermissionsRequest[]) => void;
};

const PermissionsField: FC<Props> = ({ stores, formPermissions, updatePermissions }) => {
  const dispatch = useDispatch();
  const allStores = useSelector(storesSelectors.activeAdminStores);

  useEffect(() => {
    dispatch(new storesActions.GetAdminStores());
  }, []);

  const adminStores: IAdminStore[] = [];

  for (const storeId of stores) {
    const selectedStore = allStores.find(store => store.id === storeId);
    if (selectedStore) adminStores.push(selectedStore);
  }
  const [selectedStoreId, setSelectedStoreId] = useState(stores[0]);

  const storeOptions: DropdownOption[] = adminStores?.map(store => ({
    text: store.name,
    value: store.id,
  }));

  const codes = useSelector(codesSelectors.codes);

  const defaultPermissions: IAssignPermissionsRequest[] = [];
  for (const store of adminStores) {
    for (const code of codes) {
      const permission: IAssignPermissionsRequest = {
        permission: {
          edit: false,
          view: false,
        },
        roleId: code.id,
        storeId: store.id,
      };
      defaultPermissions.push(permission);
    }
  }
  if (formPermissions.length === 0) {
    defaultPermissions.forEach(p => formPermissions.push(p));
  }

  return (
    <div className="marked">
      <div className="titel">
        <label>{translations.getLabel('USERS.CREATE.EDIT')}</label>
      </div>
      <div className="items">
        <Dropdown
          name="selectedStore"
          onChange={(value: string) => setSelectedStoreId(value)}
          options={storeOptions}
          value={selectedStoreId}
        />

        {codes.map(code => {
          const possibleActions: string[] = [Actions.Edit, Actions.View, Actions.NoAccess];
          const permissionOptions: DropdownOption[] = possibleActions.map(action => ({
            icon: setIcon(action),
            text: translations.getLabel(`USERS.CREATE.PERMISSIONS.${action.toUpperCase()}`),
            value: action,
          }));

          function changePermission(value: Actions) {
            let isPermissionUpdated = false;

            const permissions = formPermissions.map(_permission => {
              if (_permission.roleId === code.id && _permission.storeId === selectedStoreId) {
                isPermissionUpdated = true;
                return {
                  ..._permission,
                  permission: updatePermission(value),
                };
              }
              return _permission;
            });
            if (!isPermissionUpdated) {
              const newPermission = {
                permission: updatePermission(value),
                roleId: code.id,
                storeId: selectedStoreId,
              };
              permissions.push(newPermission);
            }
            updatePermissions(permissions);
          }

          const selectedPermission = formPermissions?.find(
            _permission => _permission.roleId === code.id && _permission.storeId === selectedStoreId,
          );

          return (
            <div key={code.code} role="group">
              <label>{code.name}</label>
              <Dropdown
                name="permissions"
                onChange={changePermission}
                options={permissionOptions}
                value={setCurrentPermissionType(selectedPermission?.permission)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissionsField;
