import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { ProductsState } from './reducer';

const selectNode = (state: AppState) => state.products;

export const products = createSelector(selectNode, (state: ProductsState) => state.products);
export const product = (state: AppState, id: string) => selectNode(state).products.find(product => product.id === id);
export const metadata = createSelector(selectNode, (state: ProductsState) => state.metadata);
export const query = createSelector(selectNode, (state: ProductsState) => state.query);
export const errorCrudProducts = createSelector(selectNode, (state: ProductsState) => state.errorCrudProducts);
export const errorDeactivateError = createSelector(selectNode, (state: ProductsState) => state.errorDeactivateError);
export const isCreateProductLoading = createSelector(selectNode, (state: ProductsState) => state.isCreateProductLoading);
export const isActivateProductLoading = createSelector(selectNode, (state: ProductsState) => state.isActivateProductLoading);
export const isDeactivateProductLoading = createSelector(selectNode, (state: ProductsState) => state.isDeactivateProductLoading);
export const isGetProductsLoading = createSelector(selectNode, (state: ProductsState) => state.isGetProductsLoading);
export const isUpdateProductsLoading = createSelector(selectNode, (state: ProductsState) => state.isUpdateProductsLoading);
