import React, { FC, useEffect } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button } from '../../_shared';
import { translations } from '../../_translations';
import { usersSelectors, profileSelectors } from '../../_store/selectors';
import { usersActions } from '../../_store/actions';
import { labelForUserState } from '../_utils';
import { UserState, IUserForm } from '../_models';
import UserForm from '../edit/UserForm';
import { IAssignPermissionsRequest } from '../../profile/_models';
import './userDetail.scss';

const UserDetail: FC = () => {
  const { id } = useParams();
  const user = useSelector(usersSelectors.user(id));
  const isUpdateLoading = useSelector(usersSelectors.isUpdateUserLoading);
  const isGetUserDetailLoading = useSelector(usersSelectors.isGetUserDetailLoading);
  const isDeactivateLoading = useSelector(usersSelectors.isDeactivateUserLoading);
  const isResendRegisterMailLoading = useSelector(usersSelectors.isResendRegisterEmailLoading);
  const error = useSelector(usersSelectors.errorCrudUsers);
  const profile = useSelector(profileSelectors.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new usersActions.GetUserDetail({ userId: id }));
  }, [id]);

  if (!user) {
    if (error) return <Redirect to="/management/users" />;
    if (isGetUserDetailLoading) return <Loader active size="large" />;
    return (
      <div className="error-center">
        {translations.getLabel('ERRORS.USER_NOT_FOUND')}
        <br />
        <br />
        <Button asText href="/management/users">
          {translations.getLabel('USERS.DETAIL.BACK')}
        </Button>
      </div>
    );
  }

  function renderHeader() {
    return (
      <header>
        <h4 className="bold">{translations.getLabel('USERS.UPDATE.TITLE')}</h4>
      </header>
    );
  }

  function renderDetailsSection() {
    const userPermissions: IAssignPermissionsRequest[] = [];
    for (const permission of user?.permissions) {
      const userPermission: IAssignPermissionsRequest = {
        permission: {
          edit: permission.permission.edit,
          view: permission.permission.view,
        },
        roleId: permission.role.id,
        storeId: permission.store.id,
      };
      userPermissions.push(userPermission);
    }
    const initialForm: IUserForm = {
      defaultStoreId: user.defaultStoreId,
      email: user.email,
      firstName: user.firstName || '',
      isAdmin: user.isAdmin,
      lastName: user.lastName || '',
      permissions: userPermissions || [],
      stores: user.stores || [],
    };

    return (
      <section className="edit-user">
        <UserForm
          error={error}
          initialForm={initialForm}
          isSubmitting={isUpdateLoading}
          submitForm={(values: IUserForm) =>
            dispatch(
              new usersActions.UpdateUser({
                userId: user.id,
                values,
              }),
            )
          }
          userId={user.id}
        />
      </section>
    );
  }

  function renderStatusSection() {
    let button = (
      <Button
        loading={isResendRegisterMailLoading}
        onClick={() => dispatch(new usersActions.ResendRegisterEmail({ userId: user.id }))}
        primary
      >
        {translations.getLabel(
          user.state === UserState.Registering
            ? 'USERS.DETAIL.STATUS.BUTTON.RESEND_REGISTER_MAIL'
            : 'USERS.DETAIL.STATUS.BUTTON.ACTIVATE',
        )}
      </Button>
    );
    if (user.state === UserState.Active) {
      button = (
        <Button loading={isDeactivateLoading} negative onClick={() => dispatch(new usersActions.DeactivateUser({ user }))}>
          {translations.getLabel('USERS.DETAIL.STATUS.BUTTON.DEACTIVATE')}
        </Button>
      );
    }
    return (
      <section className="section-status">
        <h2>{translations.getLabel('USERS.DETAIL.STATUS.TITLE')}</h2>
        <div>
          <span>{`${translations.getLabel('USERS.DETAIL.STATUS.SUBTITLE')} `}</span>
          <span className={classnames('status', user.state)}>{labelForUserState(user.state)}</span>
        </div>
        <div className="description">
          <span>{translations.getLabel(`USERS.DETAIL.STATUS.DESCRIPTION.${user.state}`)}</span>
        </div>
        <div className="actions">{profile.isAdmin && button}</div>
      </section>
    );
  }

  return (
    <Container as="main">
      {renderHeader()}
      {renderDetailsSection()}
      {renderStatusSection()}
    </Container>
  );
};

export default UserDetail;
