import { HttpMetadataQuery, HttpClient, ProductsHttpPagedResponse } from '../../_http';
import { getQueryParams } from '../../_utils/queryHelpers';
import { StockItem, Ids } from '../../stock/_models';
import { ICreateProductRequest } from '../_models';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import { ItemState } from '../../_utils/stateHelper';

export function createProduct(ids: Ids, body: ICreateProductRequest): Promise<void> {
  return HttpClient.post<void>('stock-products', body, { 'store-id': ids.store });
}

export function deactivateProduct(ids: Ids): Promise<void> {
  return HttpClient.post<void>(`stock-products/${ids.product}/deactivate`, null, { 'store-id': ids.store });
}

export function activateProduct(ids: Ids): Promise<void> {
  return HttpClient.post<void>(`stock-products/${ids.product}/activate`, null, { 'store-id': ids.store });
}

export function getProducts(storeId: string, query?: HttpMetadataQuery): Promise<ProductsHttpPagedResponse<StockItem>> {
  return HttpClient.get<ProductsHttpPagedResponse<StockItem>>(`stock-products/${getQueryParams(query)}`, null, {
    'store-id': storeId,
  });
}

export function updateProduct(ids: Ids, body: ICreateProductRequest): Promise<{ data: StockItem }> {
  return HttpClient.put<{ data: StockItem }>(`stock-products/${ids.product}`, removeEmptyKeys(body), { 'store-id': ids.store });
}

export async function exportProductsTable({ store }: Ids, state = ItemState.Active): Promise<Blob> {
  return HttpClient.post<Blob>(`stock-products/export?state=${state}`, null, { 'store-id': store }, 'blob');
}
