import { Price } from '../../prices/_models';
import { StockItem } from '../../stock/_models';
import { ItemState } from '../../_utils/stateHelper';

export type Product = {
  code: string;
  createdAt?: string;
  createdBy?: string;
  description: string;
  dimensions?: string;
  id: string;
  isChair: boolean;
  price: Price;
  remark: string;
  reserved?: number;
  state: ItemState;
  title: string;
  updatedAt?: string;
  updatedBy?: string;
  warehouseStockCount: number;
};

export type IProductForm = {
  code: string;
  description: string;
  dimensions: string;
  expected: string;
  extraPrice: string;
  initalStock: string;
  isChair: boolean;
  purchasePrice: string;
  remark: string;
  reserved?: string;
  sellingPrice: string;
  warehouseStockCount: string;
  zone: string;
};

export type ICreateProductRequest = {
  expected: string;
  product: {
    code: string;
    description: string;
    dimensions: string;
    isChair: boolean;
    price: {
      extraPrice: number;
      id?: string;
      purchasePrice: number;
      sellingPrice: number;
    };
    remark: string;
    reserved?: number;
    warehouseStockCount: number;
  };
  value: number;
  zone: string;
};

export const createProductForm = (stockItem: StockItem): IProductForm => {
  return {
    code: stockItem.product.code,
    description: stockItem.product.description,
    dimensions: stockItem.product.dimensions,
    expected: stockItem.expected,
    extraPrice: stockItem.product.price.extraPrice.toString(),
    initalStock: stockItem.value.toString(),
    isChair: stockItem.product.isChair,
    purchasePrice: stockItem.product.price.purchasePrice.toString(),
    remark: stockItem.product.remark,
    reserved: stockItem.product.reserved?.toString() || '',
    sellingPrice: stockItem.product.price.sellingPrice.toString(),
    warehouseStockCount: stockItem.product.warehouseStockCount?.toString() || '',
    zone: stockItem.zone,
  };
};

export const mapProductItem = (
  {
    expected,
    code,
    description,
    dimensions,
    extraPrice,
    purchasePrice,
    sellingPrice,
    remark,
    reserved,
    zone,
    initalStock,
    warehouseStockCount,
    isChair,
  }: IProductForm,
  priceId: string,
): ICreateProductRequest => ({
  expected,
  product: {
    code,
    description,
    dimensions,
    isChair,
    price: {
      extraPrice: parseFloat(extraPrice),
      id: priceId,
      purchasePrice: parseFloat(purchasePrice),
      sellingPrice: parseFloat(sellingPrice),
    },
    remark,
    reserved: parseFloat(reserved),
    warehouseStockCount: parseInt(warehouseStockCount),
  },
  value: parseFloat(initalStock),
  zone,
});
