export type Price = {
  description: string;
  extraPrice: string;
  id: string;
  purchasePrice: string;
  sellingPrice: string;
  title?: string;
};

export type IPricesForm = {
  code: string;
  description: string;
  grossPrice: string;
  sellingPrice: string;
  title: string;
};

export type PricesRequest = {
  description: string;
  title: string;
};

export type PrintProduct = {
  price: {
    brutoPrice: string | number;
    description: string;
    sellingPrice: string | number;
    title: string;
  };
  product: {
    code: string;
    dimensions: string;
    id: string;
  };
};
export type PricesPrintRequest = {
  printFormat: PrintFormat;
  productsToPrint: PrintProduct[];
};

export enum PrintFormat {
  A5 = 'A5',
  A6 = 'A6',
}
