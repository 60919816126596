import { UserState } from '../_models';
import { translations } from '../../_translations';

export function labelForUserState(state: UserState): string {
  return translations.getLabel(`USERS.STATE.OPTIONS.${state}`);
}

export const menuItems = [
  {
    path: '/management/stores',
    text: translations.getLabel('MANAGEMENT.MENU.STORES'),
  },
  {
    path: '/management/users',
    text: translations.getLabel('MANAGEMENT.MENU.USERS'),
  },
  {
    path: '/management/codes',
    text: translations.getLabel('MANAGEMENT.MENU.CODES'),
  },
];
