import React from 'react';
import { useTableSort } from '../../../../_hooks';
import { HttpSortDirection } from '../../../../_http';
import CustomTable, { TTableColumn } from '../../../../_shared/custom/customTable/CustomTable';
import { TCashSheet, TFillCashSheetQueryFunction, TSale } from '../../../_models';
import { TSaleForm } from '../../../_forms/SaleForm';
import { addDefaultRows } from '../../../_utils';
import { translations } from '../../../../_translations';
import SaleForm from './SaleForm';

const DEFAULT_AMOUNT_OF_ROWS = 15;

const columns: TTableColumn[] = [
  {
    className: 'smallest-column centered-text',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.ID'),
    name: 'orderNumber',
    sortable: true,
  },
  {
    className: 'large-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CLIENT_NAME'),
    name: 'clientName',
    sortable: true,
  },
  {
    className: 'small-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.RECEIPT_CODE'),
    name: 'receiptCode',
    sortable: true,
  },
  {
    className: 'smallest-column',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.SELLER'),
    name: 'seller',
    sortable: true,
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.AMOUNT'), name: 'totalPrice', sortable: false },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH'), name: 'paidCash', sortable: false },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANCONTACT'), name: 'paidBancontact', sortable: false },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CREDITCARD'), name: 'paidCreditcard', sortable: false },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_BANCONTACT'),
    name: 'paidBancontactPortable',
    sortable: false,
  },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_CREDITCARD'),
    name: 'paidCreditCardPortable',
    sortable: false,
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.COUPON'), name: 'paidCheck', sortable: false },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANK_TRANSFER'),
    name: 'paidBankTransfer',
    sortable: false,
  },
];

type TProps = {
  data?: TCashSheet;
  isLoading?: boolean;
  readOnly?: boolean;
  setQuery?: TFillCashSheetQueryFunction;
};

const SalesTable: React.FC<TProps> = ({ data, setQuery, isLoading, readOnly }) => {
  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ sortSalesBy: column, sortSalesDirection: direction }),
  );

  function renderRow(sale: TSale, index: number): JSX.Element {
    const initialForm: TSaleForm = {
      clientName: sale.clientName || '',
      orderNumber: sale.orderNumber || 0,
      paidBancontact: sale.paidBancontact || 0,
      paidBancontactPortable: sale.paidBancontactPortable || 0,
      paidBankTransfer: sale.paidBankTransfer || 0,
      paidCash: sale.paidCash || 0,
      paidCheck: sale.paidCheck || 0,
      paidCreditCard: sale.paidCreditCard || 0,
      paidCreditCardPortable: sale.paidCreditCardPortable || 0,
      receiptCode: sale.receiptCode || '',
      seller: sale.seller || '',
      totalPrice: sale.totalPrice || 0,
    };

    return (
      <SaleForm
        cashSheet={data}
        highlight={sale.highlight}
        index={index}
        initialForm={initialForm}
        key={index}
        saleId={sale.id}
      />
    );
  }

  return (
    <CustomTable
      columns={columns}
      data={addDefaultRows<TSale, TSaleForm>(
        data?.sales,
        () => ({ orderNumber: (data?.sales || []).length }),
        DEFAULT_AMOUNT_OF_ROWS,
      )}
      isLoading={isLoading}
      readonly={readOnly}
      renderRow={renderRow}
      sortFunctions={sortFunctions}
    />
  );
};

export default SalesTable;
