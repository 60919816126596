import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePermission, useSyncScroll, useTableSort } from '../../../_hooks';
import CustomTable, { TTableColumn } from '../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../_translations';
import { TDecorationForm } from '../../_models';
import { ledgerActions } from '../../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../../_store/selectors';
import { HttpSortDirection } from '../../../_http';
import { TOtherFurniture, TOtherFurnitureQuery, TOtherFurnitureSale } from '../../_models/OtherFurniture';

import { Code } from '../../../codes/_models';
import OtherFurnitureGroupHeaders from './OtherFurnitureGroupHeaders';
import OtherFurnitureForm from './OtherFurnitureForm';
import './otherFurnitureTable.scss';

type TProps = {
  isLoading: boolean;
  otherFurniture?: TOtherFurniture;
};

export const otherFurnitureTableColumns: TTableColumn[] = [
  {
    className: 'smallest-column centered-text',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.ID'),
    name: 'id',
  },
  {
    className: 'date-column',
    label: translations.getLabel('LEDGER.TABLE.DATE'),
    name: 'date',
    sortable: true,
  },
  {
    label: translations.getLabel('LEDGER.TABLE.CLIENT_NAME'),
    name: 'clientName',
    sortable: true,
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.RECEIPT_CODE'),
    name: 'receiptCode',
    sortable: true,
  },
  {
    className: 'small-column',
    label: translations.getLabel('LEDGER.TABLE.SELLER'),
    name: 'seller',
    sortable: true,
  },
  {
    className: 'currency-column',
    label: translations.getLabel('LEDGER.TABLE.TOTAL_PRICE'),
    name: 'totalPrice',
    sortable: true,
  },
  { className: 'small-column border-left', label: translations.getLabel('LEDGER.TABLE.CASH'), name: 'depositPaidCash' },
  { className: 'small-column', label: translations.getLabel('LEDGER.TABLE.BANCONTACT'), name: 'depositPaidBancontact' },
  { className: 'small-column', label: translations.getLabel('LEDGER.TABLE.CREDITCARD'), name: 'depositPaidCreditCard' },
  { className: 'currency-column border-left', label: translations.getLabel('LEDGER.TABLE.CASH'), name: 'pickupPaidCash' },
  { className: 'currency-column', label: translations.getLabel('LEDGER.TABLE.BANCONTACT'), name: 'pickupPaidBancontact' },
  { className: 'currency-column', label: translations.getLabel('LEDGER.TABLE.CREDITCARD'), name: 'pickupPaidCreditCard' },
  {
    className: 'currency-column border-left',
    label: translations.getLabel('LEDGER.TABLE.CASH'),
    name: 'transportPaidCash',
  },
  { className: 'currency-column', label: translations.getLabel('LEDGER.TABLE.BANK_CHECK'), name: 'transportPaidCheck' },
  { className: 'currency-column', label: translations.getLabel('LEDGER.TABLE.PORTABLE'), name: 'transportPaidPortable' },
  {
    className: 'currency-column border-right',
    label: translations.getLabel('LEDGER.TABLE.BANK_TRANSFER'),
    name: 'transportPaidBankTransfer',
  },
  {
    className: 'currency-column',
    label: translations.getLabel('LEDGER.TABLE.TOTAL_REVENUE'),
    name: 'totalRevenue',
    sortable: true,
  },
  {
    className: 'currency-column',
    label: translations.getLabel('LEDGER.TABLE.DIFFERENCE'),
    name: 'owedAmount',
    sortable: true,
  },
  { label: translations.getLabel('LEDGER.TABLE.NOTE'), name: 'note' },
];

const OtherFurnitureTable: React.FC<TProps> = ({ otherFurniture, isLoading }) => {
  const currentStore = useSelector(storesSelectors.currentStore);
  const query = useSelector(ledgerSelectors.otherFurnitureQuery);
  const dispatch = useDispatch();
  useSyncScroll(['otherFurnitureTable', 'otherFurnitureBottomBar']);
  const permission = usePermission(Code.Ledger);

  const setQuery = (partialQuery: TOtherFurnitureQuery) => {
    dispatch(new ledgerActions.SetOtherFurnitureQuery({ query: { ...query, ...partialQuery }, storeId: currentStore.id }));
  };

  const { sortFunctions } = useTableSort((column: string, direction: HttpSortDirection) =>
    setQuery({ sortBy: column, sortDirection: direction }),
  );

  function renderRow(sale: TOtherFurnitureSale, index: number): JSX.Element {
    const initialForm: TDecorationForm = {
      note: sale.note || '',
    };

    return <OtherFurnitureForm highlight={sale.highlight} index={index} initialForm={initialForm} key={sale.id} sale={sale} />;
  }
  return (
    <div className="other-furniture-table" id="otherFurnitureTable">
      <OtherFurnitureGroupHeaders />
      <CustomTable
        columns={otherFurnitureTableColumns}
        data={otherFurniture?.sales ? otherFurniture.sales : []}
        isLoading={isLoading}
        readonly={!permission.edit}
        renderRow={renderRow}
        sortFunctions={sortFunctions}
      />
    </div>
  );
};

export default OtherFurnitureTable;
