import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Highlight } from '../../../cashSheets/_models';
import { useForm } from '../../../_hooks';
import { CustomTable, InputFieldText } from '../../../_shared';
import { ledgerActions } from '../../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../../_store/selectors';
import { parseCurrency, parseCurrencyWithPlaceholder } from '../../../_utils/parseHelpers';
import { TOtherFurnitureForm, TOtherFurnitureSale } from '../../_models';

type TProps = {
  highlight: Highlight;
  index: number;
  initialForm: TOtherFurnitureForm;
  isSubmitting?: boolean;
  sale: TOtherFurnitureSale;
};

const OtherFurnitureForm: React.FC<TProps> = ({ initialForm, index, sale, highlight }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const error = useSelector(ledgerSelectors.errorCrudLedger);

  const form = useForm<TOtherFurnitureForm>({ initialForm, submitForm: () => ({}), validateForm: () => ({}) });

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [sale]);

  useEffect(() => {
    if (error) {
      form.setFormValues(initialForm);
    }
  }, [error]);

  const onBlur = (name: string, value: unknown) => {
    dispatch(new ledgerActions.ClearActiveCell({ activeCell: { array: 'otherFurniture', index, name } }));
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new ledgerActions.UpdateOtherFurniture({
            saleId: sale.id,
            storeId: currentStore.id,
            values: { ...values, [name]: value },
          }),
        );
      });
    }
  };

  const onClick = (name: string) => {
    dispatch(
      new ledgerActions.SetActiveCell({
        activeCell: {
          array: 'otherFurniture',
          highlight: sale.highlight,
          id: sale.id,
          index,
          name,
        },
      }),
    );
  };
  return (
    <CustomTable.Row className={highlight?.toLowerCase()} key={index}>
      <CustomTable.Cell className="bold centered-text">{index + 1}</CustomTable.Cell>
      <CustomTable.Cell>{sale.date}</CustomTable.Cell>
      <CustomTable.Cell>{sale.clientName}</CustomTable.Cell>
      <CustomTable.Cell>{sale.receiptCode}</CustomTable.Cell>
      <CustomTable.Cell>{sale.seller}</CustomTable.Cell>
      <CustomTable.Cell className="bold">{parseCurrency(sale.totalPrice)}</CustomTable.Cell>
      <CustomTable.Cell className="border-left">{parseCurrency(sale.depositPaidCash)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.depositPaidBancontact)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.depositPaidCreditCard)}</CustomTable.Cell>
      <CustomTable.Cell className="border-left">{parseCurrency(sale.pickupPaidCash)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.pickupPaidBancontact)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.pickupPaidCreditCard)}</CustomTable.Cell>
      <CustomTable.Cell className="border-left">{parseCurrency(sale.transportPaidCash)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.transportPaidCheck)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.transportPaidPortable)}</CustomTable.Cell>
      <CustomTable.Cell className="border-right">{parseCurrency(sale.transportPaidBankTransfer)}</CustomTable.Cell>
      <CustomTable.Cell>{parseCurrency(sale.totalRevenue)}</CustomTable.Cell>
      <CustomTable.Cell className={sale.owedAmount >= 0 ? 'positive' : 'negative'}>
        {parseCurrencyWithPlaceholder(sale.owedAmount)}
      </CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="note" onBlur={onBlur} onClick={onClick} value={form.values.note} />
      </CustomTable.CellEditable>
    </CustomTable.Row>
  );
};

export default OtherFurnitureForm;
