import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ErrorMessage } from '../../_shared';
import { translations } from '../../_translations';
import { productsActions } from '../../_store/actions';
import { StockItem } from '../../stock/_models';
import { productsSelectors } from '../../_store/selectors';
import { ApiError } from '../../_http';
import './product-delete-modal.scss';

type Props = {
  closeEditModal?: () => void;
  closeModal?: () => void;
  errorAsString?: (error?: ApiError) => string;
  stockItem?: StockItem;
};

const ProductDeleteModal: FC<Props> = ({ closeEditModal, closeModal, errorAsString, stockItem }) => {
  const error = useSelector(productsSelectors.errorDeactivateError);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  function deleteProduct() {
    closeEditModal();
    closeModal();
  }

  const onClose = () => {
    closeModal();
    dispatch(new productsActions.DeactivateProductError({ error: null }));
  };

  return (
    <Modal className="product-delete-modal" onClose={onClose} open>
      <header>
        <label>{translations.getLabel('PRODUCTS.DELETE.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <h3>{translations.getLabel('PRODUCTS.DELETE.ATTENTION')}</h3>
        <p>{translations.getLabel('PRODUCTS.DELETE.INFO')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button asText onClick={onClose}>
          {translations.getLabel('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button
          onClick={() =>
            dispatch(
              new productsActions.DeactivateProduct({
                ids: { product: stockItem.product.id, store: stockItem.store.id },
                onSuccess: deleteProduct,
              }),
            )
          }
          primary
        >
          {translations.getLabel('PRODUCTS.DELETE.CONFIRM')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProductDeleteModal;
