import React, { FC } from 'react';
import { translations } from '../../_translations';
import { ItemState } from '../../_utils/stateHelper';
import Checkbox, { InputCheckboxProps } from '../input/checkbox/Checkbox';
import './activeStateCheckbox.scss';

export type Props = InputCheckboxProps & {
  onChange: (value: ItemState, name: string) => void;
  state: ItemState;
};

const ActiveStateCheckbox: FC<Props> = ({ state, ...props }) => {
  return (
    <div className="active-state-checkbox">
      <Checkbox
        label={translations.getLabel('CODES.STATE.TITLE')}
        {...props}
        checked={state === ItemState.Active}
        onChange={(value, name) => props.onChange(value ? ItemState.Active : ItemState.Inactive, name)}
        type="checkbox"
      />
    </div>
  );
};

export default ActiveStateCheckbox;
