import { IChoosePasswordForm, ILoginForm, IRequestPasswordResetForm, IChangePasswordForm } from '../_models';
import { HttpClient, HttpPagedResponse } from '../../_http';
import { IProfile } from '../../profile/_models';
import { getCodes } from '../../codes/_store/api';
import { ICode } from '../../codes/_models';

export function choosePassword(body: IChoosePasswordForm, resetToken: string): Promise<void> {
  return HttpClient.post('auth/reset-password', { ...body, resetToken });
}

export function changePassword(body: IChangePasswordForm): Promise<void> {
  const payload = {
    newPassword: body.newPassword,
    oldPassword: body.oldPassword,
  };

  return HttpClient.post('auth/change-password', payload);
}

export async function login(body: ILoginForm): Promise<{ codes: HttpPagedResponse<ICode>; profile: IProfile }> {
  const profile = await HttpClient.post<IProfile>('auth/login', body);
  const codes = await getCodes();
  return { codes, profile };
}

export function logout(): Promise<void> {
  return HttpClient.post('auth/logout');
}

export function requestPasswordReset(body: IRequestPasswordResetForm): Promise<void> {
  return HttpClient.post('auth/request-password-reset', body);
}

export function authenticate(): Promise<IProfile> {
  return HttpClient.get<IProfile>('auth/me');
}
