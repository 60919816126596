function parseDecimal(value: string, options: Intl.NumberFormatOptions): string {
  return parseFloat(value).toLocaleString('nl-BE', {
    maximumFractionDigits: 2,
    ...options,
  });
}

export function parseCurrency(value: number): string {
  if (!value) return null;
  return parseDecimal(`${value / 100}`, { minimumFractionDigits: 2 });
}
export function parseCurrencyWithPlaceholder(value: number, placeholder = '0,00'): string {
  return parseCurrency(value) || placeholder;
}
