import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../../../_hooks/useForm';
import CustomTable from '../../../../_shared/custom/customTable/CustomTable';
import InputFieldCurrency from '../../../../_shared/custom/inputFieldCurrency/InputFieldCurrency';
import InputFieldText from '../../../../_shared/custom/inputFieldText/InputFieldText';
import { cashSheetActions } from '../../../../_store/actions';
import { Highlight, TCashSheet } from '../../../_models';
import { TSaleForm } from '../../../_forms/SaleForm';
import { cashSheetsSelectors, storesSelectors } from '../../../../_store/selectors';

type TProps = {
  cashSheet?: TCashSheet;
  highlight: Highlight;
  index: number;
  initialForm: TSaleForm;
  isSubmitting?: boolean;
  saleId?: string;
};

const SaleForm: React.FC<TProps> = ({ saleId, initialForm, highlight, index, cashSheet }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const activeCell = useSelector(cashSheetsSelectors.activeCell);
  const error = useSelector(cashSheetsSelectors.errorCrudCashSheets);

  const form = useForm<TSaleForm>({ error, initialForm, submitForm: () => {}, validateForm: () => ({}) });

  useEffect(() => {
    if (!saleId) return form.setFormValues(initialForm);
    if (activeCell?.index === index && activeCell?.array === 'sales') return;
    form.setFormValues(initialForm);
  }, [cashSheet]);

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [saleId]);

  useEffect(() => {
    if (error) {
      form.setFormValues(initialForm);
    }
  }, [error]);

  const onClick = (name: string) => {
    dispatch(
      new cashSheetActions.SetActiveCell({
        activeCell: { array: 'sales', highlight, index, name, orderNumber: form.values.orderNumber },
      }),
    );
  };
  const onBlur = (name: string, value: unknown) => {
    dispatch(new cashSheetActions.ClearActiveCell({ activeCell: { array: 'sales', index, name } }));
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new cashSheetActions.UpdateSale({
            cashSheetId: cashSheet?.id,
            saleId,
            storeId: currentStore.id,
            values: { ...values, [name]: value },
          }),
        );
      });
    }
  };
  return (
    <CustomTable.Row className={highlight?.toLowerCase()} key={index}>
      <CustomTable.Cell className="bold centered-text">{saleId ? form.values.orderNumber + 1 : null}</CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="clientName" onBlur={onBlur} onClick={onClick} value={form.values.clientName} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldText
          autoFocus
          name="receiptCode"
          normalize={value => value.toUpperCase()}
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.receiptCode}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldText
          autoFocus
          name="seller"
          normalize={value => value.toUpperCase()}
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.seller}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          className="bold"
          name="totalPrice"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.totalPrice}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency autoFocus name="paidCash" onBlur={onBlur} onClick={onClick} value={form.values.paidCash} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBancontact"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBancontact}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidCreditCard"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidCreditCard}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBancontactPortable"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBancontactPortable}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidCreditCardPortable"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidCreditCardPortable}
        />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency autoFocus name="paidCheck" onBlur={onBlur} onClick={onClick} value={form.values.paidCheck} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency
          autoFocus
          name="paidBankTransfer"
          onBlur={onBlur}
          onClick={onClick}
          value={form.values.paidBankTransfer}
        />
      </CustomTable.CellEditable>
    </CustomTable.Row>
  );
};

export default SaleForm;
