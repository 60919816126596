import { ApiError, HttpStatus } from '../_http';
import { translations } from '../_translations';

export function errorAsString(error?: ApiError): string {
  if (!error?.error) return null;
  if (error.statusCode === HttpStatus.Unauthorized) return translations.getLabel(`ERRORS.UNAUTHORIZED`);
  if (error.error === 'DEACTIVATE_PRODUCT_FAILED') return translations.getLabel(`ERRORS.DEACTIVATE_PRODUCT_FAILED`);
  if (error.error === 'EMAIL_ALREADY_IN_USE') return translations.getLabel(`ERRORS.EMAIL_ALREADY_IN_USE`);
  if (error.error === 'INVALID_FORM') return translations.getLabel(`ERRORS.GENERAL`);
  if (error.error === 'INVALID_OLD_PASSWORD') return translations.getLabel(`ERRORS.INVALID_OLD_PASSWORD`);
  if (error.error === 'NO_CONTENT') return translations.getLabel(`ERRORS.NO_CONTENT`);
  if (error.error === 'PERMISSION_DENIED') return translations.getLabel(`ERRORS.PERMISSION_DENIED`);
  if (error.error === 'PRODUCT_CODE_ALREADY_IN_USE') return translations.getLabel(`ERRORS.PRODUCT_CODE_ALREADY_IN_USE`);
  if (error.error === 'PRODUCT_NOT_FOUND') return translations.getLabel(`ERRORS.PRODUCT_NOT_FOUND`);
  if (error.error === 'RESET_TOKEN_EXPIRED') return translations.getLabel(`ERRORS.RESET_TOKEN_EXPIRED`);
  if (error.error === 'RESET_TOKEN_INVALID') return translations.getLabel(`ERRORS.RESET_TOKEN_INVALID`);
  if (error.error === 'ROLE_CODE_ALREADY_IN_USE') return translations.getLabel(`ERRORS.ROLE_CODE_ALREADY_IN_USE`);
  if (error.error === 'ROLE_IN_USE') return translations.getLabel(`ERRORS.ROLE_IN_USE`);
  if (error.error === 'ROLE_NAME_ALREADY_IN_USE') return translations.getLabel(`ERRORS.ROLE_NAME_ALREADY_IN_USE`);
  if (error.error === 'STORE_CODE_ALREADY_IN_USE') return translations.getLabel('ERRORS.VALIDATION.CODE');
  if (error.error === 'USER_STATE_NOT_ALLOWED') return translations.getLabel(`ERRORS.USER_STATE_NOT_ALLOWED`);
  return null;
}
