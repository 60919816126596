import React from 'react';
import classnames from 'classnames';
import { Icon } from '../../../_shared';
import CustomTable, { TTableColumn } from '../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../_translations';
import { parseCurrency } from '../../../_utils/parseHelpers';
import { TRevenue, TRevenueSale } from '../../_models';
import './revenuePerSeller.scss';
import { usePermission, useToggle } from '../../../_hooks';

import { Code } from '../../../codes/_models';

export const revenuePerSellerColumns: TTableColumn[] = [
  {
    className: 'smallest-column centered-text',
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.ID'),
    name: 'id',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.DATE'),
    name: 'date',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.RECEIPT_CODE'),
    name: 'receiptCode',
  },
  {
    className: 'medium-column',
    label: translations.getLabel('LEDGER.TABLE.TOTAL_PRICE'),
    name: 'totalPrice',
  },
];

type TProps = {
  isLoading?: boolean;
  revenue?: TRevenue;
};

const RevenuePerSeller: React.FC<TProps> = ({ revenue, isLoading }) => {
  const [isExpanded, setIsExpanded] = useToggle(true);
  const permission = usePermission(Code.Ledger);

  function renderRow(sale: TRevenueSale, index: number): JSX.Element {
    return (
      <CustomTable.Row key={index}>
        <CustomTable.Cell className="bold centered-text">{index + 1}</CustomTable.Cell>
        <CustomTable.Cell>{sale.date}</CustomTable.Cell>
        <CustomTable.Cell>{sale.receiptCode}</CustomTable.Cell>
        <CustomTable.Cell className="bold"> {parseCurrency(sale.totalPrice)}</CustomTable.Cell>
      </CustomTable.Row>
    );
  }

  return (
    <main className="revenue-per-seller print-page">
      <div className="revenue-per-seller__summary">
        <div className="revenue-per-seller__summary__seller">
          <h4 className="revenue-per-seller__summary__seller__name">{revenue.seller}</h4>
          <Icon clickable name={isExpanded ? 'SvgChevronDown' : 'SvgChevronUp'} onClick={() => setIsExpanded(!isExpanded)} />
        </div>
        <div className="revenue-per-seller__summary__total-price">
          <h4 className="revenue-per-seller__summary__total-price__text">{translations.getLabel('LEDGER.GENERAL.TOTAL')}:</h4>
          <h4 className="revenue-per-seller__summary__total-price__number">{parseCurrency(revenue.totalPrice)}</h4>
        </div>
      </div>
      <div className={classnames('revenue-per-seller__table', { 'is-expanded': isExpanded })}>
        <CustomTable
          columns={revenuePerSellerColumns}
          data={revenue.sales}
          isLoading={isLoading}
          readonly={!permission.edit}
          renderRow={renderRow}
        />
      </div>
    </main>
  );
};

export default RevenuePerSeller;
