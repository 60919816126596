import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomTable, { TTableColumn } from '../../../../_shared/custom/customTable/CustomTable';
import { translations } from '../../../../_translations';
import { parseCurrency } from '../../../../_utils/parseHelpers';
import { TDecoration } from '../../../_models';

const columns: TTableColumn[] = [
  {
    label: translations.getLabel('STOCKITEMS.TABLE.DATE'),
    name: 'cashSheetDate',
  },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.DECORATION'),
    name: 'name',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.RECEIPT_CODE'), name: 'receiptCode' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.AMOUNT'), name: 'totalPrice' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CASH'), name: 'paidCash' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANCONTACT'), name: 'paidBancontact' },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.CREDITCARD'), name: 'paidCreditcard' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_BANCONTACT'),
    name: 'paidBancontactPortable',
  },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.PORTABLE_CREDITCARD'),
    name: 'paidCreditcardPortable',
  },
  { label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.COUPON'), name: 'paidCheck' },
  {
    label: translations.getLabel('CASH_SHEETS.OVERVIEW.TABLE.BANK_TRANSFER'),
    name: 'paidBankTransfer',
  },
];

type TProps = {
  data?: TDecoration[];
  isLoading?: boolean;
  setDate: (date: string) => void;
  storeId: string;
};

const DecorationSearchTable: React.FC<TProps> = ({ data, isLoading, storeId, setDate }) => {
  function renderRow(decoration: TDecoration, index: number): JSX.Element {
    return (
      <CustomTable.Row className={decoration?.highlight?.toLowerCase()} key={index}>
        <CustomTable.Cell className="underline">
          <NavLink onClick={() => setDate(decoration.cashSheetDate)} to={`/cash-sheets/${storeId}`}>
            {decoration.cashSheetDate}
          </NavLink>
        </CustomTable.Cell>
        <CustomTable.Cell>{decoration.name}</CustomTable.Cell>
        <CustomTable.Cell>{decoration.receiptCode}</CustomTable.Cell>
        <CustomTable.Cell className="bold">{parseCurrency(decoration.totalPrice)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidCash)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidBancontact)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidCreditCard)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidBancontactPortable)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidCreditCardPortable)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidCheck)}</CustomTable.Cell>
        <CustomTable.Cell>{parseCurrency(decoration.paidBankTransfer)}</CustomTable.Cell>
      </CustomTable.Row>
    );
  }

  return <CustomTable columns={columns} data={data} isLoading={isLoading} renderRow={renderRow} />;
};

export default DecorationSearchTable;
