import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../_hooks';
import { CustomTable, InputFieldCurrency, InputFieldText } from '../../../_shared';
import { cashSheetActions } from '../../../_store/actions';
import { ledgerSelectors, storesSelectors } from '../../../_store/selectors';
import { parseCurrency } from '../../../_utils/parseHelpers';
import { TBankTransfer, TBankTransferForm } from '../../_models';

type TProps = {
  bankTransfer: TBankTransfer;
  index: number;
  initialForm: TBankTransferForm;
};

const BankTransferForm: React.FC<TProps> = ({ initialForm, index, bankTransfer }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(storesSelectors.currentStore);
  const error = useSelector(ledgerSelectors.errorCrudLedger);

  const form = useForm<TBankTransferForm>({ initialForm, submitForm: () => ({}), validateForm: () => ({}) });

  useEffect(() => {
    form.setFormValues(initialForm);
  }, [bankTransfer]);

  useEffect(() => {
    if (error) {
      form.setFormValues(initialForm);
    }
  }, [error]);

  const onBlur = (name: string, value: unknown) => {
    const hasSetAttribute = form.setAttribute(value, name);
    if (hasSetAttribute) {
      form.submit(null, (values, setFormValues) => {
        setFormValues({ ...values, [name]: value });
        dispatch(
          new cashSheetActions.UpdateCashSheet({
            cashSheetId: bankTransfer.cashSheetId,
            storeId: currentStore.id,
            values: { ...values, [name]: value },
          }),
        );
      });
    }
  };

  return (
    <CustomTable.Row>
      <CustomTable.Cell className="bold centered-text">{index + 1}</CustomTable.Cell>
      <CustomTable.Cell>{bankTransfer.date}</CustomTable.Cell>
      <CustomTable.Cell className="bold">{parseCurrency(bankTransfer.totalAmountToBank)}</CustomTable.Cell>
      <CustomTable.Cell>{bankTransfer.closedBy}</CustomTable.Cell>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="bankStatement" onBlur={onBlur} value={form.values.bankStatement} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldCurrency autoFocus name="bankCheck" onBlur={onBlur} value={form.values.bankCheck} />
      </CustomTable.CellEditable>
      <CustomTable.CellEditable>
        <InputFieldText autoFocus name="bankNote" onBlur={onBlur} value={form.values.bankNote} />
      </CustomTable.CellEditable>
    </CustomTable.Row>
  );
};

export default BankTransferForm;
