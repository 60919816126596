import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiError } from '../../_http';
import { ICodeForm, ICode } from '../_models';
import { Modal, ErrorMessage, Button } from '../../_shared';
import { translations } from '../../_translations';
import { codesSelectors } from '../../_store/selectors';
import { codesActions } from '../../_store/actions';
import CodeForm from './CodeForm';

type Props = {
  closeModal: () => void;
  code?: ICode;
  errorAsString: (error?: ApiError) => string;
};

const EditCodeModal: FC<Props> = ({ closeModal, code, errorAsString }) => {
  const isUpdateCodeLoading = useSelector(codesSelectors.isUpdateCodeLoading);
  const error = useSelector(codesSelectors.errorCrudCodes);
  const errorMessage = errorAsString(error);
  const dispatch = useDispatch();

  if (!code && error) closeModal();

  const initialForm: ICodeForm = {
    code: code.code,
    description: code.description,
    name: code.name,
    serialNumber: code.serialNumber,
    state: code.state,
    type: code.type,
  };

  return (
    <Modal onClose={closeModal} open>
      <header>
        <label>{translations.getLabel('CODES.EDIT.TITLE')}</label>
      </header>
      <Modal.Content>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <CodeForm
          buttons={
            <Button asText onClick={closeModal}>
              {translations.getLabel('SHARED.BUTTONS.CANCEL')}
            </Button>
          }
          error={error}
          initialForm={initialForm}
          isSubmitting={isUpdateCodeLoading}
          submitForm={(values: ICodeForm) =>
            dispatch(new codesActions.UpdateCode({ codeId: code.id, onSuccess: closeModal, values }))
          }
        />
      </Modal.Content>
    </Modal>
  );
};

export default EditCodeModal;
