import React from 'react';
import { translations } from '../../_translations';
import './ledgerEmpty.scss';

const LedgerEmpty: React.FC = () => {
  return (
    <main className="empty-message">
      <h3 className="empty-message__title">{translations.getLabel('LEDGER.EMPTY.TITLE')}</h3>
      <p className="empty-message__extra-info">{translations.getLabel('LEDGER.EMPTY.EXTRA_INFO')}</p>
    </main>
  );
};

export default LedgerEmpty;
