import { useEffect } from 'react';

function useSyncScroll(domElementIds: string[]): void {
  function handleScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (!domElementIds.some(id => id === target.id)) return;
    domElementIds.filter(id => id !== target.id).forEach(id => (document.getElementById(id).scrollLeft = target.scrollLeft));
  }

  useEffect(() => {
    domElementIds.forEach(id => (document.getElementById(id).onscroll = handleScroll));
  }, []);
}

export default useSyncScroll;
