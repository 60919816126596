import { ItemState } from '../../_utils/stateHelper';
import { StockItem } from '.';

export function canDeleteStockItemProduct(stockItem: StockItem): boolean {
  return stockItem.product.state === ItemState.Active;
}

export function canActivateStockItemProduct(stockItem: StockItem): boolean {
  return stockItem.product.state === ItemState.Inactive;
}
