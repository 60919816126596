import { combineEpics } from 'redux-observable';
import authEpics from '../auth/_store/epics';
import cashSheetsEpics from '../cashSheets/_store/epics';
import codesEpics from '../codes/_store/epics';
import modalEpics from '../modal/_store/epics';
import pricesEpics from '../prices/_store/epics';
import productsEpics from '../products/_store/epics';
import reportsEpics from '../reports/_store/epics';
import stockEpics from '../stock/_store/epics';
import storesEpics from '../management/_store/epics';
import usersEpics from '../users/_store/epics';
import ledgerEpics from '../ledger/_store/epics';

const rootEpic = combineEpics(
  ...authEpics,
  ...cashSheetsEpics,
  ...codesEpics,
  ...modalEpics,
  ...pricesEpics,
  ...productsEpics,
  ...reportsEpics,
  ...stockEpics,
  ...storesEpics,
  ...usersEpics,
  ...ledgerEpics,
);

export default rootEpic;
