import { createSelector } from 'reselect';
import { AppState } from '../../_store/rootReducer';
import { StockState } from './reducer';

const selectNode = (state: AppState) => state.stock;

// stockchange
export const stockChange = createSelector(selectNode, (state: StockState) => state.stockChange);
export const stockChangeMetadata = createSelector(selectNode, (state: StockState) => state.stockChangeMetadata);
export const stockChangeQuery = createSelector(selectNode, (state: StockState) => state.stockChangeQuery);
export const errorCrudStockChange = createSelector(selectNode, (state: StockState) => state.errorCrudStockChange);
export const isGetStockChangeLoading = createSelector(selectNode, (state: StockState) => state.isGetStockChangeLoading);

// stockitems
export const stockItems = createSelector(selectNode, (state: StockState) => state.stockitems);
export const stockItemMetadata = createSelector(selectNode, (state: StockState) => state.stockItemMetadata);
export const errorCrudStockItems = createSelector(selectNode, (state: StockState) => state.errorCrudStockItems);
export const isGetStockItemsLoading = createSelector(selectNode, (state: StockState) => state.isGetStockItemsLoading);
